import { RootState } from "../../app/store";
import { EntityModelValueBand } from "../../openapi";

export const selectIsFetchingValueBands = (state: RootState): boolean =>
  state.vbReducer.isFetching;
export const selectValueBands = (state: RootState): EntityModelValueBand[] =>
  state.vbReducer.valueBands;
export const selectSelectedValueBand = (
  state: RootState
): EntityModelValueBand | undefined => state.vbReducer.selected;
