import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import { Alert, Grid, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Spinner } from "../../../components/core/Spinner";
import { Card } from "../../../components/core/Card";
import { useUser } from "../../../hooks/useUser";
import { FormInput } from "../../../components/hook-form/FormInput";
import { FormFieldLabel } from "../../../components/hook-form/FormFieldLabel";
import {
  EntityModelInstrument,
  InstrumentControllerService,
  InstrumentUpdateDto,
  UserControllerService,
} from "../../../openapi";
import { AlertMessage } from "../../../components/core/AlertMessage/AlertMessage";
import { parseJwt } from "../../../utils/jwtUtils";

export interface EditInstrumentInputs {
  bsRemark: string;
  itRemark: string;
  etdCategory: string;
  pricingModel: string;
  transaction: string;
  instrumentType: string;
  direction: string;
  text: string;
  sid: string;
  simulationNumber: string;
  fullName: string;
  instrSecurityNumber: string;
  quantity: string;
  contrLotSize: string;
  premiumPerContrLot: string;
  instrSecurityCurrency: string;
  bookingCenterCurrency: string;
  exchangeRate: string;
  stockExchange: string;
}

export interface EditInstrumentFormProps {
  id?: string;
}

export const EditInstrumentForm = (props: EditInstrumentFormProps) => {
  const [instrument, setInstrument] = useState<EntityModelInstrument>();
  const [instrumentError, setInstrumentError] = useState("");
  const [instrumentIsFetching, setInstrumentIsFetching] = useState(false);

  const { id } = props;
  const { token, user } = useUser();

  const form = useForm<EditInstrumentInputs>({
    defaultValues: {
      bsRemark: "",
      itRemark: "",
      etdCategory: "",
      pricingModel: "",
      transaction: "",
      instrumentType: "",
      direction: "",
      text: "",
      sid: "",
      simulationNumber: "",
      fullName: "",
      instrSecurityNumber: "",
      quantity: "",
      contrLotSize: "",
      premiumPerContrLot: "",
      instrSecurityCurrency: "",
      bookingCenterCurrency: "",
      exchangeRate: "",
      stockExchange: "",
    },
  });

  const { reset, formState, handleSubmit, control } = form;

  useEffect(() => {
    setInstrumentIsFetching(true);
    if (token) {
      InstrumentControllerService.getInstruments({
        id: Number(id),
        authorization: `Bearer ${token}`,
        size: 10,
      })
        .then((data) => {
          setInstrumentIsFetching(false);
          setInstrument(data?._embedded?.entities?.[0] ?? {});

          reset({
            sid:
              data?._embedded?.entities?.[0].sid !== null
                ? String(data?._embedded?.entities?.[0].sid)
                : "",
            simulationNumber:
              data?._embedded?.entities?.[0].simulationNumber !== null
                ? String(data?._embedded?.entities?.[0].simulationNumber)
                : "",
            quantity:
              data?._embedded?.entities?.[0].quantity !== null
                ? String(data?._embedded?.entities?.[0].quantity)
                : "",
            contrLotSize:
              data?._embedded?.entities?.[0].contrLotSize !== null
                ? String(data?._embedded?.entities?.[0].contrLotSize)
                : "",
            premiumPerContrLot:
              data?._embedded?.entities?.[0].premiumPerContrLot !== null
                ? String(data?._embedded?.entities?.[0].premiumPerContrLot)
                : "",
            exchangeRate:
              data?._embedded?.entities?.[0].exchangeRate !== null
                ? String(data?._embedded?.entities?.[0].exchangeRate)
                : "",
            transaction: data?._embedded?.entities?.[0].transaction?.text ?? "",
            instrumentType:
              data?._embedded?.entities?.[0].instrumentType?.text ?? "",
            direction: data?._embedded?.entities?.[0].direction?.text ?? "",
            text: data?._embedded?.entities?.[0].text ?? "",
            pricingModel:
              data?._embedded?.entities?.[0].pricingModel?.text ?? "",
            etdCategory: data?._embedded?.entities?.[0].etdCategory?.text ?? "",
            fullName: data?._embedded?.entities?.[0].fullName ?? "",
            instrSecurityNumber:
              data?._embedded?.entities?.[0].instrSecurityNumber ?? "",
            bsRemark: data?._embedded?.entities?.[0].bsRemark ?? "",
            itRemark: data?._embedded?.entities?.[0].itRemark ?? "",
            instrSecurityCurrency:
              data?._embedded?.entities?.[0].instrSecurityCurrency?.text ?? "",
            bookingCenterCurrency:
              data?._embedded?.entities?.[0].bookingCenterCurrency?.text ?? "",
            stockExchange:
              data?._embedded?.entities?.[0].stockExchange?.text ?? "",
          });
        })
        .catch((error) => {
          setInstrumentIsFetching(false);
          setInstrumentError(error.message);
        });
    }
  }, [id, reset, token]);

  const onSubmit: SubmitHandler<EditInstrumentInputs> = useCallback(
    (data) => {
      if (id) {
        const { bsRemark, itRemark, exchangeRate } = data;

        const bodyReq: InstrumentUpdateDto = {
          bsRemark: null,
          itRemark: null,
          exchangeRate: null,
        };

        if (bsRemark) bodyReq.bsRemark = bsRemark;
        if (itRemark) bodyReq.itRemark = itRemark;
        if (exchangeRate) bodyReq.exchangeRate = Number(exchangeRate);

        InstrumentControllerService.updateInstruments(
          Number(id),
          bodyReq,
          `Bearer ${token}`
        )
          .then(() => {
            UserControllerService.addUserAction(
              {
                user: parseJwt(token ?? "")?.upn,
                cdOperation: "entity_Instrument_edit",
                operation: "ecs_ee",
                description:
                  "The user edited the Instrument entity with id " + id,
              },
              `Bearer ${token}`
            );
          })
          .catch((error) => setInstrumentError(error.message));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, token, user]
  );

  return (
    <Card
      title="Edit Instruments"
      style={{ width: "1512px" }}
      actions={
        instrument &&
        !instrumentError && (
          <LoadingButton
            variant="contained"
            endIcon={<SaveAltOutlinedIcon />}
            form="edit-instrument-form"
            type="submit"
            sx={{ textTransform: "none" }}
            disabled={formState.isSubmitting}
            loading={formState.isSubmitting}
          >
            Save
          </LoadingButton>
        )
      }
      Component={() => (
        <Box sx={{ margin: "20px" }}>
          {instrumentError && <Alert severity="error">{instrumentError}</Alert>}
          {instrumentIsFetching && !instrumentError && (
            <Spinner height="353px" />
          )}
          {instrument && !instrumentError && (
            <form
              id="edit-instrument-form"
              data-testid="edit-instrument-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container columnSpacing={2} rowSpacing={5}>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="bookingCenterCurrency"
                    label={
                      <FormFieldLabel>Booking Center Currency</FormFieldLabel>
                    }
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="fullName"
                    label={<FormFieldLabel>Full Name</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="instrSecurityNumber"
                    label={
                      <FormFieldLabel>Instr. Security Number</FormFieldLabel>
                    }
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="pricingModel"
                    label={<FormFieldLabel>Pricing Model</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="transaction"
                    label={<FormFieldLabel>Transaction</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="instrumentType"
                    label={<FormFieldLabel>Instrument Type</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="direction"
                    label={<FormFieldLabel>Direction</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="text"
                    label={<FormFieldLabel>Text</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="etdCategory"
                    label={<FormFieldLabel>Etd Category</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="sid"
                    label={<FormFieldLabel>Sid</FormFieldLabel>}
                    fullWidth
                    InputProps={{ inputProps: { min: 0, step: 0.0001 } }}
                    type="number"
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    InputProps={{ inputProps: { min: 0, step: 0.0001 } }}
                    name="simulationNumber"
                    label={<FormFieldLabel>Simulation Number</FormFieldLabel>}
                    fullWidth
                    type="number"
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="quantity"
                    label={<FormFieldLabel>Quantity</FormFieldLabel>}
                    fullWidth
                    InputProps={{ inputProps: { min: 0, step: 0.0001 } }}
                    type="number"
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="contrLotSize"
                    label={<FormFieldLabel>Contr Lot Size</FormFieldLabel>}
                    fullWidth
                    InputProps={{ inputProps: { min: 0, step: 0.0001 } }}
                    type="number"
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="exchangeRate"
                    label={<FormFieldLabel>Exchange Rate</FormFieldLabel>}
                    fullWidth
                    InputProps={{ inputProps: { min: 0, step: 0.0001 } }}
                    type="number"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="premiumPerContrLot"
                    label={
                      <FormFieldLabel>Premium Per ContrLot</FormFieldLabel>
                    }
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="stockExchange"
                    label={<FormFieldLabel>Stock Exchange</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="instrSecurityCurrency"
                    label={
                      <FormFieldLabel>Instr. Security Currency:</FormFieldLabel>
                    }
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="bsRemark"
                    label={<FormFieldLabel>Bs Remark</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="itRemark"
                    label={<FormFieldLabel>It Remark</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
              </Grid>
            </form>
          )}
          <AlertMessage
            show={
              formState.isSubmitted &&
              formState.isSubmitSuccessful &&
              !instrumentError
            }
            message="Data has been updated correctly"
          />
        </Box>
      )}
    />
  );
};
