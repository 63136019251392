/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedModelEntityModelDirectionDto } from "../models/PagedModelEntityModelDirectionDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class DirectionControllerService {
  /**
   * It will return a paged list of the Directions
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param authorization
   * @param code
   * @param id
   * @param text
   * @param sid
   * @returns PagedModelEntityModelDirectionDto Directions list paged and filtered by the given mandatory and optional fields
   * @throws ApiError
   */
  public static getDirections(input: {
    page?: number;
    size: number;
    sort?: Array<string>;
    authorization?: string;
    code?: string;
    id?: number;
    text?: string;
    sid?: number;
  }): CancelablePromise<PagedModelEntityModelDirectionDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/directions",
      headers: {
        Authorization: input.authorization,
      },
      query: {
        page: input.page,
        size: input.size,
        sort: input.sort,
        code: input.code,
        id: input.id,
        text: input.text,
        sid: input.sid,
      },
      errors: {
        403: `Your role must have read grants to direction table`,
      },
    });
  }
}
