import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { SingleSimulationFormInputs } from "../../SingleSimulationForm/types/SingleSimulationFormInputs";
import { useAppDispatch } from "../../../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../../../hooks/useAppSelector";
import { selectValueBands } from "../../../../../../features/valueBands/valueBands.selectors";
import { valueBandSelected } from "../../../../../../features/valueBands/valueBands.reducer";
import { getValueBand } from "../../../../../../utils/simulationUtils";
import { FormInput } from "../../../../../../components/hook-form/FormInput";
import { InputAdornment } from "@mui/material";
import { selectSelectedCurrency } from "../../../../../../features/currencies/currencies.selectors";
import { selectSelectedBookingCenter } from "../../../../../../features/bookingCenters/bookingCenters.selectors";
import { FormCurrencyInput } from "components/hook-form/FormCurrencyInput";

export const ValueBands = (): React.ReactElement => {
  const { control, setValue } = useFormContext<SingleSimulationFormInputs>();

  const dispatch = useAppDispatch();

  const selectedBookingCenter = useAppSelector(selectSelectedBookingCenter);

  const transactionVolume1 = useWatch({ control, name: "transactionVolume1" });
  const transactionVolume2 = useWatch({ control, name: "transactionVolume2" });
  const valueBand = useWatch({ control, name: "valueBand" });
  const vbPortion1 = useWatch({ control, name: "vbPortion1" });
  const vbPortion2 = useWatch({ control, name: "vbPortion2" });
  const vbPortion3 = useWatch({ control, name: "vbPortion3" });

  const valueBands = useAppSelector(selectValueBands);
  const selectedCurrency = useAppSelector(selectSelectedCurrency);

  const transactionVolume2Float = transactionVolume2 as unknown as number;

  React.useEffect(() => {
    if (valueBands && valueBands.length > 0 && transactionVolume2) {
      const _valueBand = getValueBand(valueBands, transactionVolume2Float);

      setValue("valueBand", (_valueBand && _valueBand.text) || "");

      dispatch(valueBandSelected(_valueBand));
    }
  }, [
    transactionVolume2Float,
    dispatch,
    setValue,
    transactionVolume2,
    valueBands,
  ]);

  React.useEffect(() => {
    const iaBand1Value: number = valueBands.find(
      (vb) => vb?.text?.toLowerCase() === "IA_Band_1".toLowerCase()
    )?.valMax as number;
    const iaBand2Value: number = valueBands.find(
      (vb) => vb?.text?.toLowerCase() === "IA_Band_2".toLowerCase()
    )?.valMax as number;

    const _vbPortion1 = Math.max(
      Math.min(iaBand1Value, transactionVolume2Float),
      0
    );
    const _vbPortion2 = Math.max(
      Math.min(
        iaBand2Value - iaBand1Value,
        transactionVolume2Float - iaBand1Value
      ),
      0
    );
    const _vbPortion3 = Math.max(transactionVolume2Float - iaBand2Value, 0);

    setValue("vbPortion1", _vbPortion1);
    setValue("vbPortion2", _vbPortion2);
    setValue("vbPortion3", _vbPortion3);
  }, [setValue, transactionVolume2Float, valueBands]);

  return (
    <React.Fragment>
      {valueBand && transactionVolume1 && valueBands ? (
        <FormInput
          name="valueBand"
          label="Value Band"
          variant="filled"
          fullWidth
          sx={{ mt: "10px" }}
        />
      ) : (
        <React.Fragment />
      )}
      {!isNaN(vbPortion1) && transactionVolume2 && selectedCurrency ? (
        <FormCurrencyInput
          name="vbPortion1"
          label="Value Band Portion 1"
          variant="filled"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {selectedBookingCenter?.currency?.code ?? ""}
              </InputAdornment>
            ),
            readOnly: true,
          }}
          fullWidth
          sx={{ mt: "10px" }}
        />
      ) : (
        <React.Fragment />
      )}
      {!isNaN(vbPortion2) && transactionVolume2 && selectedCurrency ? (
        <FormCurrencyInput
          name="vbPortion2"
          label="Value Band Portion 2"
          variant="filled"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {selectedBookingCenter?.currency?.code ?? ""}
              </InputAdornment>
            ),
            readOnly: true,
          }}
          fullWidth
          sx={{ mt: "10px" }}
        />
      ) : (
        <React.Fragment />
      )}
      {!isNaN(vbPortion3) && transactionVolume2 && selectedCurrency ? (
        <FormCurrencyInput
          name="vbPortion3"
          label="Value Band Portion 3"
          variant="filled"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {selectedBookingCenter?.currency?.code ?? ""}
              </InputAdornment>
            ),
            readOnly: true,
          }}
          fullWidth
          sx={{ mt: "10px" }}
        />
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};
