import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { SingleSimulationFormInputs } from "../../SingleSimulationForm/types/SingleSimulationFormInputs";
import { useAppSelector } from "../../../../../../hooks/useAppSelector";
import { selectSelectedCurrency } from "../../../../../../features/currencies/currencies.selectors";
import { InputAdornment } from "@mui/material";
import { selectFfeMatrices } from "../../../../../../features/ffeMatrices/ffeMatrices.selectors";
import { selectValueBands } from "../../../../../../features/valueBands/valueBands.selectors";
import { selectSelectedEtdCategory } from "../../../../../../features/etdCategories/etdCategories.selectors";
import { selectSelectedBookingCenter } from "../../../../../../features/bookingCenters/bookingCenters.selectors";
import { getExchangeRate } from "../../../../../../utils/simulationUtils";
import { FormCurrencyInput } from "components/hook-form/FormCurrencyInput";

export const Fees = (): React.ReactElement => {
  const { control, setValue } = useFormContext<SingleSimulationFormInputs>();

  const b1 = useWatch({ control, name: "bjbTaRateBand1" });
  const b2 = useWatch({ control, name: "bjbTaRateBand2" });
  const b3 = useWatch({ control, name: "bjbTaRateBand3" });
  const vb1 = useWatch({ control, name: "vbPortion1" });
  const vb2 = useWatch({ control, name: "vbPortion2" });
  const vb3 = useWatch({ control, name: "vbPortion3" });

  const bookingCenter = useWatch({
    control,
    name: "bookingCenter",
  });

  const selectedBookingCenter = useAppSelector(selectSelectedBookingCenter);
  const selectedCurrency = useAppSelector(selectSelectedCurrency);
  const ffeMatrices = useAppSelector(selectFfeMatrices);
  const valueBands = useAppSelector(selectValueBands);
  const selectedEtdCategory = useAppSelector(selectSelectedEtdCategory);

  const transactionVolume2 = useWatch({ control, name: "transactionVolume2" });
  const futuresFeePerLot = useWatch({ control, name: "futuresFeePerLot" });
  const optionsStandardTransactionFee = useWatch({
    control,
    name: "optionsStandardTransactionFee",
  });
  const futuresStandardTransactionFee = useWatch({
    control,
    name: "futuresStandardTransactionFee",
  });
  const standardTransactionFee = useWatch({
    control,
    name: "standardTransactionFee",
  });
  const exchangeRate = useWatch({ control, name: "exchangeRate" });
  let parsedExchangeRate = parseFloat(exchangeRate ?? "0");

  const quantity = useWatch({ control, name: "quantity" });
  const specialConditionsType = useWatch({
    control,
    name: "specialConditionsType",
  });
  const minFee = useWatch({ control, name: "minFee" });
  const specialConditionsAmount = useWatch({
    control,
    name: "specialConditionsAmount",
  });
  const totalTransactionFee = useWatch({
    control,
    name: "totalTransactionFee",
  });
  const totalTransactionFeeInstrumentCurrency = useWatch({
    control,
    name: "totalTransactionFeeInstrumentCurrency",
  });

  React.useEffect(() => {
    const _optionsStandardTransactionFee =
      (vb1 * b1) / 100 + (vb2 * b2) / 100 + (vb3 * b3) / 100;
    setValue(
      "optionsStandardTransactionFee",
      parseFloat(Number(_optionsStandardTransactionFee).toFixed(2))
    );
  }, [b1, b2, b3, vb1, vb2, vb3, setValue]);

  React.useEffect(() => {
    const _futuresFeePerLot = ffeMatrices.find(
      (fm) => fm?.feeClass?.text.toLowerCase() === "Lots".toLowerCase()
    )?.amount as number;

    setValue("futuresFeePerLot", _futuresFeePerLot);
  }, [ffeMatrices, setValue]);

  React.useEffect(() => {
    if (!futuresFeePerLot) {
      return;
    }

    const _futuresStandardTransactionFee = quantity * futuresFeePerLot;
    setValue("futuresStandardTransactionFee", _futuresStandardTransactionFee);
  }, [futuresFeePerLot, quantity, setValue]);

  React.useEffect(() => {
    if (!selectedEtdCategory) {
      return;
    }

    let _standardTransactionFee;

    if (selectedEtdCategory.text.toLowerCase() === "option") {
      _standardTransactionFee = optionsStandardTransactionFee;
    } else {
      _standardTransactionFee = futuresStandardTransactionFee;
    }

    setValue("standardTransactionFee", _standardTransactionFee);
  }, [
    parsedExchangeRate,
    selectedEtdCategory,
    futuresFeePerLot,
    optionsStandardTransactionFee,
    futuresStandardTransactionFee,
    setValue,
  ]);

  React.useEffect(() => {
    let _totalTransactionFee;

    switch (specialConditionsType) {
      case "no":
        _totalTransactionFee = Math.max(
          isNaN(standardTransactionFee) ? 0 : standardTransactionFee,
          minFee
        );
        break;
      case "Discount in %":
        _totalTransactionFee = Math.max(
          (standardTransactionFee * (100 - specialConditionsAmount)) / 100,
          minFee
        );
        break;
      case "Flat Fee in %":
        const value =
          typeof transactionVolume2 === "number"
            ? (transactionVolume2 * specialConditionsAmount) / 100
            : (parseFloat(transactionVolume2.replace(",", "")) *
                specialConditionsAmount) /
              100;
        _totalTransactionFee = Math.max(value, minFee);
        break;
      case "Flat Fee per Lot":
        _totalTransactionFee = quantity * specialConditionsAmount;
        break;
      default:
        _totalTransactionFee = specialConditionsAmount;
        break;
    }
    setValue("totalTransactionFee", _totalTransactionFee);

    setValue(
      "totalTransactionFeeInstrumentCurrency",
      parseFloat(
        Number(
          _totalTransactionFee /
            getExchangeRate(parsedExchangeRate, bookingCenter)
        ).toFixed(2)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    transactionVolume2,
    specialConditionsType,
    standardTransactionFee,
    quantity,
    specialConditionsAmount,
    minFee,
    parsedExchangeRate,
    setValue,
  ]);

  return (
    <React.Fragment>
      {!isNaN(optionsStandardTransactionFee) &&
      ffeMatrices &&
      valueBands &&
      transactionVolume2 &&
      selectedCurrency ? (
        <FormCurrencyInput
          name={"optionsStandardTransactionFee"}
          label={"Options - Standard Transaction Fee"}
          variant="filled"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {selectedBookingCenter?.currency?.code ?? ""}
              </InputAdornment>
            ),
            readOnly: true,
          }}
          fullWidth
          sx={{ mt: "10px" }}
        />
      ) : (
        <React.Fragment />
      )}
      {futuresFeePerLot && ffeMatrices && selectedCurrency ? (
        <FormCurrencyInput
          name={"futuresFeePerLot"}
          label={"Futures - Fee per Lot"}
          variant="filled"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {selectedBookingCenter?.currency?.code ?? ""}
              </InputAdornment>
            ),
            readOnly: true,
          }}
          fullWidth
          sx={{ mt: "10px" }}
        />
      ) : (
        <React.Fragment />
      )}
      {futuresFeePerLot && quantity && selectedCurrency ? (
        <FormCurrencyInput
          name={"futuresStandardTransactionFee"}
          label={"Futures - Standard Transaction Fee"}
          variant="filled"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {selectedCurrency.code}
              </InputAdornment>
            ),
            readOnly: true,
          }}
          fullWidth
          sx={{ mt: "10px" }}
        />
      ) : (
        <React.Fragment />
      )}
      {standardTransactionFee &&
      selectedEtdCategory &&
      selectedBookingCenter ? (
        <FormCurrencyInput
          name={"standardTransactionFee"}
          label={"Standard Transaction Fee"}
          variant="filled"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {selectedBookingCenter.currency?.code}
              </InputAdornment>
            ),
            readOnly: true,
          }}
          fullWidth
          sx={{ mt: "10px" }}
        />
      ) : (
        <React.Fragment />
      )}
      {!isNaN(totalTransactionFee) && bookingCenter ? (
        <FormCurrencyInput
          name={"totalTransactionFee"}
          label={"Total Transaction Fee (Rate card curr.)"}
          variant="filled"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {selectedBookingCenter?.currency?.code}
              </InputAdornment>
            ),
            readOnly: true,
          }}
          fullWidth
          sx={{ mt: "10px" }}
        />
      ) : (
        <React.Fragment />
      )}
      {!isNaN(totalTransactionFeeInstrumentCurrency) && bookingCenter ? (
        <FormCurrencyInput
          name={"totalTransactionFeeInstrumentCurrency"}
          label={"Total Transaction Fee (Instr. curr.)"}
          variant="filled"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {selectedCurrency?.code}
              </InputAdornment>
            ),
            readOnly: true,
          }}
          fullWidth
          sx={{ mt: "10px" }}
        />
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};
