/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FeeTableUpdateDto } from "../models/FeeTableUpdateDto";
import type { PagedModelEntityModelFcdToBcToEcToPmToSt } from "../models/PagedModelEntityModelFcdToBcToEcToPmToSt";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class FeeTableControllerService {
  /**
   * It updates some columns on FCD_TO_BC_TO_EC_TO_PM_TO_ST table
   * @param feeTableId
   * @param requestBody
   * @param authorization
   * @returns void
   * @throws ApiError
   */
  public static updateFeeTable(
    feeTableId: number,
    requestBody: FeeTableUpdateDto,
    authorization?: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/fee-tables/{feeTableId}",
      path: {
        feeTableId: feeTableId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        403: `Your role must have update grants to FCD_TO_BC_TO_EC_TO_PM_TO_ST table`,
      },
    });
  }

  /**
   * It will return a paged list of the Fee Tables
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param search
   * @param authorization
   * @param pricingModel
   * @param feeMin
   * @param feeCode
   * @param specialConditionType
   * @param bookingCenter
   * @param etdCategory
   * @param discount
   * @param id
   * @returns PagedModelEntityModelFcdToBcToEcToPmToSt Fee Tables list paged and filtered by the given mandatory and optional fields
   * @throws ApiError
   */
  public static getFeeTables(input: {
    page?: number;
    size: number;
    sort?: Array<string>;
    search?: string;
    authorization?: string;
    pricingModel?: string;
    feeMin?: number;
    feeCode?: string;
    specialConditionType?: string;
    bookingCenter?: string;
    etdCategory?: string;
    discount?: number;
    id?: number;
  }): CancelablePromise<PagedModelEntityModelFcdToBcToEcToPmToSt> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/fee-tables",
      headers: {
        Authorization: input.authorization,
      },
      query: {
        page: input.page,
        size: input.size,
        sort: input.sort,
        search: input.search,
        pricingModel: input.pricingModel,
        feeMin: input.feeMin,
        feeCode:input.feeCode,
        specialConditionType: input.specialConditionType,
        bookingCenter: input.bookingCenter,
        etdCategory: input.etdCategory,
        discount: input.discount,
        id: input.id,
      },
      errors: {
        403: `Your role must have read grants to FCD_TO_BC_TO_EC_TO_PM_TO_ST table`,
      },
    });
  }
}
