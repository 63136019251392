import { createSlice } from "@reduxjs/toolkit";
import { EtdCategoryDto } from "../../openapi";

export type EtdCategoriesState = {
  etdCategories: EtdCategoryDto[];
  selected?: EtdCategoryDto;
  isFetching: boolean;
};

export const INITIAL_STATE: EtdCategoriesState = {
  etdCategories: [],
  selected: undefined,
  isFetching: false,
};

const etdCategoriesSlice = createSlice({
  name: "etdCategories",
  initialState: INITIAL_STATE,
  reducers: {
    fetchEtdCategories: (state: EtdCategoriesState) => {
      state.isFetching = true;
    },
    fetchEtdCategoriesSuccess: (state: EtdCategoriesState, action) => {
      state.etdCategories = action.payload.etdCategories;
      state.isFetching = false;
    },
    etdCategorySelected: (state: EtdCategoriesState, action) => {
      state.selected = action.payload;
    },
    resetEtdCategories: (state: EtdCategoriesState) => {
      state.etdCategories = [];
    }
  },
});

export const {
  fetchEtdCategories,
  fetchEtdCategoriesSuccess,
  etdCategorySelected,
  resetEtdCategories
} = etdCategoriesSlice.actions;

export default etdCategoriesSlice.reducer;
