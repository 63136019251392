import { createSlice } from "@reduxjs/toolkit";
import { DirectionDto } from "../../openapi";

export type DirectionsState = {
  directions: DirectionDto[];
  selected?: DirectionDto;
  isFetching: boolean;
};

export const INITIAL_STATE: DirectionsState = {
  directions: [],
  selected: undefined,
  isFetching: false,
};

const directionsSlice = createSlice({
  name: "directions",
  initialState: INITIAL_STATE,
  reducers: {
    fetchDirections: (state: DirectionsState) => {
      state.isFetching = true;
    },
    fetchDirectionsSuccess: (state: DirectionsState, action) => {
      state.directions = action.payload.directions;
      state.isFetching = false;
    },
    directionSelected: (state: DirectionsState, action) => {
      state.selected = action.payload;
    },
    resetDirections: (state: DirectionsState) => {
      state.directions = [];
    }
  },
});

export const {
  fetchDirections,
  fetchDirectionsSuccess,
  directionSelected,
  resetDirections
} = directionsSlice.actions;

export default directionsSlice.reducer;
