/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedModelEntityModelLanguage } from "../models/PagedModelEntityModelLanguage";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class LanguageControllerService {
  /**
   * It will return a paged list of the managed Langueges
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param authorization
   * @param code
   * @param dateFormatExpression
   * @param id
   * @param text
   * @returns PagedModelEntityModelLanguage Language list paged and filtered by the given mandatory and optional fields
   * @throws ApiError
   */
  public static getLanguages(input: {
    page?: number;
    size: number;
    sort?: Array<string>;
    authorization?: string;
    code?: string;
    dateFormatExpression?: string;
    id?: number;
    text?: string;
  }): CancelablePromise<PagedModelEntityModelLanguage> {
    
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/languages",
      headers: {
        Authorization: input.authorization,
      },
      query: {
        page: input.page,
        size: input.size,
        sort: input.sort,
        code: input.code,
        dateFormatExpression: input.dateFormatExpression,
        id: input.id,
        text: input.text,
      },
      errors: {
        403: `Your role must have read grants to language table`,
      },
    });
  }
}
