import { createSlice } from "@reduxjs/toolkit";
import { EntityModelLanguage } from "../../openapi";

export type LanguagesState = {
  languages: EntityModelLanguage[];
  selected?: EntityModelLanguage;
  isFetching: boolean;
};

export const INITIAL_STATE: LanguagesState = {
  languages: [],
  selected: undefined,
  isFetching: false,
};

const languagesSlice = createSlice({
  name: "languages",
  initialState: INITIAL_STATE,
  reducers: {
    fetchLanguages: (state: LanguagesState) => {
      state.isFetching = true;
    },
    fetchLanguagesSuccess: (state: LanguagesState, action) => {
      state.languages = action.payload.languages;
      state.isFetching = false;
    },
    languageSelected: (state: LanguagesState, action) => {
      state.selected = action.payload;
    },
    resetLanguages: (state: LanguagesState) => {
      state.languages = [];
    },
  },
});

export const {
  fetchLanguages,
  fetchLanguagesSuccess,
  languageSelected,
  resetLanguages,
} = languagesSlice.actions;

export default languagesSlice.reducer;
