import { GrantAndFunctionDto } from "../openapi";
import { UserPermissions, UserPermission } from "../types/UserPermission";
import { Location } from "react-router-dom";
import { routePaths } from "../routes/routePaths";

/** Check if the user has permission to access the current route */
export const hasRoutePermission = (
  userPermissions: UserPermissions,
  pathname: Location["pathname"]
) => {
  return userPermissions?.some((userPermission) =>
    userPermission?.paths.find((route) => route === pathname)
  );
};

/** Associate an human redeable label (to a codeGrant) that indicates the kind of the action to perform */
export const mapCodeGrantToLabel = (
  codeGrant: UserPermission["codeGrant"] | undefined
) => {
  if (codeGrant === "U") return "Edit ";
  if (codeGrant === "E") return "Start ";
  return "";
};

/** Associate one or more routes path to a codeFunction */
export const mapCodeFuncToRoutePaths = (
  codeFunction: UserPermission["codeFunction"] | undefined,
  codeGrant: UserPermission["codeGrant"]
) => {
  if (!codeFunction) return ["/"];

  let filteredRoutePaths = routePaths.filter(
    (routePath) => routePath.code === codeFunction
  );

  if (filteredRoutePaths.length === 0) return ["/"];

  let paths = filteredRoutePaths.map((routeObj) => routeObj.path);

  if (codeGrant === "R") {
    return [paths[0]];
  }

  return paths;
};

/** Create a user permission obj from a grant and function obj. */
export const createUserPermission = (
  grantAndFuncObj: GrantAndFunctionDto
): UserPermission => ({
  id: grantAndFuncObj.function.id,
  codeFunction: grantAndFuncObj.function.codeFunction,
  description: grantAndFuncObj.function.description,
  codeGrant: grantAndFuncObj.grant.codeGrant,
  paths: mapCodeFuncToRoutePaths(
    grantAndFuncObj.function.codeFunction,
    grantAndFuncObj.grant.codeGrant
  ),
});

/** Create an array of user permission from a grand and function array */
export const mapGrantsFuncToUserPermissions = (
  grantAndFuncArr: GrantAndFunctionDto[]
): UserPermissions => {
  const UserPermissions: UserPermissions = [];

  const result: Array<UserPermission | undefined> = grantAndFuncArr.reduce((userPermissions, func) => {
    /** Get function grant */
    const currFuncGrant = func.grant.codeGrant;

    /**If we have both R and U permission for the same functionality, we can just consider U functionality. */
    if (currFuncGrant === "R") {
      /** Check in the  created permissions if there is the current with U value*/
      const userPermissionWithUGrand = userPermissions.find(
        (userPermission) =>
          userPermission?.id === func.function.id &&
          userPermission.codeGrant === "U"
      );

      if (!userPermissionWithUGrand) {
        /** if we didn't found the same func with U grant, just create a new permission with R grant */
        const UserPermission = createUserPermission(func);
        userPermissions.push(UserPermission);
      }
    }

    if (currFuncGrant === "U") {
      /** Check in the created permissions if there is the current with R value*/
      const userPermissionWithRGrand = userPermissions.find(
        (userPermission) =>
          userPermission?.id === func.function.id &&
          userPermission.codeGrant === "R"
      );

      if (userPermissionWithRGrand) {
        /** in this case, just update the value of the permission from R to U  */
        userPermissionWithRGrand.codeGrant = "U";
      } else {
        /** create the permission */
        const UserPermission = createUserPermission(func);
        userPermissions.push(UserPermission);
      }
    }

    if (currFuncGrant === "E") {
      const UserPermission = createUserPermission(func);
      userPermissions.push(UserPermission);
    }

    return UserPermissions;
  }, UserPermissions);

  return result;
};
