import { RootState } from "../../app/store";
import { EntityModelLanguage } from "../../openapi";

export const selectIsFetchingLanguages = (state: RootState): boolean =>
  state.lReducer.isFetching;
export const selectLanguages = (state: RootState): EntityModelLanguage[] =>
  state.lReducer.languages;
export const selectSelectedLanguage = (
  state: RootState
): EntityModelLanguage | undefined => state.lReducer.selected;
