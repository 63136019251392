import { createSlice } from "@reduxjs/toolkit";
import { EntityModelOutputDto } from "../../openapi";
import { SingleSimulationFormInputs } from "../../pages/SingleSimulation/components/SingleSimulationPanel/SingleSimulationForm/types/SingleSimulationFormInputs";

export type SingleOutput = EntityModelOutputDto & SingleSimulationFormInputs;

export type SingleOutputState = {
  output?: SingleOutput;
  isFetching: boolean;
};

export const INITIAL_STATE: SingleOutputState = {
  output: undefined,
  isFetching: false,
};

export type FetchSingleOutputAction = {
  type: string;
  payload: SingleSimulationFormInputs & {
    languageId: number;
  };
};

const singleOutputSlice = createSlice({
  name: "singleOutput",
  initialState: INITIAL_STATE,
  reducers: {
    fetchSingleOutput: (
      state: SingleOutputState,
      _action?
    ) => {
      state.isFetching = true;
    },
    fetchSingleOutputSuccess: (state: SingleOutputState, action) => {
      state.output = action.payload.output;
      state.isFetching = false;
    },
    resetSingleOutput: (state: SingleOutputState) => {
      state.output = undefined;
    },
  },
});

export const {
  fetchSingleOutput,
  fetchSingleOutputSuccess,
  resetSingleOutput,
} = singleOutputSlice.actions;

export default singleOutputSlice.reducer;
