import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import { EntityTable } from "../../components/core/EntityTable";
import { AuthenticatedLayout } from "../../components/layouts/AuthenticatedLayout";
import { FfeMatrixBjbControllerService } from "../../openapi";

export const FeeMatrixBJB = () => {
  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <EntityTable
        title="Fee Matrix BJB"
        service={FfeMatrixBjbControllerService.getFfeMatricesBjb}
        endpoint="fee-matrix-bjb"
        columns={[
          {
            label: "Booking Center",
            id: "bookingCenter.text",
            numeric: false,
          },

          {
            label: "Etd Category",
            id: "etdCategory.text",
            numeric: false,
          },
          {
            label: "Fee Class",
            id: "feeClass.text",
            numeric: false,
          },
          {
            label: "Pricing Model",
            id: "pricingModel.text",
            numeric: false,
          },
          {
            label: "Amount",
            id: "amount",
            numeric: true,
          },
          {
            label: "BS Remark",
            id: "bsRemark",
            numeric: false,
          },
          {
            label: "IT Remark",
            id: "itRemark",
            numeric: false,
          },
        ]}
      />
    </AuthenticatedLayout>
  );
};
