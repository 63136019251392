import React from "react";
import { Divider, Grid, Typography } from "@mui/material";
import { FormInput } from "../../../../../../../components/hook-form/FormInput";
import { FormFieldLabel } from "../../../../../../../components/hook-form/FormFieldLabel";
import { useAppSelector } from "../../../../../../../hooks/useAppSelector";
import { selectSelectedBookingCenter } from "../../../../../../../features/bookingCenters/bookingCenters.selectors";
import {
  EntityModelBookingCenter,
  FeeCode,
  FeeCodeControllerService,
} from "../../../../../../../openapi";
import { getSimulationEtdCategoryAndPricingModelBasedOnBookingCenterAndSimNo } from "../../../../../../../utils/simulationUtils";
import { useFormContext } from "react-hook-form";
import { MultipleSimulationFormInputs } from "../../types/MultipleSimulationFormInputs";
import { selectSelectedFeeCode } from "../../../../../../../features/feeCodes/feeCodes.selectors";
import { selectPricingModels } from "../../../../../../../features/pricingModels/pricingModels.selectors";
import { useUser } from "../../../../../../../hooks/useUser";
import { GroupProps } from "../types";
import {
  getEtdCategoryFieldName,
  getPricingModelFieldName,
  getSimulationPossibleFieldName,
} from "../utils";

export const Group1 = (props: GroupProps): React.ReactElement => {
  const { control, setValue } = useFormContext<MultipleSimulationFormInputs>();
  const { simNo } = props;
  const { token } = useUser();

  const bookingCenter = useAppSelector(
    selectSelectedBookingCenter
  ) as EntityModelBookingCenter;

  const feeCode = useAppSelector(selectSelectedFeeCode) as FeeCode;
  const pricingModels = useAppSelector(selectPricingModels);

  React.useEffect(() => {
    if (!bookingCenter) {
      setValue(getEtdCategoryFieldName(simNo), "");
      setValue(getPricingModelFieldName(simNo), "");
      return;
    }

    const { etdCategory, pricingModel } =
      getSimulationEtdCategoryAndPricingModelBasedOnBookingCenterAndSimNo(
        bookingCenter,
        simNo
      );

    setValue(getEtdCategoryFieldName(simNo), etdCategory);
    setValue(getPricingModelFieldName(simNo), pricingModel);
  }, [simNo, bookingCenter, setValue]);

  React.useEffect(() => {
    if (!bookingCenter || pricingModels.length === 0) {
      return;
    }

    const { pricingModel } =
      getSimulationEtdCategoryAndPricingModelBasedOnBookingCenterAndSimNo(
        bookingCenter,
        simNo
      );

    const pricingModelId = pricingModels.find(
      (p) => p.text.toLowerCase() === pricingModel.toLowerCase()
    )?.id as number;

    if (isNaN(pricingModelId)) {
      setValue(getSimulationPossibleFieldName(simNo), "no");
      return;
    }

    FeeCodeControllerService.getSimulationPossible({
      feeCode: feeCode?.code as number ?? 999,
      pricingModelId: pricingModelId,
      authorization: "Bearer " + token,
    }).then((result) => {
      const simulationPossible =
        result?.page && (result.page?.totalElements ?? 0) > 0 ? "yes" : "no";
      setValue(getSimulationPossibleFieldName(simNo), simulationPossible);
    });
  }, [feeCode, bookingCenter, pricingModels, simNo, setValue, token]);

  return (
    <React.Fragment>
      <Typography
        color={"secondary"}
        style={{
          textTransform: "uppercase",
          fontSize: "12px",
          marginTop: "15px",
        }}
      >
        {"Group 1"}
      </Typography>
      <Divider />
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getEtdCategoryFieldName(simNo)}
          label={<FormFieldLabel>ETD Category</FormFieldLabel>}
          control={control}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getPricingModelFieldName(simNo)}
          label={<FormFieldLabel>Segment (Pricing Model)</FormFieldLabel>}
          control={control}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getSimulationPossibleFieldName(simNo)}
          label={<FormFieldLabel>Simulation Possible</FormFieldLabel>}
          control={control}
          fullWidth
          disabled
        />
      </Grid>
    </React.Fragment>
  );
};
