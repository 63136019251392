/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GrantAndFunctionDto } from "../models/GrantAndFunctionDto";
import type { UserActionDto } from "../models/UserActionDto";
import type { UserActionRequest } from "../models/UserActionRequest";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import env from "../../config/env";

export class UserControllerService {
  /**
   * Trace a relevant user action on the db for audit purpose, like an insert, update, or something else
   * @param requestBody
   * @param authorization
   * @returns UserActionDto The traced user action with its assigned id
   * @throws ApiError
   */
  public static addUserAction(
    requestBody: UserActionRequest,
    authorization?: string
  ): CancelablePromise<UserActionDto> {
      return __request(OpenAPI, {
      method: "POST",
      url: "/api/user-action",
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Get all the grants and functions related to a role
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param authorization
   * @returns GrantAndFunctionDto A list of grants and a list of functions related to the role
   * @throws ApiError
   */
  public static getGrantsAndFunctions(input: {
    page?: number;
    size: number;
    sort?: Array<string>;
    authorization?: string;
  }): CancelablePromise<Array<GrantAndFunctionDto>> {
      return __request(OpenAPI, {
      method: "GET",
      url: "/api/grants-funcs",
      headers: {
        Authorization: input.authorization,
      },
      query: {
        page: input.page,
        size: input.size,
        sort: input.sort,
      },
    });
  }
}
