import { createSlice } from "@reduxjs/toolkit";
import { EntityModelValueBand } from "../../openapi";

export type ValueBandsState = {
  valueBands: EntityModelValueBand[];
  selected?: EntityModelValueBand;
  isFetching: boolean;
};

export const INITIAL_STATE: ValueBandsState = {
  valueBands: [],
  selected: undefined,
  isFetching: false,
};

export type FetchValueBandsAction = {
  type: string;
  payload?: {
    search?: string;
  }
}

const valueBandsSlice = createSlice({
  name: "valueBands",
  initialState: INITIAL_STATE,
  reducers: {
    fetchValueBands: (state: ValueBandsState, _action: FetchValueBandsAction) => {
      state.isFetching = true;
    },
    fetchValueBandsSuccess: (state: ValueBandsState, action) => {
      state.valueBands = action.payload.valueBands;
      state.isFetching = false;
    },
    valueBandSelected: (state: ValueBandsState, action) => {
      state.selected = action.payload;
    },
    resetValueBands: (state: ValueBandsState) => {
      state.valueBands = [];
    }
  },
});

export const {
  fetchValueBands,
  fetchValueBandsSuccess,
  valueBandSelected,
  resetValueBands
} = valueBandsSlice.actions;

export default valueBandsSlice.reducer;
