import { useEffect } from "react";
import { Grid, SelectChangeEvent } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { Content } from "../../../../../../components/core/Content";
import { Header } from "../../../../../../components/core/Header";
import { FormFieldLabel } from "../../../../../../components/hook-form/FormFieldLabel";
import { FormInput } from "../../../../../../components/hook-form/FormInput";
import { FormSelect } from "../../../../../../components/hook-form/FormSelect";
import { useAppSelector } from "../../../../../../hooks/useAppSelector";
import {
  selectBookingCenters,
  selectSelectedBookingCenter,
} from "../../../../../../features/bookingCenters/bookingCenters.selectors";
import {
  bookingCenterSelected,
  fetchBookingCenters,
} from "../../../../../../features/bookingCenters/bookingCenters.reducer";
import { useAppDispatch } from "../../../../../../hooks/useAppDispatch";
import { mapEntitiesToFormSelectOptions } from "../../../../../../utils/formUtils";
import { fetchPricingModels } from "../../../../../../features/pricingModels/pricingModels.reducer";
import { fetchLanguages } from "../../../../../../features/languages/languages.reducer";
import { selectLanguages } from "../../../../../../features/languages/languages.selectors";
import { SingleSimulationFormInputs } from "../types/SingleSimulationFormInputs";
import { currencySelected } from "../../../../../../features/currencies/currencies.reducer";
import { selectCurrencies } from "../../../../../../features/currencies/currencies.selectors";
import { useAutoSelectEN } from "../../../../../../features/languages/hooks/useAutoSelectEN";

export const BasicFields = () => {
  const { control, setValue } = useFormContext<SingleSimulationFormInputs>();
  const dispatch = useAppDispatch();

  useAutoSelectEN();

  const bookingCenters = useAppSelector(selectBookingCenters);
  const languages = useAppSelector(selectLanguages);
  const selectedBookingCenter = useAppSelector(selectSelectedBookingCenter);
  const currencies = useAppSelector(selectCurrencies);

  useEffect(() => {
    dispatch(fetchBookingCenters());
    dispatch(fetchLanguages());
  }, [dispatch]);

  useEffect(() => {
    if (selectedBookingCenter) {
      setValue("pricingModel", "");
      setValue("specialConditionsType", "");
      dispatch(
        fetchPricingModels({
          bookingCenterId: selectedBookingCenter.id as number,
        })
      );
    }
  }, [selectedBookingCenter, dispatch, setValue]);

  const onBookingCenterSelected = (evt: SelectChangeEvent<string>) => {
    const bcCode = evt.target.value;
    const selected = bookingCenters.find((bc) => bc.code === bcCode);

    dispatch(bookingCenterSelected(selected));

    setValue("instrumentSecurityCurrency", selected?.currency?.code ?? "");

    const selectedCurrency = currencies.find(
      (c) => c.code === selected?.currency?.code ?? ""
    );
    dispatch(currencySelected(selectedCurrency));
  };

  return (
    <>
      <Content>
        <Header title="Basic Fields" />
      </Content>
      <Grid item xs={6} md={4}>
        <FormInput
          name="portfolioNo"
          label={<FormFieldLabel isRequired>Portfolio No</FormFieldLabel>}
          control={control}
          autoComplete="off"
          fullWidth
          regEx="[]"
          rules={{ required: "PortfolioNo is Required." }}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <FormSelect
          name="outputLanguage"
          label={<FormFieldLabel isRequired>Output Language</FormFieldLabel>}
          control={control}
          fullWidth
          autoComplete="off"
          options={mapEntitiesToFormSelectOptions(languages, {
            useCodeAsLabel: true,
          })}
          rules={{ required: "Output Language is Required." }}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <FormSelect
          name="bookingCenter"
          deps={[currencies]}
          label={<FormFieldLabel isRequired>Booking Center</FormFieldLabel>}
          control={control}
          fullWidth
          handleChange={onBookingCenterSelected}
          autoComplete="off"
          options={mapEntitiesToFormSelectOptions(bookingCenters, {
            useCodeAsLabel: true,
          })}
          rules={{ required: "Booking Center is Required." }}
        />
      </Grid>
    </>
  );
};
