/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedModelEntityModelFcdToBcToEcToPmToSt } from "../models/PagedModelEntityModelFcdToBcToEcToPmToSt";
import type { PagedModelEntityModelFeeCode } from "../models/PagedModelEntityModelFeeCode";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import { PagedModelEntityModelSimulationPossibleDto } from "../../openapi";

export class FeeCodeControllerService {
  /**
   * It will return a paged list of the Fee Codes
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param outputRemark
   * @param code
   * @param name
   * @param id
   * @param text
   * @param sid
   * @returns PagedModelEntityModelFeeCode Fee Codes list paged and filtered by the given mandatory and optional fields
   * @throws ApiError
   */
  public static getFeeCodes(input: {
    page?: number;
    size: number;
    sort?: Array<string>;
    outputRemark?: string;
    code?: number;
    name?: string;
    id?: number;
    text?: string;
    sid?: number;
    authorization?: string;
  }): CancelablePromise<PagedModelEntityModelFeeCode> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/fee-codes",
      headers: {
        Authorization: input.authorization,
      },
      query: {
        page: input.page,
        size: input.size,
        sort: input.sort,
        outputRemark: input.outputRemark,
        code: input.code,
        name: input.name,
        id: input.id,
        text: input.text,
        sid: input.sid,
      },
      errors: {
        403: `Your role must have read grants to fee codes table`,
      },
    });
  }

  /**
   * It will return a paged list of the FcdToBcToEcToPmToSt. It could be used to evaluate the Simulation possible field.
   * @param input
   * @returns PagedModelEntityModelSimulationPossibleDto FcdToBcToEcToPmToSt list paged and filtered by the given mandatory and optional fields
   * @throws ApiError
   */
  public static getSimulationPossible(input: {
    feeCode: number;
    pricingModelId: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
    pricingModel?: string;
    feeMin?: number;
    specialConditionType?: string;
    bookingCenter?: string;
    etdCategory?: string;
    discount?: number;
    id?: number;
    authorization?: string;
  }): CancelablePromise<PagedModelEntityModelSimulationPossibleDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/fee-codes/{feeCode}/pricing-models/{pricingModelId}",
      headers: {
        Authorization: input.authorization,
      },
      path: {
        feeCode: input.feeCode,
        pricingModelId: input.pricingModelId,
      },
      query: {
        page: input.page ?? 0,
        size: input.size ?? 10,
        sort: input.sort,
        pricingModel: input.pricingModel,
        feeMin: input.feeMin,
        specialConditionType: input.specialConditionType,
        bookingCenter: input.bookingCenter,
        etdCategory: input.etdCategory,
        discount: input.discount,
        id: input.id,
      },
      errors: {
        403: `Your role must have read grants to FCD_TO_BC_TO_EC_TO_PM_TO_ST table`,
      },
    });
  }

  /**
   * It will return a paged list of the FcdToBcToEcToPmToSt
   * @param feeCodeId
   * @param bookingCenterId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param pricingModel
   * @param feeMin
   * @param feeCode
   * @param specialConditionType
   * @param bookingCenter
   * @param etdCategory
   * @param discount
   * @param id
   * @returns PagedModelEntityModelFcdToBcToEcToPmToSt FcdToBcToEcToPmToSt list paged and filtered by the given mandatory and optional fields
   * @throws ApiError
   */
  public static getSpecialConditions(input: {
    feeCodeId: number;
    bookingCenterId: number;
    page?: number;
    size: number;
    sort?: Array<string>;
    pricingModel?: string;
    feeMin?: number;
    feeCode?: string;
    specialConditionType?: string;
    bookingCenter?: string;
    etdCategory?: string;
    discount?: number;
    id?: number;
    authorization?: string;
  }): CancelablePromise<PagedModelEntityModelFcdToBcToEcToPmToSt> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/fee-codes/{feeCodeId}/booking-centers/{bookingCenterId}/special-conditions",
      path: {
        feeCodeId: input.feeCodeId,
        bookingCenterId: input.bookingCenterId,
      },
      headers: {
        Authorization: input.authorization,
      },
      query: {
        page: input.page,
        size: input.size,
        sort: input.sort,
        pricingModel: input.pricingModel,
        feeMin: input.feeMin,
        feeCode: input.feeCode,
        specialConditionType: input.specialConditionType,
        bookingCenter: input.bookingCenter,
        etdCategory: input.etdCategory,
        discount: input.discount,
        id: input.id,
      },
      errors: {
        403: `Your role must have read grants to FCD_TO_BC_TO_EC_TO_PM_TO_ST table`,
      },
    });
  }
}
