import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import { Alert, Grid, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Spinner } from "../../../components/core/Spinner";
import { Card } from "../../../components/core/Card";
import { useUser } from "../../../hooks/useUser";
import { FormInput } from "../../../components/hook-form/FormInput";
import { FormFieldLabel } from "../../../components/hook-form/FormFieldLabel";
import {
  EntityModelFeeMatrix3P,
  FeeMatrix3PControllerService,
  FeeMatrix3PUpdateDto,
  UserControllerService,
} from "../../../openapi";
import { AlertMessage } from "../../../components/core/AlertMessage/AlertMessage";
import { parseJwt } from "../../../utils/jwtUtils";

export interface EditFeeMatrix3pInputs {
  source: string;
  rate: string;
  perLot: string;
  bsRemark: string;
  itRemark: string;
  bookingCenter: string;
  pricingModel: string;
  etdCategory: string;
  chargeModel: string;
  currency: string;
  marketPlace: string;
}

export interface EditFeeMatrix3pFormProps {
  id?: string;
}

export const EditFeeMatrix3pForm = (props: EditFeeMatrix3pFormProps) => {
  const [feeMatrix3p, setFeeMatrix3p] = useState<EntityModelFeeMatrix3P>();
  const [feeMatrix3pError, setFeeMatrix3pError] = useState("");
  const [feeMatrix3pIsFetching, setFeeMatrix3pIsFetching] = useState(false);

  const { id } = props;
  const { token, user } = useUser();

  const form = useForm<EditFeeMatrix3pInputs>({
    defaultValues: {
      source: "",
      rate: "",
      perLot: "",
      bookingCenter: "",
      pricingModel: "",
      etdCategory: "",
      chargeModel: "",
      currency: "",
      marketPlace: "",
      bsRemark: "",
      itRemark: "",
    },
  });

  const { reset, formState, handleSubmit, control } = form;

  useEffect(() => {
    setFeeMatrix3pIsFetching(true);
    if (token) {
      FeeMatrix3PControllerService.getFeeMatrices3P({
        id: Number(id),
        authorization: `Bearer ${token}`,
      })
        .then((data) => {
          setFeeMatrix3pIsFetching(false);
          setFeeMatrix3p(data?._embedded?.entities?.[0] ?? {});
          reset({
            source: data?._embedded?.entities?.[0].source ?? "",
            rate:
              data?._embedded?.entities?.[0].rate !== null
                ? String(data?._embedded?.entities?.[0].rate)
                : "",
            perLot:
              data?._embedded?.entities?.[0].perLot !== null
                ? String(data?._embedded?.entities?.[0].perLot)
                : "",
            bookingCenter:
              data?._embedded?.entities?.[0].bookingCenter?.text ?? "",
            pricingModel:
              data?._embedded?.entities?.[0].pricingModel?.text ?? "",
            etdCategory: data?._embedded?.entities?.[0].etdCategory?.text ?? "",
            chargeModel: data?._embedded?.entities?.[0].chargeModel?.text ?? "",
            currency: data?._embedded?.entities?.[0].currency?.text ?? "",
            marketPlace: data?._embedded?.entities?.[0].marketPlace?.text ?? "",
            bsRemark: data?._embedded?.entities?.[0].bsRemark ?? "",
            itRemark: data?._embedded?.entities?.[0].itRemark ?? "",
          });
        })
        .catch((error) => {
          setFeeMatrix3pIsFetching(false);
          setFeeMatrix3pError(error.message);
        });
    }
  }, [id, reset, token]);

  const onSubmit: SubmitHandler<EditFeeMatrix3pInputs> = useCallback(
    (data) => {
      if (id) {
        const { bsRemark, itRemark, source, rate, perLot } = data;

        const bodyReq: FeeMatrix3PUpdateDto = {
          bsRemark: null,
          itRemark: null,
          source: null,
          rate: null,
          perLot: null,
        };

        if (bsRemark) bodyReq.bsRemark = bsRemark;
        if (itRemark) bodyReq.itRemark = itRemark;
        if (source) bodyReq.source = source;
        if (rate) bodyReq.rate = Number(rate);
        if (perLot) bodyReq.perLot = Number(perLot);

        FeeMatrix3PControllerService.updateFeeMatrix3P(
          Number(id),
          bodyReq,
          `Bearer ${token}`
        )
          .then(() => {
            UserControllerService.addUserAction(
              {
                user: parseJwt(token ?? "")?.upn,
                cdOperation: "entity_FeeMatrix3p_edit",
                operation: "ecs_ee",
                description:
                  "The user edited the FeeMatrix3p entity with id " + id,
              },
              `Bearer ${token}`
            );
          })
          .catch((error) => setFeeMatrix3pError(error.message));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, token, user]
  );

  return (
    <Card
      title="Edit Fee Matrix 3p"
      style={{ width: "1512px" }}
      actions={
        feeMatrix3p &&
        !feeMatrix3pError && (
          <LoadingButton
            variant="contained"
            endIcon={<SaveAltOutlinedIcon />}
            form="edit-fee-matrix-3p-form"
            type="submit"
            sx={{ textTransform: "none" }}
            disabled={formState.isSubmitting}
            loading={formState.isSubmitting}
          >
            Save
          </LoadingButton>
        )
      }
      Component={() => (
        <Box sx={{ margin: "20px" }}>
          {feeMatrix3pError && (
            <Alert severity="error">{feeMatrix3pError}</Alert>
          )}
          {feeMatrix3pIsFetching && !feeMatrix3pError && (
            <Spinner height="353px" />
          )}
          {feeMatrix3p && !feeMatrix3pError && (
            <form
              id="edit-fee-matrix-3p-form"
              data-testid="edit-fee-matrix-3p-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container columnSpacing={2} rowSpacing={5}>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="bookingCenter"
                    label={<FormFieldLabel>Booking Center</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="pricingModel"
                    label={<FormFieldLabel>Pricing Model</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="source"
                    label={<FormFieldLabel>Source</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="chargeModel"
                    label={<FormFieldLabel>Charge Model</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="currency"
                    label={<FormFieldLabel>Currency</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="marketPlace"
                    label={<FormFieldLabel>Market Place</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="etdCategory"
                    label={<FormFieldLabel>Etd Category</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="rate"
                    label={<FormFieldLabel>Rate</FormFieldLabel>}
                    fullWidth
                    InputProps={{ inputProps: { min: 0, step: 0.0001 } }}
                    type="number"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    InputProps={{ inputProps: { min: 0, step: 0.0001 } }}
                    name="perLot"
                    label={<FormFieldLabel>Per Lot</FormFieldLabel>}
                    fullWidth
                    type="number"
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="bsRemark"
                    label={<FormFieldLabel>Bs Remark</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="itRemark"
                    label={<FormFieldLabel>It Remark</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
              </Grid>
            </form>
          )}
          {
            <AlertMessage
              show={
                formState.isSubmitted &&
                formState.isSubmitSuccessful &&
                !feeMatrix3pError
              }
              message="Data has been updated correctly"
            />
          }
        </Box>
      )}
    />
  );
};
