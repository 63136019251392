import React from "react";
import { TextField } from "@mui/material";
import { Controller, Path, PathValue } from "react-hook-form";
import type { TextFieldProps } from "@mui/material";
import type { UseControllerProps } from "react-hook-form";
import type { FieldValues } from "react-hook-form/dist/types/fields";

export type FormInputProps<T extends FieldValues = FieldValues> = Omit<
  UseControllerProps<T>,
  "defaultValue"
> &
  Omit<TextFieldProps, "onBlur" | "onChange" | "value" | "error"> & {
    textarea?: boolean;
    decimals?: number;
    minZero?: boolean;
    disableWords?: boolean;
    regEx?: string;
  };

export const FormInput = <T extends FieldValues = FieldValues>(
  props: FormInputProps<T>
) => {
  const {
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister,
    textarea,
    minZero,
    decimals,
    disabled,
    disableWords,
    ...restProps
  } = props;
  let { inputProps, InputProps, regEx } = props;

  const dyRegEx = new RegExp(regEx ? regEx : "_", "i");

  if (minZero) {
    inputProps = inputProps ? { ...inputProps, min: 0 } : { min: 0 };
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        let helperText = null;

        if (textarea && inputProps?.maxLength) {
          helperText = `${value.length}/${inputProps?.maxLength} characters inserted`;
        }

        const calculateDecimals = (_value: PathValue<T, Path<T>> | string) => {
          if (decimals && decimals > 0) {
            if (disabled) {
              _value = Number(_value).toFixed(decimals) as PathValue<
                T,
                Path<T>
              >;
            } else {
              let step = 1;

              for (let i = 0; i < decimals; i++) {
                step /= 10;
              }

              inputProps = { ...(inputProps ?? {}), ...{ step } };
              InputProps = { ...InputProps, ...{ inputProps } };
            }
          }

          return _value;
        };

        const internalValue = calculateDecimals(value);

        const internalChange = (
          event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ): void => {
          let _value = calculateDecimals(event.target.value);

          onChange({
            ...event,
            target: {
              ...event.target,
              value: _value,
            },
          });
        };

        InputProps = { ...InputProps, ...{ inputProps } };

        return (
          <TextField
            id={name}
            name={name}
            value={internalValue}
            onChange={internalChange}
            onBlur={onBlur}
            error={!!error}
            helperText={error?.message ?? helperText}
            disabled={disabled}
            inputProps={inputProps}
            InputProps={InputProps}
            {...restProps}
            onKeyPress={(event) => {
              if (disableWords) {
                const regex = /^[0-9]+$/; // Regular expression per accettare solo numeri
                const key = String.fromCharCode(event.charCode); // Ottieni il carattere inserito dall'utente

                if (!regex.test(key) && key !== "." && key !== ",") {
                  // Se il carattere inserito non è un numero, previene l'inserimento
                  event.preventDefault();
                }
              }
              if (dyRegEx.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        );
      }}
    />
  );
};
