import { takeLatest, put } from "redux-saga/effects";
import { fetchDirections, fetchDirectionsSuccess } from "./directions.reducer";
import { DirectionControllerService, DirectionDto } from "../../openapi";
import authorizedCall from "../../utils/authorizedCall";

export function* fetchDirectionsSaga() {
  const response: DirectionDto[] = yield authorizedCall(
    DirectionControllerService.getDirections,
    {
      page: 0,
      size: 10,
    }
  );

  yield put(fetchDirectionsSuccess({ directions: response }));
}

export default function* rootDirectionsSaga() {
  yield takeLatest(fetchDirections.type, fetchDirectionsSaga);
}
