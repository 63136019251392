import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import { EntityTable } from "../../components/core/EntityTable";
import { AuthenticatedLayout } from "../../components/layouts/AuthenticatedLayout";
import { ValueBandControllerService } from "../../openapi";

export const ValueBand = () => {
  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <EntityTable
        title="Value Bands"
        service={ValueBandControllerService.getValueBands}
        endpoint="value-bands"
        columns={[
          { label: "Code", id: "code", numeric: false },
          { label: "Text", id: "text", numeric: false },
          {
            label: "Minimum value",
            id: "valMin",
            numeric: true,
          },
          {
            label: "Maximum value",
            id: "valMax",
            numeric: true,
          },
          {
            label: "BS Remark",
            id: "bsRemark",
            numeric: false,
          },
          {
            label: "IT Remark",
            id: "itRemark",
            numeric: false,
          },
        ]}
      />
    </AuthenticatedLayout>
  );
};
