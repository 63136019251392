import React, { useMemo, useState } from "react";
import {
  getSimulationEtdCategoryAndPricingModelBasedOnBookingCenterAndSimNo,
  getSimulationThirdPartyChargesModelBasedOnBookingCenterAndSimNo,
} from "../../../../../../../utils/simulationUtils";
import { useAppSelector } from "../../../../../../../hooks/useAppSelector";
import { selectSelectedBookingCenter } from "../../../../../../../features/bookingCenters/bookingCenters.selectors";
import {
  EntityModelBookingCenter,
  EntityModelPricingDto,
  EntityModelSpecialConditionType,
  SpecialConditionTypeControllerService,
} from "../../../../../../../openapi";
import { useFormContext, useWatch } from "react-hook-form";
import { MultipleSimulationFormInputs } from "../../types/MultipleSimulationFormInputs";
import { Divider, Grid, SelectChangeEvent, Typography } from "@mui/material";
import { FormInput } from "../../../../../../../components/hook-form/FormInput";
import { FormFieldLabel } from "../../../../../../../components/hook-form/FormFieldLabel";
import { selectSelectedPricing } from "../../../../../../../features/pricing/pricing.selectors";
import { useAppDispatch } from "../../../../../../../hooks/useAppDispatch";
import {
  fetchSpecialConditionTypes,
  resetSpecialConditionTypes,
  specialConditionTypeForMultipleSimulationSelected,
} from "../../../../../../../features/specialConditionTypes/specialConditionTypes.reducer";
import {
  selectIsMultipleSpecialConditionTypes,
  selectSelectedSpecialConditionTypeForMultipleSimulation1,
  selectSelectedSpecialConditionTypeForMultipleSimulation2,
  selectSelectedSpecialConditionTypeForMultipleSimulation3,
  selectSelectedSpecialConditionTypeForMultipleSimulation4,
  selectSpecialConditionTypes,
  selectSpecialConditionTypesDiscounts,
  selectSpecialConditionTypesMinFees,
} from "../../../../../../../features/specialConditionTypes/specialConditionTypes.selectors";
import { FormSelect } from "../../../../../../../components/hook-form/FormSelect";
import { mapEntitiesWithNoCodeToFormSelectOptions } from "../../../../../../../utils/formUtils";
import { selectEtdCategories } from "../../../../../../../features/etdCategories/etdCategories.selectors";
import {
  selectFfeMatricesForMultipleSimulation1,
  selectFfeMatricesForMultipleSimulation2,
  selectFfeMatricesForMultipleSimulation3,
  selectFfeMatricesForMultipleSimulation4,
} from "../../../../../../../features/ffeMatrices/ffeMatrices.selectors";
import { selectPricingModels } from "../../../../../../../features/pricingModels/pricingModels.selectors";
import { fetchFfeMatricesForMultipleSimulation } from "../../../../../../../features/ffeMatrices/ffeMatrices.reducer";
import { GroupProps } from "../types";
import {
  getChargesModelFieldName,
  getMinimumFeeFieldName,
  getSegmentFieldName,
  getSpecialConditionsAmountFieldName,
  getSpecialConditionsFieldName,
  getSpecialConditionsUnitFieldName,
} from "../utils";
import { selectSelectedFeeCode } from "../../../../../../../features/feeCodes/feeCodes.selectors";
import authorizedCall from "utils/authorizedCall";

export const Group2 = (props: GroupProps): React.ReactElement => {
  const { control, setValue, getValues } =
    useFormContext<MultipleSimulationFormInputs>();
  const { simNo } = props;
  const [specialConditionInPercenge, setSpecialConditionInPercenge] = useState(
    []
  );

  const dispatch = useAppDispatch();
  const bookingCenter = useAppSelector(
    selectSelectedBookingCenter
  ) as EntityModelBookingCenter;

  const pricing = useAppSelector(
    selectSelectedPricing
  ) as EntityModelPricingDto;

  const feeCode = useAppSelector(selectSelectedFeeCode);
  const specialConditions = useAppSelector(selectSpecialConditionTypes);
  const etdCategories = useAppSelector(selectEtdCategories);
  const pricingModels = useAppSelector(selectPricingModels);
  const specialConditionMinFees = useAppSelector(
    selectSpecialConditionTypesMinFees
  );
  const specialConditionDiscounts = useAppSelector(
    selectSpecialConditionTypesDiscounts
  );
  const isMultipleSpecialCondition = useAppSelector(
    selectIsMultipleSpecialConditionTypes
  );

  let ffeMatricesSelector;
  let specialConditionSelected;

  switch (simNo) {
    case 1:
      ffeMatricesSelector = selectFfeMatricesForMultipleSimulation1;
      specialConditionSelected =
        selectSelectedSpecialConditionTypeForMultipleSimulation1;
      break;
    case 2:
      ffeMatricesSelector = selectFfeMatricesForMultipleSimulation2;
      specialConditionSelected =
        selectSelectedSpecialConditionTypeForMultipleSimulation2;
      break;
    case 3:
      ffeMatricesSelector = selectFfeMatricesForMultipleSimulation3;
      specialConditionSelected =
        selectSelectedSpecialConditionTypeForMultipleSimulation3;
      break;
    default:
      ffeMatricesSelector = selectFfeMatricesForMultipleSimulation4;
      specialConditionSelected =
        selectSelectedSpecialConditionTypeForMultipleSimulation4;
      break;
  }
  const ffeMatrices = useAppSelector(ffeMatricesSelector);
  const selectedSpecialCondition = useAppSelector(specialConditionSelected);

  const ticketFeeHost = useWatch({ control, name: "ticketFeeHost" });
  const chargesModel = useWatch({ control, name: "chargesModel" });
  const formValues = getValues();

  const ChangeConditionTypesChanged = (txt: string, simNo: number) => {
    const specialConds =
      simNo === 2 ? specialConditionInPercenge : specialConditions;
    const specialCondition = specialConds.find(
      (sc) => sc.text?.toLowerCase() === txt.toLowerCase()
    );

    dispatch(
      specialConditionTypeForMultipleSimulationSelected({
        selected: specialCondition as EntityModelSpecialConditionType,
        simNo,
      })
    );
  };

  React.useEffect(() => {
    if (formValues.bookingCenter.toLowerCase() === "lux") {
      if (formValues.specialConditions1 !== formValues.specialConditions2) {
        setValue("specialConditions2", formValues.specialConditions1);
        ChangeConditionTypesChanged(formValues.specialConditions1, 2);
      }

      if (formValues.specialConditions3 !== formValues.specialConditions4) {
        setValue("specialConditions4", formValues.specialConditions3);
        ChangeConditionTypesChanged(formValues.specialConditions3, 4);
      }

      if (formValues.minimumFee1 !== formValues.minimumFee2) {
        setValue("minimumFee2", formValues.minimumFee1);
      }

      if (formValues.minimumFee3 !== formValues.minimumFee4) {
        setValue("minimumFee4", formValues.minimumFee3);
      }

      if (
        formValues.specialConditionsAmount1 !==
        formValues.specialConditionsAmount2
      ) {
        setValue(
          "specialConditionsAmount2",
          formValues.specialConditionsAmount1
        );
      }

      if (
        formValues.specialConditionsAmount3 !==
        formValues.specialConditionsAmount4
      ) {
        setValue(
          "specialConditionsAmount4",
          formValues.specialConditionsAmount3
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  React.useEffect(() => {
    if (pricing?.text.toLowerCase() === "manual") {
      setValue(getSpecialConditionsAmountFieldName(simNo), "");
      if (specialConditionInPercenge.length === 0) {
        authorizedCall(
          SpecialConditionTypeControllerService.getSpecialConditionType,
          {
            page: 0,
            size: 10,
            categoryId: 1,
          }
        ).then((res) => {
          setSpecialConditionInPercenge(res);
          (window as any).specialCond = res.find(
            (el: any) => el.text === "Flat Fee in %"
          );
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, pricing, simNo]);

  React.useEffect(() => {
    if (!bookingCenter) {
      setValue(getSegmentFieldName(simNo), "");
      return;
    }

    const { etdCategory, pricingModel } =
      getSimulationEtdCategoryAndPricingModelBasedOnBookingCenterAndSimNo(
        bookingCenter,
        simNo
      );

    setValue(getSegmentFieldName(simNo), etdCategory + "." + pricingModel);
  }, [simNo, bookingCenter, setValue]);

  React.useEffect(() => {
    if (
      !bookingCenter ||
      !etdCategories ||
      etdCategories.length === 0 ||
      !pricingModels ||
      pricingModels.length === 0
    ) {
      return;
    }

    const { etdCategory, pricingModel } =
      getSimulationEtdCategoryAndPricingModelBasedOnBookingCenterAndSimNo(
        bookingCenter,
        simNo
      );

    const categoryId = etdCategories?.find(
      (ec) => ec?.text?.toLowerCase() === etdCategory.toLowerCase() ?? ""
    )?.id as number;

    const pricingModelId = pricingModels?.find(
      (pm) => pm?.text?.toLowerCase() === pricingModel.toLowerCase() ?? ""
    )?.id as number;

    dispatch(
      fetchFfeMatricesForMultipleSimulation({
        bookingCenterId: bookingCenter.id as number,
        pricingModelId: pricingModelId,
        categoryId: categoryId,
        simNo,
      })
    );
  }, [simNo, bookingCenter, etdCategories, pricingModels, setValue, dispatch]);

  React.useEffect(() => {
    if (isMultipleSpecialCondition) {
      return;
    }

    setValue(getSpecialConditionsFieldName(simNo), "");
    setValue(getSpecialConditionsUnitFieldName(simNo), "");

    if (!bookingCenter || !pricing) {
      dispatch(resetSpecialConditionTypes());
      return;
    }

    dispatch(resetSpecialConditionTypes());

    const { etdCategory } =
      getSimulationEtdCategoryAndPricingModelBasedOnBookingCenterAndSimNo(
        bookingCenter,
        simNo
      );

    const categoryId = etdCategories?.find(
      (ec) => ec?.text?.toLowerCase() === etdCategory.toLowerCase() ?? ""
    )?.id as number;

    if (!isNaN(categoryId)) {
      dispatch(fetchSpecialConditionTypes({ categoryId: categoryId }));
    } else {
      dispatch(fetchSpecialConditionTypes({}));
    }
  }, [
    pricing,
    setValue,
    simNo,
    bookingCenter,
    dispatch,
    etdCategories,
    isMultipleSpecialCondition,
  ]);

  React.useEffect(() => {
    if (!selectedSpecialCondition) {
      setValue(getSpecialConditionsUnitFieldName(simNo), "");
      return;
    }

    let unit = selectedSpecialCondition.textForOutput ?? "";
    let amount = specialConditionDiscounts[simNo - 1] ?? 0;

    if (selectedSpecialCondition.text === "Ticket-Fee") {
      unit = bookingCenter.currency?.code as string;
      amount = ticketFeeHost ?? 0;
    }

    setValue(getSpecialConditionsUnitFieldName(simNo), unit);
    setValue(getSpecialConditionsAmountFieldName(simNo), "" + amount);
  }, [
    ticketFeeHost,
    specialConditionDiscounts,
    selectedSpecialCondition,
    setValue,
    simNo,
    bookingCenter,
  ]);

  React.useEffect(() => {
    if (!specialConditions || specialConditions.length === 0) {
      return;
    }

    setValue(
      getSpecialConditionsFieldName(simNo),
      specialConditions[0]?.text ?? ""
    );

    if (!isMultipleSpecialCondition) {
      return;
    }

    dispatch(
      specialConditionTypeForMultipleSimulationSelected({
        selected: specialConditions[simNo - 1],
        simNo,
      })
    );
  }, [
    specialConditions,
    isMultipleSpecialCondition,
    simNo,
    setValue,
    dispatch,
  ]);

  React.useEffect(() => {
    if (!ffeMatrices || ffeMatrices.length === 0) {
      return;
    }

    if (feeCode) {
      return;
    }

    const minimumFee =
      ffeMatrices.find(
        (fm) => fm.feeClass?.text.toLowerCase() === "min. Fee".toLowerCase()
      )?.amount ?? 0;

    setValue(getMinimumFeeFieldName(simNo), "" + minimumFee);
  }, [feeCode, ffeMatrices, simNo, setValue]);

  React.useEffect(() => {
    if (
      !specialConditionMinFees ||
      specialConditionMinFees.length === 0 ||
      !feeCode
    ) {
      return;
    }

    const minimumFee = specialConditionMinFees[simNo - 1] ?? 0;

    setValue(getMinimumFeeFieldName(simNo), "" + minimumFee);
  }, [feeCode, specialConditionMinFees, ffeMatrices, simNo, setValue]);

  React.useEffect(() => {
    if (!bookingCenter || !chargesModel) {
      return;
    }

    const chargesModelSim =
      getSimulationThirdPartyChargesModelBasedOnBookingCenterAndSimNo(
        bookingCenter,
        chargesModel,
        simNo
      );
    setValue(getChargesModelFieldName(simNo), chargesModelSim);
  }, [bookingCenter, chargesModel, simNo, setValue]);

  const onSpecialConditionTypesChanged = (evt: SelectChangeEvent<string>) => {
    const scText = evt.target.value;
    const specConds =
      scText === "Flat Fee in %"
        ? specialConditionInPercenge
        : specialConditions;
    const specialCondition = specConds.find(
      (sc) => sc.text?.toLowerCase() === scText.toLowerCase()
    );

    dispatch(
      specialConditionTypeForMultipleSimulationSelected({
        selected: specialCondition as EntityModelSpecialConditionType,
        simNo,
      })
    );
  };

  const luxemburgDisabled = useMemo(
    () =>
      pricing?.text.toLowerCase() !== "manual" ||
      (simNo % 2 === 0 && bookingCenter.code?.toLowerCase() === "lux"),
    [pricing?.text, simNo, bookingCenter?.code]
  );

  const luxemburgRequired = useMemo(
    () =>
      pricing?.text.toLowerCase() === "manual" &&
      !(simNo % 2 === 0 && bookingCenter.code?.toLowerCase() === "lux"),
    [pricing?.text, simNo, bookingCenter?.code]
  );

  const specialConditionIsNo = useMemo(() => {
    return !selectedSpecialCondition || selectedSpecialCondition?.text === "no";
  }, [selectedSpecialCondition]);

  return (
    <React.Fragment>
      <Typography
        color={"secondary"}
        style={{
          textTransform: "uppercase",
          fontSize: "12px",
          marginTop: "15px",
        }}
      >
        {"Group 2"}
      </Typography>
      <Divider />
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getSegmentFieldName(simNo)}
          label={<FormFieldLabel>Segment</FormFieldLabel>}
          control={control}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormSelect
          name={getSpecialConditionsFieldName(simNo)}
          label={
            <FormFieldLabel isRequired={luxemburgRequired}>
              Special Conditions
            </FormFieldLabel>
          }
          control={control}
          handleChange={onSpecialConditionTypesChanged}
          fullWidth
          autoComplete="off"
          disabled={luxemburgDisabled}
          options={
            specialConditionInPercenge.length > 1 &&
            (simNo === 1 || simNo === 2)
              ? mapEntitiesWithNoCodeToFormSelectOptions(
                  specialConditionInPercenge
                )
              : mapEntitiesWithNoCodeToFormSelectOptions(specialConditions)
          }
          rules={
            luxemburgRequired
              ? { required: "Special Conditions is Required." }
              : { required: false }
          }
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getSpecialConditionsUnitFieldName(simNo)}
          label={<FormFieldLabel>Special Conditions Unit</FormFieldLabel>}
          control={control}
          fullWidth
          autoComplete="off"
          disabled
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getSpecialConditionsAmountFieldName(simNo)}
          label={
            <FormFieldLabel
              isRequired={luxemburgRequired && !specialConditionIsNo}
            >
              Special Conditions Amount
            </FormFieldLabel>
          }
          control={control}
          minZero
          fullWidth
          type="number"
          decimals={2}
          inputProps={{ min: 0 }}
          autoComplete="off"
          disabled={luxemburgDisabled || specialConditionIsNo}
          rules={
            luxemburgRequired && !specialConditionIsNo
              ? { required: "Special Conditions Amount is Required." }
              : { required: false }
          }
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getMinimumFeeFieldName(simNo)}
          label={<FormFieldLabel>Minimum Fee</FormFieldLabel>}
          type="number"
          decimals={2}
          inputProps={{ min: 0 }}
          control={control}
          fullWidth
          autoComplete="off"
          disabled={luxemburgDisabled}
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getChargesModelFieldName(simNo)}
          label={<FormFieldLabel>3rd Party Charges Model</FormFieldLabel>}
          control={control}
          fullWidth
          autoComplete="off"
          disabled
        />
      </Grid>
    </React.Fragment>
  );
};
