import { RootState } from "../../app/store";
import { EntityModelMarketPlaceDto } from "../../openapi";

export let selectIsFetchingMarketPlaces = (state: RootState): boolean =>
  state.mpReducer.isFetching;
export let selectMarketPlaces = (
  state: RootState
): EntityModelMarketPlaceDto[] => state.mpReducer.marketPlaces;
export let selectSelectedMarketPlace = (
  state: RootState
): EntityModelMarketPlaceDto | undefined => state.mpReducer.selected;
