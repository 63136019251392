import { RootState } from "../../app/store";
import { EntityModelBookingCenter } from "../../openapi";

export const selectIsFetchingBookingCenters = (state: RootState): boolean =>
  state.bcReducer.isFetching;
export const selectBookingCenters = (state: RootState): EntityModelBookingCenter[] =>
  state.bcReducer.bookingCenters;
export let selectSelectedBookingCenter = (
  state: RootState
): EntityModelBookingCenter | undefined => state.bcReducer.selected;
