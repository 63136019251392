import React from "react";
import { useAuth } from "react-oidc-context";

export const Login = () => {
  const auth = useAuth();

  React.useEffect(() => {
    auth.signinRedirect().catch((e) => {
      console.log(e);
    });
  }, [auth]);

  return <React.Fragment></React.Fragment>;
};

export default Login;
