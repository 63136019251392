export const defaultValues = {
  portfolioNo: "",
  outputLanguage: "",
  bookingCenter: "",
  instrumentType: "",
  etdCategory: "",
  transaction: "",
  direction: "",
  quantity: 0,
  instrumentShortName: "",
  instrumentSecurityNumber: "",
  instrumentSecurityCurrency: "",
  exchangeRate: "1",
  underlyingFullName: "",
  tickerIsinOfUnderlying: "",
  expiry: "",
  strike: "",
  pricingModel: "",
  thirdPartyChargesModel: "",
  stockExchangeIndicative: "",
  simulateThirdPartyCharges: "yes",
  specialConditionsType: "",
  specialConditionsAmount: 0,
  contractLotSize: 0,
  premiumPerContractLot: 0,
  remarks: "",
  noStandardMinimumFee: 0,
  userUid: "",
  userName: "",
  premiumDirection: "",
  bookingCenterRateCardCurrency: "",
  transactionVolume1: "",
  transactionVolume2: "",
  valueBand: "",
  vbPortion1: 0,
  vbPortion2: 0,
  vbPortion3: 0,
  bjbTaRateMinFee: 0,
  minFee: 0,
  bjbTaRateBand1: 0,
  bjbTaRateBand2: 0,
  bjbTaRateBand3: 0,
  optionsStandardTransactionFee: 0,
  futuresFeePerLot: 0,
  futuresStandardTransactionFee: 0,
  standardTransactionFee: 0,
  totalTransactionFee: 0,
  totalTransactionFeeInstrumentCurrency: 0,
  thirdChargesInPercentage: 0,
  thirdChargesPerLot: 0,
  totalThirdCharges: 0,
  totalFee: 0,
};
