import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  FormHelperText,
  SelectChangeEvent,
} from "@mui/material";
import { Controller, useController } from "react-hook-form";
import type { UseControllerProps } from "react-hook-form";
import type { SelectProps } from "@mui/material";
import type { FieldValues } from "react-hook-form/dist/types/fields";
import { useEffect } from "react";

export type FormSelectOption = {
  label: string;
  value: string;
};

export type FormSelectProps<T extends FieldValues = FieldValues> = Omit<
  UseControllerProps<T>,
  "defaultValue"
> &
  Omit<
    SelectProps,
    "children" | "defaultValue" | "labelId" | "value" | "onBlur" | "onChange"
  > & {
    options: FormSelectOption[];
    handleChange?: (evt: SelectChangeEvent<string>) => void;
    deps?: Array<any>;
    noFirstOption?: boolean;
  };

export const FormSelect = <T extends FieldValues = FieldValues>(
  props: FormSelectProps<T>
) => {
  const {
    name,
    control,
    rules,
    shouldUnregister,
    label,
    handleChange,
    deps,
    noFirstOption,
    ...restProps
  } = props;

  let { options } = props;

  const { field } = useController({
    name,
    control,
    rules,
    shouldUnregister,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onInternalChange = (value: string) => {
    field.onChange(value);
    if (!noFirstOption) {
      handleChange?.({
        target: {
          value,
        },
      } as SelectChangeEvent<string>);
    }
  };

  useEffect(() => {
    if (options.length === 1) {
      onInternalChange && onInternalChange(options[0].value);
    }
  }, [options, onInternalChange]);

  // Deduplication
  options = options.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.value === value.value)
  );

  const isEmpty = (_value: any) => {
    let _internalDisabled = false;

    switch (typeof _value) {
      case "object":
        _internalDisabled = _value.length === 0;
    }

    return _value === null || _value === undefined || _internalDisabled;
  };

  const depsDisabled = deps && deps.filter((dep) => isEmpty(dep)).length > 0;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState: { error } }) => (
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth error={!!error?.message}>
            <InputLabel id={`${name}-label`}>{label}</InputLabel>
            <Select
              name={name}
              id={`${name}-select`}
              labelId={`${name}-label`}
              onChange={(value) => {
                field.onChange(value);
                handleChange?.(value);
              }}
              onBlur={field.onBlur}
              value={field.value}
              error={!!error}
              label={label}
              disabled={options.length === 0 || depsDisabled}
              data-testid={name}
              {...restProps}
            >
              {options.map((option) => (
                <MenuItem key={option.label} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{error?.message ?? null}</FormHelperText>
          </FormControl>
        </Box>
      )}
    />
  );
};
