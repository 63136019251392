import { RootState } from "../../app/store";
import { EntityModelFeeMatrix3P } from "../../openapi";

export const selectIsFetchingFeeMatrices3P = (state: RootState): boolean =>
  state.fm3pReducer.isFetching;
export const selectFeeMatrices3P = (
  state: RootState
): EntityModelFeeMatrix3P[] => state.fm3pReducer.feeMatrices3P;
export const selectSelectedFfeMatrix = (
  state: RootState
): EntityModelFeeMatrix3P | undefined => state.fm3pReducer.selected;
