/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExpiryDateDto } from "../models/ExpiryDateDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class UtilsControllerService {
  /**
   * It returns the expiry date used to transfer data from a multiple simulation to a single simulation
   * @param input
   * @returns ExpiryDateDto Expiry date
   * @throws ApiError
   */
  public static getExpiryDate(input: {
    authorization?: string;
  }): CancelablePromise<ExpiryDateDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/utils/expiry-date",
      headers: {
        Authorization: input.authorization,
      },
      errors: {
        403: `Your role must have execute grants to multiple simulations`,
      },
    });
  }
}
