import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import { EntityTable } from "../../components/core/EntityTable";
import { AuthenticatedLayout } from "../../components/layouts/AuthenticatedLayout";
import { FeeTableControllerService } from "../../openapi";

export const FeeTable = () => {
  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <EntityTable
        title="Fee Table"
        service={FeeTableControllerService.getFeeTables}
        endpoint="fee-table"
        columns={[
          {
            label: "Booking Center",
            id: "bookingCenter.text",
            numeric: false,
          },
          {
            label: "Pricing Model",
            id: "pricingModel.text",
            numeric: false,
          },
          {
            label: "Discount",
            id: "discount",
            numeric: true,
          },
          {
            label: "Etd Category",
            id: "etdCategory.text",
            numeric: false,
          },
          {
            label: "Special Condition Type",
            id: "specialConditionType.text",
            numeric: false,
          },
          {
            label: "Fee Code",
            id: "feeCode.text",
            numeric: false,
          },
          {
            label: "Fee Min",
            id: "feeMin",
            numeric: true,
          },

          {
            label: "BS Remark",
            id: "bsRemark",
            numeric: false,
          },
          {
            label: "IT Remark",
            id: "itRemark",
            numeric: false,
          },
        ]}
      />
    </AuthenticatedLayout>
  );
};
