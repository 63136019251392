/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FfeMatrixBjbUpdateDto } from "../models/FfeMatrixBjbUpdateDto";
import type { PagedModelEntityModelFfeMatrixBjb } from "../models/PagedModelEntityModelFfeMatrixBjb";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class FfeMatrixBjbControllerService {
  /**
   * It updates some columns on ffe matrix bjb table
   * @param ffeMatrixBjbId
   * @param requestBody
   * @param authorization
   * @returns void
   * @throws ApiError
   */
  public static updateFfeMatrixBjb(
    ffeMatrixBjbId: number,
    requestBody: FfeMatrixBjbUpdateDto,
    authorization?: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/ffe-matrix-bjb/{ffeMatrixBjbId}",
      path: {
        ffeMatrixBjbId: ffeMatrixBjbId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        403: `Your role must have update grants to FFE_MATRIX_BJB table`,
      },
    });
  }

  /**
   * It will return a paged list of ffe matrix bjb records
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param search
   * @param authorization
   * @param pricingModel
   * @param amount
   * @param bookingCenter
   * @param etdCategory
   * @param id
   * @param feeClass
   * @returns PagedModelEntityModelFfeMatrixBjb Ffe Matrix Bjb list paged and filtered by the given mandatory and optional fields
   * @throws ApiError
   */
  public static getFfeMatricesBjb(input: {
    page?: number;
    size: number;
    sort?: Array<string>;
    search?: string;
    authorization?: string;
    pricingModel?: string;
    amount?: number;
    bookingCenter?: string;
    etdCategory?: string;
    id?: number;
    feeClass?: string;
  }): CancelablePromise<PagedModelEntityModelFfeMatrixBjb> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/ffe-matrix-bjb",
      headers: {
        Authorization: input.authorization,
      },
      query: {
        page: input.page,
        size: input.size,
        sort: input.sort,
        search: input.search,
        pricingModel: input.pricingModel,
        amount: input.amount,
        bookingCenter: input.bookingCenter,
        etdCategory: input.etdCategory,
        id: input.id,
        feeClass: input.feeClass,
      },
      errors: {
        403: `Your role must have read grants to ffe matrix bjb table`,
      },
    });
  }
}
