import { takeLatest, put } from "redux-saga/effects";
import { fetchGrantFuncs, fetchGrantFuncsSuccess } from "./grantFuncs.reducer";
import { GrantAndFunctionDto, UserControllerService } from "../../openapi";
import authorizedCall from "../../utils/authorizedCall";

export function* fetchGrantFuncsSaga() {
  const response: GrantAndFunctionDto[] = yield authorizedCall(
    UserControllerService.getGrantsAndFunctions,
    {
      page: 0,
      size: 10,
    }
  );

  yield put(fetchGrantFuncsSuccess({ grantFuncs: response }));
}

export default function* rootGrantFuncsSaga() {
  yield takeLatest(fetchGrantFuncs.type, fetchGrantFuncsSaga);
}
