import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import { Alert, Grid, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Spinner } from "../../../components/core/Spinner";
import { Card } from "../../../components/core/Card";
import { useUser } from "../../../hooks/useUser";
import { FormInput } from "../../../components/hook-form/FormInput";
import { FormFieldLabel } from "../../../components/hook-form/FormFieldLabel";
import {
  EntityModelValueBand,
  UserControllerService,
  ValueBandControllerService,
  ValueBandUpdateDto,
} from "../../../openapi";
import { AlertMessage } from "../../../components/core/AlertMessage/AlertMessage";
import { parseJwt } from "../../../utils/jwtUtils";

export interface EditValueBandInputs {
  bsRemark: string;
  itRemark: string;
  code: string;
  text: string;
  valMin: string;
  valMax: string;
}

export interface EditValueBandFormProps {
  id?: string;
}

export const EditValueBandForm = (props: EditValueBandFormProps) => {
  const [valueBand, setValueBand3p] = useState<EntityModelValueBand>();
  const [valueBandError, setValueBandError] = useState("");
  const [valueBandIsFetching, setValueBandIsFetching] = useState(false);

  const { id } = props;
  const { token, user } = useUser();

  const form = useForm<EditValueBandInputs>({
    defaultValues: {
      bsRemark: "",
      itRemark: "",
      valMin: "",
      valMax: "",
      code: "",
      text: "",
    },
  });

  const { reset, formState, handleSubmit, control } = form;

  useEffect(() => {
    setValueBandIsFetching(true);
    if (token) {
      ValueBandControllerService.getValueBands({
        id: Number(id),
        authorization: `Bearer ${token}`,
        size: 10,
      })
        .then((data) => {
          setValueBandIsFetching(false);
          setValueBand3p(data?._embedded?.entities?.[0] ?? {});
          reset({
            bsRemark: data?._embedded?.entities?.[0].bsRemark ?? "",
            itRemark: data?._embedded?.entities?.[0].itRemark ?? "",
            valMin:
              data?._embedded?.entities?.[0].valMin !== null
                ? String(data?._embedded?.entities?.[0].valMin)
                : "",
            valMax:
              data?._embedded?.entities?.[0].valMax !== null
                ? String(data?._embedded?.entities?.[0].valMax)
                : "",
            code: data?._embedded?.entities?.[0].code ?? "",
            text: data?._embedded?.entities?.[0].text ?? "",
          });
        })
        .catch((error) => {
          setValueBandIsFetching(false);
          setValueBandError(error.message);
        });
    }
  }, [id, reset, token]);

  const onSubmit: SubmitHandler<EditValueBandInputs> = useCallback(
    (data) => {
      if (id) {
        const { bsRemark, itRemark, valMax, valMin } = data;

        const bodyReq: ValueBandUpdateDto = {
          bsRemark: null,
          itRemark: null,
          valMax: null,
          valMin: null,
        };

        if (bsRemark) bodyReq.bsRemark = bsRemark;
        if (itRemark) bodyReq.itRemark = itRemark;
        if (valMax) bodyReq.valMax = Number(valMax);
        if (valMin) bodyReq.valMin = Number(valMin);

        ValueBandControllerService.updateValueBand(
          Number(id),
          bodyReq,
          `Bearer ${token}`
        )
          .then(() => {
            UserControllerService.addUserAction(
              {
                user: parseJwt(token ?? "")?.upn,
                cdOperation: "entity_ValueBand_edit",
                operation: "ecs_ee",
                description:
                  "The user edited the ValueBand entity with id " + id,
              },
              `Bearer ${token}`
            );
          })
          .catch((error) => setValueBandError(error.message));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, token, user]
  );

  return (
    <Card
      title="Edit Value Bands"
      style={{ width: "1512px" }}
      actions={
        valueBand &&
        !valueBandError && (
          <LoadingButton
            variant="contained"
            endIcon={<SaveAltOutlinedIcon />}
            form="edit-fee-matrix-3p-form"
            type="submit"
            sx={{ textTransform: "none" }}
            disabled={formState.isSubmitting}
            loading={formState.isSubmitting}
          >
            Save
          </LoadingButton>
        )
      }
      Component={() => (
        <Box sx={{ margin: "20px" }}>
          {valueBandError && <Alert severity="error">{valueBandError}</Alert>}
          {valueBandIsFetching && !valueBandError && <Spinner height="353px" />}
          {valueBand && !valueBandError && (
            <form
              id="edit-fee-matrix-3p-form"
              data-testid="edit-fee-matrix-3p-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container columnSpacing={2} rowSpacing={5}>
                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="text"
                    label={<FormFieldLabel>Text</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="code"
                    label={<FormFieldLabel>Code</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="valMin"
                    label={<FormFieldLabel>Val Min</FormFieldLabel>}
                    fullWidth
                    InputProps={{ inputProps: { min: 0, step: 0.0001 } }}
                    type="number"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="valMax"
                    label={<FormFieldLabel>Val Max</FormFieldLabel>}
                    fullWidth
                    InputProps={{ inputProps: { min: 0, step: 0.0001 } }}
                    type="number"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="bsRemark"
                    label={<FormFieldLabel>Bs Remark</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="itRemark"
                    label={<FormFieldLabel>It Remark</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
              </Grid>
            </form>
          )}
          <AlertMessage
            show={
              formState.isSubmitted &&
              formState.isSubmitSuccessful &&
              !valueBandError
            }
            message="Data has been updated correctly"
          />
        </Box>
      )}
    />
  );
};
