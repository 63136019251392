import React from "react";
import { FormInput } from "../../../../../../components/hook-form/FormInput";
import { InputAdornment } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { SingleSimulationFormInputs } from "../../SingleSimulationForm/types/SingleSimulationFormInputs";
import {
  calculateTransactionVolumeBC,
  calculateTransactionVolumeISC,
  getBookingCenterRateCardCurrency,
  getPremiumDirection,
} from "../../../../../../utils/simulationUtils";
import { commify } from "../../../../../../utils/numbersUtils";
import { useAppSelector } from "../../../../../../hooks/useAppSelector";
import { selectSelectedCurrency } from "../../../../../../features/currencies/currencies.selectors";
import { selectSelectedBookingCenter } from "../../../../../../features/bookingCenters/bookingCenters.selectors";
import { FormCurrencyInput } from "components/hook-form/FormCurrencyInput";

export const Basic = (): React.ReactElement => {
  const { control, setValue } = useFormContext<SingleSimulationFormInputs>();

  const selectedBookingCenter = useAppSelector(selectSelectedBookingCenter);

  const transaction = useWatch({ control, name: "transaction" });
  const bookingCenter = useWatch({
    control,
    name: "bookingCenter",
  });
  const quantity = useWatch({ control, name: "quantity" });
  const contractLotSize = useWatch({ control, name: "contractLotSize" });
  const premiumPerContractLot = useWatch({
    control,
    name: "premiumPerContractLot",
  });
  const exchangeRate = useWatch({ control, name: "exchangeRate" });
  const transactionVolume1 = useWatch({ control, name: "transactionVolume1" });
  const selectedCurrency = useAppSelector(selectSelectedCurrency);

  React.useEffect(() => {
    if (transaction) {
      setValue("premiumDirection", getPremiumDirection(transaction));
    }
  }, [setValue, transaction]);

  React.useEffect(() => {
    if (bookingCenter) {
      setValue(
        "bookingCenterRateCardCurrency",
        getBookingCenterRateCardCurrency(bookingCenter)
      );
    }
  }, [bookingCenter, setValue, transaction]);

  React.useEffect(() => {
    if (quantity && contractLotSize && premiumPerContractLot) {
      setValue(
        "transactionVolume1",
        calculateTransactionVolumeISC(
          quantity,
          contractLotSize,
          premiumPerContractLot
        ) as unknown as string // the calculation of the output requires a string
      );
    }
  }, [contractLotSize, premiumPerContractLot, quantity, setValue]);

  React.useEffect(() => {
    if (exchangeRate && transactionVolume1 && selectedCurrency) {
      setValue(
        "transactionVolume2",
        commify(
          calculateTransactionVolumeBC(
            transactionVolume1 as unknown as number,
            parseFloat(exchangeRate),
            bookingCenter
          )
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchangeRate, selectedCurrency, setValue, transactionVolume1]);
  return (
    <React.Fragment>
      {transaction && (
        <FormInput
          name="premiumDirection"
          label="Premium Direction"
          variant="filled"
          InputProps={{ readOnly: true }}
          fullWidth
          sx={{ mt: "10px" }}
        />
      )}
      {bookingCenter && (
        <FormInput
          name="bookingCenterRateCardCurrency"
          label="Booking Center / Rate Card Currency"
          variant="filled"
          InputProps={{ readOnly: true }}
          fullWidth
          sx={{ mt: "10px" }}
        />
      )}
      {!!quantity &&
        !!contractLotSize &&
        !!premiumPerContractLot &&
        selectedCurrency && (
          <FormCurrencyInput
            name="transactionVolume1"
            label="Transaction Volume"
            variant="filled"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {selectedCurrency.code}
                </InputAdornment>
              ),
              readOnly: true,
            }}
            fullWidth
            sx={{ mt: "10px" }}
          />
        )}

      {!!exchangeRate && transactionVolume1 && selectedCurrency && (
        <FormCurrencyInput
          name="transactionVolume2"
          label="Transaction Volume"
          variant="filled"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {selectedBookingCenter?.currency?.code ?? ""}
              </InputAdornment>
            ),
            readOnly: true,
          }}
          fullWidth
          sx={{ mt: "10px" }}
        />
      )}
    </React.Fragment>
  );
};
