import { RootState } from "../../app/store";
import { EntityModelSpecialConditionType } from "../../openapi";

export const selectIsFetchingSpecialConditionTypes = (
  state: RootState
): boolean => state.sctReducer.isFetching;
export let selectIsMultipleSpecialConditionTypes = (
  state: RootState
): boolean => state.sctReducer.isMultiple;
export let selectSpecialConditionTypes = (
  state: RootState
): EntityModelSpecialConditionType[] => state.sctReducer.specialConditionTypes;
export const selectSelectedSpecialConditionType = (
  state: RootState
): EntityModelSpecialConditionType | undefined => state.sctReducer.selected;
export let selectSelectedSpecialConditionTypeForMultipleSimulation1 = (
  state: RootState
): EntityModelSpecialConditionType | undefined =>
  state.sctReducer.selectedForMultipleSimulation1;
export const selectSelectedSpecialConditionTypeForMultipleSimulation2 = (
  state: RootState
): EntityModelSpecialConditionType | undefined =>
  state.sctReducer.selectedForMultipleSimulation2;
export const selectSelectedSpecialConditionTypeForMultipleSimulation3 = (
  state: RootState
): EntityModelSpecialConditionType | undefined =>
  state.sctReducer.selectedForMultipleSimulation3;
export const selectSelectedSpecialConditionTypeForMultipleSimulation4 = (
  state: RootState
): EntityModelSpecialConditionType | undefined =>
  state.sctReducer.selectedForMultipleSimulation4;
export let selectSpecialConditionTypesDiscounts = (
  state: RootState
): number[] => state.sctReducer.discounts;
export let selectSpecialConditionTypesMinFees = (
  state: RootState
): number[] => state.sctReducer.minFees;
