import { RoutePaths } from "../types/RoutePath";

/**Please keep this order when insert routes: read, edit */
export const routePaths: RoutePaths = [
  {
    name: "Fee Matrix 3P",
    path: "/table/fee-matrix-3p",
    code: "FEE_MATRIX_3P",
  },
  {
    name: "Fee Matrix 3P",
    path: "/table/fee-matrix-3p/edit",
    code: "FEE_MATRIX_3P",
  },
  {
    name: "Instrument",
    path: "/table/instruments",
    code: "IM",
  },
  {
    name: "Instrument",
    path: "/table/instruments/edit",
    code: "IM",
  },
  {
    name: "Fee Matrix BJB",
    path: "/table/fee-matrix-bjb",
    code: "FFE_MATRIX_BJB",
  },
  {
    name: "Fee Matrix BJB",
    path: "/table/fee-matrix-bjb/edit",
    code: "FFE_MATRIX_BJB",
  },
  {
    name: "Fee Class",
    path: "/table/fee-class",
    code: "FC",
  },
  {
    name: "Fee Class",
    path: "/table/fee-class/edit",
    code: "FC",
  },
  {
    name: "Value Band",
    path: "/table/value-bands",
    code: "VB",
  },
  {
    name: "Value Band",
    path: "/table/value-bands/edit",
    code: "VB",
  },
  {
    name: "Fee Table",
    path: "/table/fee-table",
    code: "FCD_TO_BC_TO_EC_TO_PM_TO_ST",
  },
  {
    name: "Fee Table",
    path: "/table/fee-table/edit",
    code: "FCD_TO_BC_TO_EC_TO_PM_TO_ST",
  },
  {
    name: "Start Simulation",
    path: "/single-simulation",
    code: "SINGLE_SIMULATION",
  },
  {
    name: "Multiple Simulation",
    path: "/multiple-simulation",
    code: "MULTIPLE_SIMULATION",
  },
];
