export const commify = (n: number, decimals: number = 2): string => {
  const parts = n.toFixed(decimals).toString().split(".");
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;

  return (
    numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "")
  );
};

export const apexify = (n: number, decimals: number = 2): string => {
  const exponential = 10 ** decimals;
  const parts = (Math.round(n * exponential) / exponential)
    .toFixed(decimals)
    .toString()
    .split(".");
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;

  if (decimals === 0) {
    return numberPart.replace(thousands, "'");
  }

  return (
    numberPart.replace(thousands, "'") + (decimalPart ? "." + decimalPart : "")
  );
};

export const withDecimalPrecision = (n: number) => {
  const hasDecimalPart = String(n).split(".")?.[1];
  const decimals = hasDecimalPart
    ? Number(String(n).split(".")?.[1]?.length)
    : 0;

  return apexify(n, decimals);
};
