import React from "react";
import { SingleSimulationInternal } from "../SingleSimulation";
import { selectBookingCenters } from "../../features/bookingCenters/bookingCenters.selectors";
import { selectLanguages } from "../../features/languages/languages.selectors";
import { languageSelected } from "../../features/languages/languages.reducer";
import { bookingCenterSelected } from "../../features/bookingCenters/bookingCenters.reducer";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { SingleSimulationFormInputs } from "../SingleSimulation/components/SingleSimulationPanel/SingleSimulationForm/types/SingleSimulationFormInputs";
import { defaultValues } from "../SingleSimulation/components/SingleSimulationPanel/SingleSimulationForm/defaultValues";
import { useSearchParams } from "react-router-dom";
import { selectEtdCategories } from "../../features/etdCategories/etdCategories.selectors";
import { etdCategorySelected } from "../../features/etdCategories/etdCategories.reducer";
import { selectInstrumentTypes } from "../../features/instrumentTypes/instrumentTypes.selectors";
import { instrumentTypeSelected } from "../../features/instrumentTypes/instrumentTypes.reducer";
import { selectTransactions } from "../../features/transactions/transactions.selectors";
import { selectDirections } from "../../features/directions/directions.selectors";
import { transactionSelected } from "../../features/transactions/transactions.reducer";
import { directionSelected } from "../../features/directions/directions.reducer";
import {
  selectCurrencies,
  selectSelectedCurrency,
} from "../../features/currencies/currencies.selectors";
import { currencySelected } from "../../features/currencies/currencies.reducer";
import { selectPricingModels } from "../../features/pricingModels/pricingModels.selectors";
import { pricingModelSelected } from "../../features/pricingModels/pricingModels.reducer";
import { selectChargeModels } from "../../features/chargeModels/chargeModels.selectors";
import { chargeModelSelected } from "../../features/chargeModels/chargeModels.reducer";
import {
  selectMarketPlaces,
  selectSelectedMarketPlace,
} from "../../features/marketPlaces/marketPlaces.selectors";
import { marketPlaceSelected } from "../../features/marketPlaces/marketPlaces.reducer";
import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import { AuthenticatedLayout } from "../../components/layouts/AuthenticatedLayout";
import { useQueryStringForClone } from "../../hooks/useQueryStringForClone";
import { selectSpecialConditionTypes } from "../../features/specialConditionTypes/specialConditionTypes.selectors";
import { specialConditionTypeSelected } from "../../features/specialConditionTypes/specialConditionTypes.reducer";
import { useAppSelector } from "../../hooks/useAppSelector";

// http://localhost:3000/cloned-single-simulation?pNo=10&lCode=EN&bcCode=CH/GUE&ecCode=F&itCode=F&tText=buy&dText=to%20open&iSN=prova&iscCode=CHF&q=50&exp=10/20/2023&ufn=underlying&pmText=Eurex&tpcmText=Eurex-Fee&mpText=EUREX%20(Switzerland%20-%20Equity%20Options)&sctText=Discount%20in%20%&sca=10&nsmf=1000&stpc=yes&cls=10&pcl=100&exr=1.1

export const ClonedSingleSimulation = (): React.ReactElement => {
  const form = useForm<SingleSimulationFormInputs>({
    defaultValues,
  });

  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <FormProvider {...form}>
        <ClonedSingleSimulationInternal />
      </FormProvider>
    </AuthenticatedLayout>
  );
};

const ClonedSingleSimulationInternal = () => {
  const [hasMarketPlaceBeingSelected, setHasMarketPlaceBeingSelected] =
    React.useState(false);
  const [hasCurrencyBeingSelected, setHasCurrencyBeingSelected] =
    React.useState(false);
  const [searchParams] = useSearchParams();
  const { setValue } = useFormContext<SingleSimulationFormInputs>();

  const selectedMarketPlace = useAppSelector(selectSelectedMarketPlace);
  const selectedCurrency = useAppSelector(selectSelectedCurrency);

  React.useEffect(() => {
    setValue("portfolioNo", searchParams.get("pNo") ?? "");
    setValue("instrumentShortName", searchParams.get("iSN") ?? "");
    setValue("instrumentSecurityNumber", searchParams.get("iSNo") ?? "n.a");
    setValue("tickerIsinOfUnderlying", searchParams.get("tISIN") ?? "n.a");
    setValue("strike", searchParams.get("strike") ?? "n.a");
    setValue("quantity", parseInt(searchParams.get("q") ?? "0"));
    setValue("expiry", searchParams.get("exp") ?? "n.a");
    setValue("underlyingFullName", searchParams.get("ufn") ?? "n.a");
    setValue(
      "specialConditionsAmount",
      parseFloat(searchParams.get("sca") ?? "0")
    );
    setValue("noStandardMinimumFee", parseInt(searchParams.get("nsmf") ?? "0"));
    setValue("contractLotSize", parseInt(searchParams.get("cls") ?? "0"));
    setValue("premiumPerContractLot", parseInt(searchParams.get("pcl") ?? "0"));
  }, [searchParams, setValue]);

  React.useEffect(() => {
    if (!selectedMarketPlace || hasMarketPlaceBeingSelected) {
      return;
    }

    setValue("simulateThirdPartyCharges", searchParams.get("stpc") ?? "no");
    setHasMarketPlaceBeingSelected(true);
  }, [
    hasMarketPlaceBeingSelected,
    setHasMarketPlaceBeingSelected,
    selectedMarketPlace,
    searchParams,
    setValue,
  ]);

  React.useEffect(() => {
    if (!selectedCurrency || hasCurrencyBeingSelected) {
      return;
    }

    setValue("exchangeRate", searchParams.get("exr") ?? "");
    setHasCurrencyBeingSelected(true);
  }, [
    hasCurrencyBeingSelected,
    setHasCurrencyBeingSelected,
    selectedCurrency,
    searchParams,
    setValue,
  ]);

  useQueryStringForClone({
    selector: selectLanguages,
    dispatcher: languageSelected,
    fieldName: "outputLanguage",
    search: "lCode",
  });

  useQueryStringForClone({
    selector: selectBookingCenters,
    dispatcher: bookingCenterSelected,
    fieldName: "bookingCenter",
    search: "bcCode",
  });

  useQueryStringForClone({
    selector: selectEtdCategories,
    dispatcher: etdCategorySelected,
    fieldName: "etdCategory",
    search: "ecText",
  });

  useQueryStringForClone({
    selector: selectInstrumentTypes,
    dispatcher: instrumentTypeSelected,
    fieldName: "instrumentType",
    search: "itText",
  });

  useQueryStringForClone({
    selector: selectTransactions,
    dispatcher: transactionSelected,
    fieldName: "transaction",
    search: "tText",
  });

  useQueryStringForClone({
    selector: selectDirections,
    dispatcher: directionSelected,
    fieldName: "direction",
    search: "dText",
  });

  useQueryStringForClone({
    selector: selectCurrencies,
    dispatcher: currencySelected,
    fieldName: "instrumentSecurityCurrency",
    search: "iscCode",
  });

  useQueryStringForClone({
    selector: selectPricingModels,
    dispatcher: pricingModelSelected,
    fieldName: "pricingModel",
    search: "pmText",
  });

  useQueryStringForClone({
    selector: selectChargeModels,
    dispatcher: chargeModelSelected,
    fieldName: "thirdPartyChargesModel",
    search: "tpcmText",
  });

  useQueryStringForClone({
    selector: selectMarketPlaces,
    dispatcher: marketPlaceSelected,
    fieldName: "stockExchangeIndicative",
    search: "mpText",
  });

  useQueryStringForClone({
    selector: selectSpecialConditionTypes,
    dispatcher: specialConditionTypeSelected,
    fieldName: "specialConditionsType",
    search: "sctText",
  });

  return <SingleSimulationInternal />;
};
