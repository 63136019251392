import { Grid, Box } from "@mui/material";
import { ButtonWrapper, StyledButton, StyledSection } from "../../index.style";
import { Card } from "../../../../components/core/Card";
import { SingleSimulationForm } from "./SingleSimulationForm";
import { OutPreview } from "./OutputPreview";
//import { SingleSimulationFormInputs } from "./SingleSimulationForm/types/SingleSimulationFormInputs";
import { useCallback } from "react";
import { useUser } from "hooks/useUser";
//import { useFormContext } from "react-hook-form";

type SingleSimulationPanelProps = {
  hide: boolean;
  onCalculate: () => void;
};

export const SingleSimulationPanel = (props: SingleSimulationPanelProps) => {
  const { hide, onCalculate } = props;
  const { isAdmin } = useUser();

  //const { reset } = useFormContext<SingleSimulationFormInputs>();
  /** Should reset all reducers state ? */
  // const handleReset = useCallback(() => reset(), [reset]);
  /**
   * Because of some difficult management between react-hook-form and redux
   * we decided to go for a page reload like PCS
   */
  const handleReset = useCallback(() => window.location.reload(), []);

  return (
    <Card
      title={<Box sx={{ textAlign: "center" }}>ECS Simulation</Box>}
      Component={StyledSection}
      style={{ display: hide ? "none" : "block" }}
      actions={
        <ButtonWrapper>
          <StyledButton
            variant="outlined"
            color="secondary"
            onClick={handleReset}
          >
            Reset
          </StyledButton>
          <StyledButton
            form="single-simulation-form"
            variant="contained"
            color="primary"
            type="submit"
          >
            Calculate ECS
          </StyledButton>
        </ButtonWrapper>
      }
    >
      <Grid item xs={isAdmin ? 8 : 12} sx={{ ml: "-20px" }}>
        <SingleSimulationForm onValidSubmit={onCalculate} />
      </Grid>

      <Grid item xs={4} sx={{ display: isAdmin ? "block" : "none" }}>
        <OutPreview />
      </Grid>
    </Card>
  );
};
