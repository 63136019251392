import React from "react";
import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";
import { useSearchParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { RootState } from "../app/store";

export type UseQueryStringForCloneProps = {
  selector: (state: RootState) => any;
  dispatcher: (item: any) => any;
  fieldName: string;
  search: string;
};

export const useQueryStringForClone = (props: UseQueryStringForCloneProps) => {
  const { selector, dispatcher, fieldName, search } = props;
  const { setValue } = useFormContext();

  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const items = useAppSelector(selector);

  React.useEffect(() => {
    const value = searchParams.get(search) ?? "";

    if (!items || items.length === 0 || value === "") {
      return;
    }

    let item;
    const isText = search.toLowerCase().includes("text");

    if (isText) {
      item = items.find(
        (i: { text: string }) =>
          i.text.trim().toLowerCase() === value.trim().toLowerCase()
      );
    } else {
      item = items.find(
        (i: { code: string }) =>
          i.code.trim().toLowerCase() === value.trim().toLowerCase()
      );
    }

    dispatch(dispatcher(item));
    setValue(fieldName, item?.code ?? item?.text ?? ("" as string));
  }, [items, search, fieldName, searchParams, dispatch, dispatcher, setValue]);
};
