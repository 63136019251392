import React from "react";
import { useLocation } from "react-router-dom";
import { Breadcrumbs as BreadcrumbsBase, Typography } from "@mui/material";
import { StyledLink } from "./index.style";
import env from "../../../config/env";

const breadsCrumbsList: { [key: string]: { [key: string]: string } } = {
  "/": { "": "Action Panel" },
  "/single-simulation": { "": "Single Simulation" },
  "/cloned-single-simulation": { "": "Single Simulation" },
  "/multiple-simulation": { "": "Multiple Simulation" },
  "/output": { "/simulation": "PCS Simulation", "": "Output" },

  "/table/fee-matrix-3p": {
    "/": "Action Panel",
    "": "Fee Matrix 3p",
  },

  "/table/fee-matrix-3p/edit/:id": {
    "/": "Action Panel",
    "/table/fee-matrix-3p": "Fee Matrix 3p",
    "": "Edit",
  },

  "/table/value-bands": {
    "/": "Action Panel",
    "": "Value Bands",
  },

  "/table/value-bands/edit/:id": {
    "/": "Action Panel",
    "/table/value-bands": "Value Bands",
    "": "Edit",
  },

  "/table/fee-matrix-bjb": {
    "/": "Action Panel",
    "": "Fee Matrix BJB",
  },

  "/table/fee-matrix-bjb/edit/:id": {
    "/": "Action Panel",
    "/table/fee-matrix-bjb": "Fee Matrix BJB",
    "": "Edit",
  },

  "/table/fee-table": {
    "/": "Action Panel",
    "": "Fee Table",
  },

  "/table/fee-table/edit/:id": {
    "/": "Action Panel",
    "/table/fee-table": "Fee Table",
    "": "Edit",
  },

  "/table/instruments": {
    "/": "Action Panel",
    "": "Instrument",
  },
  "/table/instruments/edit/:id": {
    "/": "Action Panel",
    "/table/instruments": "Instruments",
    "": "Edit",
  },
};

export const Breadcrumbs = (): React.ReactElement => {
  const [breadcrumbs, setBreadcrumbs] = React.useState<{
    [key: string]: string;
  }>({});

  const { REACT_APP_ENV, REACT_APP_PDF_VERSION } = env;

  const location = useLocation();

  React.useEffect(() => {
    let path = location.pathname;

    if (!breadsCrumbsList[path]) {
      let pieces = path.split("/");

      if (parseInt(pieces[pieces.length - 1]) >= 0) {
        pieces[pieces.length - 1] = ":id";
        path = pieces.join("/");
      }
    }

    if (!breadsCrumbsList[path]) {
      return;
    }

    setBreadcrumbs(breadsCrumbsList[path]);
  }, [location]);

  const renderBreadcrumbs = () => {
    let result = [];

    for (let key of Object.keys(breadcrumbs)) {
      let breadcrumb = breadcrumbs[key];

      if (key === "") {
        result.push(
          <Typography color="text.primary" key={"breadcrumb-#" + key}>
            {breadcrumb}
          </Typography>
        );
        continue;
      }

      result.push(
        <StyledLink color="primary" key={"breadcrumb-#" + key} to={key}>
          {breadcrumb}
        </StyledLink>
      );
    }

    return result;
  };

  if (breadcrumbs === undefined || breadcrumbs === null) {
    return <></>;
  }

  return (
    <React.Fragment>
      <BreadcrumbsBase
        aria-label="breadcrumb"
        separator={">"}
        style={{ marginBottom: "10px" }}
      >
        {renderBreadcrumbs()}
      </BreadcrumbsBase>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginLeft: "auto",
        }}
      >
        {REACT_APP_ENV && (
          <>
            <b>Env:</b> {REACT_APP_ENV} &nbsp;
          </>
        )}
        <b> Vers:</b> {REACT_APP_PDF_VERSION}
      </div>
    </React.Fragment>
  );
};
