import { createSlice } from "@reduxjs/toolkit";
import { MarketPlaceDto } from "../../openapi";

export type MarketPlacesState = {
  marketPlaces: MarketPlaceDto[];
  selected?: MarketPlaceDto;
  isFetching: boolean;
};

export const INITIAL_STATE: MarketPlacesState = {
  marketPlaces: [],
  selected: undefined,
  isFetching: false,
};

export type FetchMarketPlacesAction = {
  type: string;
  payload: {
    bookingCenterId: number,
    pricingModelId: number,
    categoryId: number,
    chargeModelId: number,
    currencyId: number,
  };
};

const marketPlacesSlice = createSlice({
  name: "marketPlaces",
  initialState: INITIAL_STATE,
  reducers: {
    fetchMarketPlaces: (
      state: MarketPlacesState,
      _action: FetchMarketPlacesAction
    ) => {
      state.isFetching = true;
    },
    fetchMarketPlacesSuccess: (state: MarketPlacesState, action) => {
      state.marketPlaces = action.payload.marketPlaces;
      state.isFetching = false;
    },
    marketPlaceSelected: (state: MarketPlacesState, action) => {
      state.selected = action.payload;
    },
    resetMarketPlaces: (state: MarketPlacesState) => {
      state.marketPlaces = [];
      state.isFetching = false;
      state.selected = undefined;
    },
  },
});

export const {
  fetchMarketPlaces,
  fetchMarketPlacesSuccess,
  marketPlaceSelected,
  resetMarketPlaces,
} = marketPlacesSlice.actions;

export default marketPlacesSlice.reducer;
