import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import { EntityTable } from "../../components/core/EntityTable";
import { AuthenticatedLayout } from "../../components/layouts/AuthenticatedLayout";
import { InstrumentControllerService } from "../../openapi";

export const Instrument = () => {
  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <EntityTable
        title="Instrument"
        service={InstrumentControllerService.getInstruments}
        endpoint="instruments"
        columns={[
          {
            label: "Booking Center Currency",
            id: "bookingCenterCurrency.text",
            numeric: false,
          },
          {
            label: "Contr Lot Size",
            id: "contrLotSize",
            numeric: true,
          },
          {
            label: "Direction",
            id: "direction.text",
            numeric: false,
          },
          {
            label: "Exchange Rate",
            id: "exchangeRate",
            numeric: true,
          },
          {
            label: "Full Name",
            id: "fullName",
            numeric: true,
          },
          {
            label: "Instr Security Currency",
            id: "instrSecurityCurrency.text",
            numeric: false,
          },
          {
            label: "Instr Security Number",
            id: "instrSecurityNumber.text",
            numeric: false,
          },
          {
            label: "Instrument Type",
            id: "instrumentType.text",
            numeric: false,
          },
          {
            label: "Premium Per Contr Lot",
            id: "premiumPerContrLot",
            numeric: true,
          },
          {
            label: "Quantity",
            id: "quantity",
            numeric: true,
          },
          {
            label: "Sid",
            id: "sid",
            numeric: true,
          },
          {
            label: "Simulation Number",
            id: "simulationNumber",
            numeric: true,
          },
          {
            label: "Stock Exchange",
            id: "stockExchange",
            numeric: true,
          },
          {
            label: "Text",
            id: "text",
            numeric: false,
          },
          {
            label: "Transaction",
            id: "transaction.text",
            numeric: false,
          },
          {
            label: "Etd Category",
            id: "etdCategory.text",
            numeric: false,
          },
          {
            label: "Pricing Model",
            id: "pricingModel.text",
            numeric: false,
          },
          {
            label: "BS Remark",
            id: "bsRemark",
            numeric: false,
          },
          {
            label: "IT Remark",
            id: "itRemark",
            numeric: false,
          },
        ]}
      />
    </AuthenticatedLayout>
  );
};
