import { takeLatest, put } from "redux-saga/effects";
import { fetchLanguages, fetchLanguagesSuccess } from "./languages.reducer";
import { LanguageControllerService, EntityModelLanguage } from "../../openapi";
import authorizedCall from "../../utils/authorizedCall";

export function* fetchLanguagesSaga() {
  const response: EntityModelLanguage[] = yield (
    authorizedCall(LanguageControllerService.getLanguages, {
      size: 10,
      page: 0,
    })
  );

  yield put(fetchLanguagesSuccess({ languages: response }));
}

export default function* rootLanguagesSaga() {
  yield takeLatest(fetchLanguages.type, fetchLanguagesSaga);
}
