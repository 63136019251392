import React from "react";
import { AuthenticatedLayout } from "../../components/layouts/AuthenticatedLayout";
import { MultipleSimulationPanel } from "./components/MultipleSimulationPanel";
import { MultipleOutputPanel } from "./components/MultipleOutputPanel";
import { FormProvider, useForm } from "react-hook-form";
import { MultipleSimulationFormInputs } from "./components/MultipleSimulationPanel/MultipleSimulationForm/types/MultipleSimulationFormInputs";
import { defaultValues } from "./components/MultipleSimulationPanel/MultipleSimulationForm/defaultValues";
import { Breadcrumbs } from "../../components/core/Breadcrumbs";

export const MultipleSimulation = (): React.ReactElement => {
  const [showMultipleSimulation, setShowMultipleSimulation] =
    React.useState(true);

  const form = useForm<MultipleSimulationFormInputs>({
    defaultValues,
  });

  const switchPanels = () => {
    setShowMultipleSimulation((state) => !state);
  };

  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <FormProvider {...form}>
        <MultipleSimulationPanel
          hide={!showMultipleSimulation}
          onCalculate={switchPanels}
        />
        <MultipleOutputPanel
          hide={showMultipleSimulation}
          handleBack={switchPanels}
        />
      </FormProvider>
    </AuthenticatedLayout>
  );
};
