import { takeLatest, put } from "redux-saga/effects";
import {
  fetchInstrumentTypes,
  fetchInstrumentTypesSuccess,
  FetchInstrumentTypesAction,
} from "./instrumentTypes.reducer";
import { EtdCategoryControllerService, InstrumentTypeDto } from "../../openapi";
import authorizedCall from "../../utils/authorizedCall";

export function* fetchInstrumentTypesSaga(action: FetchInstrumentTypesAction) {
  const response: InstrumentTypeDto[] = yield (
    authorizedCall(EtdCategoryControllerService.getCategoryInstrumentTypes, {
      etdCategoryId: action.payload.etdCategoryId,
      size: 10,
      page: 0,
    })
  );
  yield put(fetchInstrumentTypesSuccess({ instrumentTypes: response }));
}

export default function* rootInstrumentTypesSaga() {
  yield takeLatest(fetchInstrumentTypes.type, fetchInstrumentTypesSaga);
}
