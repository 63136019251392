import {
  chargesModelName,
  contractLotSizeName,
  currenciesName,
  directionName,
  etdCategoryName,
  exchangeRate4SimName,
  exchangeRateName,
  fullNameName,
  instrumentSecurityCurrencyName,
  instrumentSecurityNumberName,
  instrumentTypeName,
  minimumFeeName,
  overrideExchangeRateName,
  overridePremiumPerContractLotName,
  overrideQuantityName,
  premiumPerContractLot4SimName,
  premiumPerContractLotName,
  pricingModelName,
  quantity4SimName,
  quantityName,
  segmentName,
  simulationPossibleName,
  specialConditionsAmountName,
  specialConditionsName,
  specialConditionsUnitName,
  stockExchangeName,
  transactionName,
} from "./types";

export const getEtdCategoryFieldName = (simNo: number): etdCategoryName => {
  return ("etdCategory" + simNo) as etdCategoryName;
};

export const getPricingModelFieldName = (simNo: number): pricingModelName => {
  return ("pricingModel" + simNo) as pricingModelName;
};

export const getSimulationPossibleFieldName = (
  simNo: number
): simulationPossibleName => {
  return ("simulationPossible" + simNo) as simulationPossibleName;
};

export const getSegmentFieldName = (simNo: number): segmentName => {
  return ("segment" + simNo) as segmentName;
};

export const getSpecialConditionsFieldName = (
  simNo: number
): specialConditionsName => {
  return ("specialConditions" + simNo) as specialConditionsName;
};

export const getSpecialConditionsUnitFieldName = (
  simNo: number
): specialConditionsUnitName => {
  return ("specialConditionsUnit" + simNo) as specialConditionsUnitName;
};

export const getSpecialConditionsAmountFieldName = (
  simNo: number
): specialConditionsAmountName => {
  return ("specialConditionsAmount" + simNo) as specialConditionsAmountName;
};

export const getMinimumFeeFieldName = (simNo: number): minimumFeeName => {
  return ("minimumFee" + simNo) as minimumFeeName;
};

export const getChargesModelFieldName = (simNo: number): chargesModelName => {
  return ("chargesModelSim" + simNo) as chargesModelName;
};

export const getInstrumentSecurityCurrencyFieldName = (
  simNo: number
): instrumentSecurityCurrencyName => {
  return ("instrumentSecurityCurrency" +
    simNo) as instrumentSecurityCurrencyName;
};

export const getTransactionFieldName = (simNo: number): transactionName => {
  return ("transaction" + simNo) as transactionName;
};

export const getInstrumentTypeFieldName = (simNo: number): instrumentTypeName => {
  return ("instrumentType" + simNo) as instrumentTypeName;
};

export const getDirectionFieldName = (simNo: number): directionName => {
  return ("direction" + simNo) as directionName;
};

export const getFullNameFieldName = (simNo: number): fullNameName => {
  return ("fullName" + simNo) as fullNameName;
};

export const getQuantityFieldName = (simNo: number): quantityName => {
  return ("quantity" + simNo) as quantityName;
};

export const getOverrideQuantityFieldName = (
  simNo: number
): overrideQuantityName => {
  return ("overrideQuantity" + simNo) as overrideQuantityName;
};

export const getQuantity4SimFieldName = (simNo: number): quantity4SimName => {
  return ("quantity4Sim" + simNo) as quantity4SimName;
};

export const getContractLotSizeFieldName = (simNo: number): contractLotSizeName => {
  return ("contractLotSize" + simNo) as contractLotSizeName;
};

export const getPremiumPerContractLotFieldName = (
  simNo: number
): premiumPerContractLotName => {
  return ("premiumPerContractLot" + simNo) as premiumPerContractLotName;
};

export const getOverridePremiumPerContractLotFieldName = (
  simNo: number
): overridePremiumPerContractLotName => {
  return ("overridePremiumPerContractLot" +
    simNo) as overridePremiumPerContractLotName;
};

export const getPremiumPerContractLot4SimFieldName = (
  simNo: number
): premiumPerContractLot4SimName => {
  return ("premiumPerContractLot4Sim" + simNo) as premiumPerContractLot4SimName;
};

export const getCurrenciesFieldName = (simNo: number): currenciesName => {
  return ("currencies" + simNo) as currenciesName;
};

export const getExchangeRateFieldName = (simNo: number): exchangeRateName => {
  return ("exchangeRate" + simNo) as exchangeRateName;
};

export const getOverrideExchangeRateFieldName = (
  simNo: number
): overrideExchangeRateName => {
  return ("overrideExchangeRate" + simNo) as overrideExchangeRateName;
};

export const getExchangeRate4SimFieldName = (
  simNo: number
): exchangeRate4SimName => {
  return ("exchangeRate4Sim" + simNo) as exchangeRate4SimName;
};

export const getStockExchangeFieldName = (simNo: number): stockExchangeName => {
  return ("stockExchange" + simNo) as stockExchangeName;
};

export const getInstrumentSecurityNumberFieldName = (
  simNo: number
): instrumentSecurityNumberName => {
  return ("instrumentSecurityNumber" + simNo) as instrumentSecurityNumberName;
};
