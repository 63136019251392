import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Grid, SelectChangeEvent } from "@mui/material";
import { Content } from "../../../../../../components/core/Content";
import { Header } from "../../../../../../components/core/Header";
import { FormFieldLabel } from "../../../../../../components/hook-form/FormFieldLabel";
import { FormSelect } from "../../../../../../components/hook-form/FormSelect";
import { fetchDirections } from "../../../../../../features/directions/directions.reducer";
import { selectDirections } from "../../../../../../features/directions/directions.selectors";
import {
  etdCategorySelected,
  fetchEtdCategories,
} from "../../../../../../features/etdCategories/etdCategories.reducer";
import {
  selectEtdCategories,
  selectSelectedEtdCategory,
} from "../../../../../../features/etdCategories/etdCategories.selectors";
import { fetchInstrumentTypes } from "../../../../../../features/instrumentTypes/instrumentTypes.reducer";
import { selectInstrumentTypes } from "../../../../../../features/instrumentTypes/instrumentTypes.selectors";
import { fetchTransactions } from "../../../../../../features/transactions/transactions.reducer";
import { selectTransactions } from "../../../../../../features/transactions/transactions.selectors";
import { useAppDispatch } from "../../../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../../../hooks/useAppSelector";
import { mapEntitiesToFormSelectOptions } from "../../../../../../utils/formUtils";
import { SingleSimulationFormInputs } from "../types/SingleSimulationFormInputs";

export const InvestmentFields = () => {
  const { control, setValue } = useFormContext<SingleSimulationFormInputs>();
  const dispatch = useAppDispatch();

  const etdCategories = useAppSelector(selectEtdCategories);
  const transactions = useAppSelector(selectTransactions);
  const instrumentTypes = useAppSelector(selectInstrumentTypes);
  const directions = useAppSelector(selectDirections);

  const selectedEtdCategory = useAppSelector(selectSelectedEtdCategory);

  useEffect(() => {
    dispatch(fetchEtdCategories());
    dispatch(fetchTransactions());
    dispatch(fetchDirections());
  }, [dispatch]);

  useEffect(() => {
    if (selectedEtdCategory) {
      setValue("instrumentType", "");
      dispatch(fetchInstrumentTypes({ etdCategoryId: selectedEtdCategory.id }));
    }
  }, [dispatch, selectedEtdCategory, setValue]);

  const onEdtCategorySelected = (evt: SelectChangeEvent<string>) => {
    const ecCode = evt.target.value;
    const selected = etdCategories.find((ec) => ec.code === ecCode);
    dispatch(etdCategorySelected(selected));
  };

  return (
    <>
      <Content>
        <Header title="Investment Fields" />
      </Content>
      <Grid item xs={6}>
        <FormSelect
          name="etdCategory"
          label={<FormFieldLabel isRequired>ETD Category</FormFieldLabel>}
          control={control}
          fullWidth
          autoComplete="off"
          handleChange={onEdtCategorySelected}
          options={mapEntitiesToFormSelectOptions(etdCategories)}
          rules={{ required: "ETD Category is Required." }}
        />
      </Grid>
      <Grid item xs={6}>
        <FormSelect
          name="instrumentType"
          label={
            <FormFieldLabel isRequired={instrumentTypes.length > 0}>
              Instrument Type
            </FormFieldLabel>
          }
          control={control}
          fullWidth
          autoComplete="off"
          options={mapEntitiesToFormSelectOptions(instrumentTypes)}
          rules={
            instrumentTypes.length > 0
              ? { required: "Instrument Type is Required." }
              : { required: false }
          }
        />
      </Grid>

      <Grid item xs={6}>
        <FormSelect
          name="transaction"
          label={<FormFieldLabel isRequired>Transaction</FormFieldLabel>}
          control={control}
          fullWidth
          autoComplete="off"
          options={mapEntitiesToFormSelectOptions(transactions)}
          rules={{
            required: "Transaction is Required.",
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <FormSelect
          name="direction"
          label={<FormFieldLabel isRequired>Direction</FormFieldLabel>}
          control={control}
          fullWidth
          autoComplete="off"
          options={mapEntitiesToFormSelectOptions(directions)}
          rules={{ required: "Direction is Required." }}
        />
      </Grid>
    </>
  );
};
