import { Grid, Box } from "@mui/material";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { StyledSection } from "./index.style";
import { Header } from "../../../../../components/core/Header";
import { StyledDiv } from "../../../index.style";
import { BasicFields } from "./BasicFields";
import { InvestmentFields } from "./InvestmentFields";
import { InstrumentFields } from "./InstrumentFields";
import { SegmentFields } from "./SegmentFields";
import { SpecialConditionsFields } from "./SpecialConditionsFields";
import { SingleSimulationFormInputs } from "./types/SingleSimulationFormInputs";
import { Remarks } from "./Remarks";
import { useAppDispatch } from "../../../../../hooks/useAppDispatch";
import { fetchSingleOutput } from "../../../../../features/singleOutput/singleOutput.reducer";
import { prepareDataForSingleOutput } from "../../../../../utils/formUtils";
import { useSelector } from "react-redux";
import { selectLanguages } from "../../../../../features/languages/languages.selectors";
import { selectCurrencies } from "../../../../../features/currencies/currencies.selectors";
import { selectBookingCenters } from "../../../../../features/bookingCenters/bookingCenters.selectors";
import { selectEtdCategories } from "../../../../../features/etdCategories/etdCategories.selectors";
import { UserControllerService } from "../../../../../openapi";
import { useUser } from "../../../../../hooks/useUser";
import { selectInstrumentTypes } from "../../../../../features/instrumentTypes/instrumentTypes.selectors";
import { selectTransactions } from "../../../../../features/transactions/transactions.selectors";
import { selectDirections } from "../../../../../features/directions/directions.selectors";
import { parseJwt } from "../../../../../utils/jwtUtils";

type SingleSimulationFormProps = {
  onValidSubmit: () => void;
};

export const SingleSimulationForm = (props: SingleSimulationFormProps) => {
  const form = useFormContext<SingleSimulationFormInputs>();
  const { onValidSubmit } = props;
  const { handleSubmit } = form;

  const { token } = useUser();

  const dispatch = useAppDispatch();
  const languages = useSelector(selectLanguages);
  const categories = useSelector(selectEtdCategories);
  const currencies = useSelector(selectCurrencies);
  const instrumentTypes = useSelector(selectInstrumentTypes);
  const bookingCenters = useSelector(selectBookingCenters);
  const transactions = useSelector(selectTransactions);
  const directions = useSelector(selectDirections);

  const onSubmit: SubmitHandler<SingleSimulationFormInputs> = (data) => {
    if (token) {
      const input = prepareDataForSingleOutput(
        data,
        languages,
        categories,
        bookingCenters,
        currencies,
        instrumentTypes,
        transactions,
        directions
      );

      dispatch(fetchSingleOutput(input));
      UserControllerService.addUserAction(
        {
          user: parseJwt(token ?? "")?.upn,
          cdOperation: "single_output_calculation",
          operation: "ecs_oc",
          description: "The user performed a calculation",
        },
        `Bearer ${token}`
      );
      onValidSubmit();
    }
  };

  return (
    <StyledDiv>
      <Header
        title={<Box sx={{ textAlign: "center" }}>Input-parameters</Box>}
      />
      <StyledSection>
        <form
          style={{ marginTop: "10px" }}
          onSubmit={handleSubmit(onSubmit)}
          id="single-simulation-form"
        >
          <Grid
            container
            columnSpacing={2}
            rowSpacing={5}
            sx={{ width: "100%" }}
          >
            <BasicFields />
            <InvestmentFields />
            <InstrumentFields />
            <SegmentFields />
            <SpecialConditionsFields />

            <Remarks />
          </Grid>
        </form>
      </StyledSection>
    </StyledDiv>
  );
};
