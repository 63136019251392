/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EntityModelOutputDto } from "../models/EntityModelOutputDto";
import type { InputSingleDto } from "../models/InputSingleDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class OutputControllerService {
  /**
   * It will return the output labels and calculated fields derived by to the Single Simulation Input instance
   * @param input
   * @returns EntityModelOutputDto Output labels and fields
   * @throws ApiError
   */
  public static output(input: {
    requestBody: InputSingleDto;
    authorization?: string;
  }): CancelablePromise<EntityModelOutputDto> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/output",
      headers: {
        Authorization: input.authorization,
      },
      body: input.requestBody,
      mediaType: "application/json",
      errors: {
        403: `Your role must have read grants Execute to SINGLE_SIMULATION function`,
      },
    });
  }
}
