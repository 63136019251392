import { UserControllerService } from "../openapi";
import { useEffect, useState } from "react";
import { useUser } from "./useUser";
import { mapGrantsFuncToUserPermissions } from "../utils/userPermissionUtils";
import { UserPermissions } from "../types/UserPermission";

export const useUserPermissions = () => {
  const [userPermissions, setUserPermissions] =
    useState<UserPermissions>(undefined);
  const [userPermissionsIsFetching, setUserPermissionsIsFetching] =
    useState(false);
  const [userPermissionsError, setUserPermissionsError] = useState("");

  const { token } = useUser();

  useEffect(() => {
    if (token) {
      setUserPermissionsIsFetching(true);
      UserControllerService.getGrantsAndFunctions({
        authorization: `Bearer ${token}`,
        page: 0,
        size: 10,
      })
        .then((data: any) => {
          const userActions = mapGrantsFuncToUserPermissions(
            data._embedded.entities
          );

          setUserPermissionsIsFetching(false);
          setUserPermissions(userActions);
        })
        .catch((error) => {
          setUserPermissionsIsFetching(false);
          setUserPermissionsError(error.message);
        });
    }
  }, [token]);

  return {
    userPermissions,
    userPermissionsIsFetching,
    userPermissionsError,
  };
};

export default useUserPermissions;
