import { createSlice } from "@reduxjs/toolkit";
import { InstrumentTypeDto } from "../../openapi";

export type InstrumentTypesState = {
  instrumentTypes: InstrumentTypeDto[];
  selected?: InstrumentTypeDto;
  isFetching: boolean;
};

export const INITIAL_STATE: InstrumentTypesState = {
  instrumentTypes: [],
  selected: undefined,
  isFetching: false,
};

export type FetchInstrumentTypesAction = {
  type: string;
  payload: {
    etdCategoryId: number;
  };
};

const instrumentTypesSlice = createSlice({
  name: "instrumentTypes",
  initialState: INITIAL_STATE,
  reducers: {
    fetchInstrumentTypes: (
      state: InstrumentTypesState,
      _action?
    ) => {
      state.isFetching = true;
    },
    fetchInstrumentTypesSuccess: (state: InstrumentTypesState, action) => {
      state.instrumentTypes = action.payload.instrumentTypes;
      state.isFetching = false;
    },
    instrumentTypeSelected: (state: InstrumentTypesState, action) => {
      state.selected = action.payload;
    },
    resetInstrumentTypes: (state: InstrumentTypesState) => {
      state.instrumentTypes = [];
    },
  },
});

export const {
  fetchInstrumentTypes,
  fetchInstrumentTypesSuccess,
  instrumentTypeSelected,
  resetInstrumentTypes,
} = instrumentTypesSlice.actions;

export default instrumentTypesSlice.reducer;
