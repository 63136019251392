import { NumericFormat, NumericFormatProps } from "react-number-format";
import { TextField, TextFieldProps } from "@mui/material";

export type CurrencyInputProps = NumericFormatProps<
  Omit<TextFieldProps, "error"> & {
    error?: any;
  }
>;

export const CurrencyInput = (props: CurrencyInputProps) => {
  const { error, name, ...restProps } = props;

  return (
    <NumericFormat
      data-testid={name}
      allowNegative={false}
      customInput={TextField}
      thousandSeparator={"'"}
      error={!!error}
      helperText={error?.message ?? null}
      {...restProps}
    />
  );
};
