import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { Content } from "../../../../../../components/core/Content";
import { Header } from "../../../../../../components/core/Header";
import { FormFieldLabel } from "../../../../../../components/hook-form/FormFieldLabel";
import { FormInput } from "../../../../../../components/hook-form/FormInput";
import { SingleSimulationFormInputs } from "../types/SingleSimulationFormInputs";

export const Remarks = () => {
  const { control } = useFormContext<SingleSimulationFormInputs>();

  return (
    <>
      <Content>
        <Header title="Remarks" />
      </Content>
      <Grid item xs={12}>
        <FormInput
          regEx="[]"
          name="remarks"
          label={<FormFieldLabel>Remarks</FormFieldLabel>}
          control={control}
          autoComplete="off"
          fullWidth
        />
      </Grid>
    </>
  );
};
