import { styled } from "@mui/material/styles";
import { Section } from "../../../../components/core/Section";

export const StyledSection = styled(Section)`
  overflow: hidden;
`;

export const StyledDiv = styled("div")`
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 48px;
`;
