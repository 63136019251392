import { RootState } from "../../app/store";
import { EtdCategoryDto } from "../../openapi";

export let selectIsFetchingEtdCategories = (state: RootState): boolean =>
  state.ecReducer.isFetching;
export let selectEtdCategories = (state: RootState): EtdCategoryDto[] =>
  state.ecReducer.etdCategories;
export let selectSelectedEtdCategory = (
  state: RootState
): EtdCategoryDto | undefined => state.ecReducer.selected;
