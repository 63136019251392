import { CancelablePromise } from "../openapi";
import { getAccessToken, parseJwt } from "./jwtUtils";

type inputParams<K> = K;

type Callback<T, K> = (input: inputParams<K>) => CancelablePromise<T>;

export default async function authorizedCall<T, K>(
  callback: Callback<T, K>,
  input: inputParams<
    K & {
      authorization?: string;
      page?: number;
      size?: number;
    }
  >,
  returnFullData: boolean = false
) {
  const jwt = getAccessToken();
  input.authorization = "Bearer " + jwt;

  input = JSON.parse(
    JSON.stringify(input).replace(
      /{useremail}/g,
      parseJwt(jwt ?? "").given_name
    )
  );

  return callback(input).then(async (data: any) => {
    // TODO: manage pagination when returnFullData = true
    if (returnFullData) {
      return data;
    }

    if (typeof data?.page === "undefined") {
      return data?._embedded?.entities ?? [];
    }

    const page = data.page;

    if (page.totalPages <= page.number + 1) {
      return data?._embedded?.entities ?? [];
    }

    input.page = page.number + 1;

    const recursiveResult: T[] = await authorizedCall(callback, input);

    return [...data._embedded.entities, ...recursiveResult];
  });
}
