import { createSlice } from "@reduxjs/toolkit";
import {EntityModelBookingCenter} from "../../openapi";

export type BookingCentersState = {
  bookingCenters: EntityModelBookingCenter[];
  selected?: EntityModelBookingCenter;
  isFetching: boolean;
};

export const INITIAL_STATE: BookingCentersState = {
  bookingCenters: [],
  selected: undefined,
  isFetching: false,
};

const bookingCentersSlice = createSlice({
  name: "bookingCenters",
  initialState: INITIAL_STATE,
  reducers: {
    fetchBookingCenters: (state: BookingCentersState) => {
      state.isFetching = true;
    },
    fetchBookingCentersSuccess: (state: BookingCentersState, action) => {
      state.bookingCenters = action.payload.bookingCenters;
      state.isFetching = false;
    },
    bookingCenterSelected: (state: BookingCentersState, action) => {
      state.selected = action.payload;
    },
    resetBookingCenters: (state: BookingCentersState) => {
      state.bookingCenters = [];
    }
  },
});

export const {
  fetchBookingCenters,
  fetchBookingCentersSuccess,
  bookingCenterSelected,
  resetBookingCenters
} = bookingCentersSlice.actions;

export default bookingCentersSlice.reducer;
