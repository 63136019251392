import React from "react";
import { Divider, Grid, SelectChangeEvent, Typography } from "@mui/material";
import { FormInput } from "../../../../../../../components/hook-form/FormInput";
import { FormFieldLabel } from "../../../../../../../components/hook-form/FormFieldLabel";
import { useFormContext, useWatch } from "react-hook-form";
import { MultipleSimulationFormInputs } from "../../types/MultipleSimulationFormInputs";
import { FormSelect } from "../../../../../../../components/hook-form/FormSelect";
import { mapEntitiesWithNoCodeToFormSelectOptions } from "../../../../../../../utils/formUtils";
import { useAppSelector } from "../../../../../../../hooks/useAppSelector";
import { selectInstruments } from "../../../../../../../features/instruments/instruments.selectors";
import { selectSelectedBookingCenter } from "../../../../../../../features/bookingCenters/bookingCenters.selectors";
import {
  filterInstrumentsBasedOnEtdCategoryAndPricingModel,
  getSimulationEtdCategoryAndPricingModelBasedOnBookingCenterAndSimNo,
} from "../../../../../../../utils/simulationUtils";
import { EntityModelInstrumentDto } from "../../../../../../../openapi";
import { GroupProps } from "../types";
import {
  getContractLotSizeFieldName,
  getCurrenciesFieldName,
  getDirectionFieldName,
  getExchangeRate4SimFieldName,
  getExchangeRateFieldName,
  getFullNameFieldName,
  getInstrumentSecurityCurrencyFieldName,
  getInstrumentSecurityNumberFieldName,
  getInstrumentTypeFieldName,
  getOverrideExchangeRateFieldName,
  getOverridePremiumPerContractLotFieldName,
  getOverrideQuantityFieldName,
  getPremiumPerContractLotFieldName,
  getPremiumPerContractLot4SimFieldName,
  getQuantity4SimFieldName,
  getQuantityFieldName,
  getSegmentFieldName,
  getStockExchangeFieldName,
  getTransactionFieldName,
} from "../utils";

export const Group3 = (props: GroupProps): React.ReactElement => {
  const [instruments, setInstruments] = React.useState<
    EntityModelInstrumentDto[]
  >([]);
  const { control, setValue } = useFormContext<MultipleSimulationFormInputs>();
  const { simNo } = props;

  const bookingCenter = useAppSelector(selectSelectedBookingCenter);
  const instrumentsFull = useAppSelector(selectInstruments);

  const quantity = useWatch({ control, name: getQuantityFieldName(simNo) });
  const overrideQuantity = useWatch({
    control,
    name: getOverrideQuantityFieldName(simNo),
  });

  const premiumPerContractLot = useWatch({
    control,
    name: getPremiumPerContractLotFieldName(simNo),
  });
  const overridePremiumPerContractLot = useWatch({
    control,
    name: getOverridePremiumPerContractLotFieldName(simNo),
  });

  const exchangeRate = useWatch({
    control,
    name: getExchangeRateFieldName(simNo),
  });
  const overrideExchangeRate = useWatch({
    control,
    name: getOverrideExchangeRateFieldName(simNo),
  });

  const premiumPerContrLot = useWatch({
    control,
    name: getPremiumPerContractLotFieldName(simNo),
  });

  const getELlementsSimulationNumber = (
    simNo: number,
    i: Array<EntityModelInstrumentDto & { simulationNumber: number }>
  ): SelectChangeEvent<string> | { target: { value: string } } => {
    const getSimulationNumber = i
      .filter((el) => el.simulationNumber)
      .find((element) => element.simulationNumber === simNo);
    return getSimulationNumber
      ? { target: { value: getSimulationNumber.text } }
      : { target: { value: i[0].text } };
  };

  React.useEffect(() => {
    if (!bookingCenter) {
      return;
    }

    const { etdCategory, pricingModel } =
      getSimulationEtdCategoryAndPricingModelBasedOnBookingCenterAndSimNo(
        bookingCenter,
        simNo
      );

    const i = filterInstrumentsBasedOnEtdCategoryAndPricingModel(
      instrumentsFull,
      etdCategory,
      pricingModel
    );

    setInstruments((prev) => i);
    let res = getELlementsSimulationNumber(
      simNo,
      i as Array<EntityModelInstrumentDto & { simulationNumber: number }>
    );
    setValue(
      getInstrumentSecurityCurrencyFieldName(simNo),
      res ? res.target.value : i[0].text
    );
    onInstrumentSecurityCurrencyChange(
      getELlementsSimulationNumber(
        simNo,
        i as Array<EntityModelInstrumentDto & { simulationNumber: number }>
      ),
      i
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instrumentsFull, bookingCenter, setInstruments, simNo, setValue]);

  React.useEffect(() => {
    if (!overrideQuantity || overrideQuantity === "") {
      setValue(getQuantity4SimFieldName(simNo), quantity);
    } else {
      setValue(getQuantity4SimFieldName(simNo), overrideQuantity);
    }
  }, [quantity, overrideQuantity, setValue, simNo]);

  React.useEffect(() => {
    if (
      !overridePremiumPerContractLot ||
      overridePremiumPerContractLot === ""
    ) {
      setValue(
        getPremiumPerContractLot4SimFieldName(simNo),
        premiumPerContractLot
      );
    } else {
      setValue(
        getPremiumPerContractLot4SimFieldName(simNo),
        overridePremiumPerContractLot
      );
    }
  }, [premiumPerContractLot, overridePremiumPerContractLot, setValue, simNo]);

  React.useEffect(() => {
    if (!overrideExchangeRate || overrideExchangeRate === "") {
      setValue(getExchangeRate4SimFieldName(simNo), exchangeRate);
    } else {
      setValue(getExchangeRate4SimFieldName(simNo), overrideExchangeRate);
    }
  }, [exchangeRate, overrideExchangeRate, setValue, simNo]);

  const onInstrumentSecurityCurrencyChange = (
    event: SelectChangeEvent<string> | { target: { value: string } },
    _instruments: EntityModelInstrumentDto[] | undefined = undefined
  ) => {
    const iText = event.target.value;
    let instrument;

    if (_instruments) {
      instrument = _instruments.find((i) => i.text === iText);
    } else {
      instrument = instruments.find((i) => i.text === iText);
    }

    setValue(
      getTransactionFieldName(simNo),
      instrument?.transaction?.text ?? ""
    );
    setValue(
      getInstrumentTypeFieldName(simNo),
      instrument?.instrumentType?.text ?? ""
    );
    setValue(getDirectionFieldName(simNo), instrument?.direction?.text ?? "");
    setValue(getFullNameFieldName(simNo), instrument?.fullName ?? "");
    setValue(getQuantityFieldName(simNo), "" + instrument?.quantity);
    setValue(getContractLotSizeFieldName(simNo), "" + instrument?.contrLotSize);
    setValue(
      getPremiumPerContractLotFieldName(simNo),
      "" + (instrument?.premiumPerContrLot ?? "")
    );
    setValue(
      getCurrenciesFieldName(simNo),
      "" + instrument?.instrSecurityCurrency.code
    );

    setValue(getExchangeRateFieldName(simNo), "" + instrument?.exchangeRate);
    setValue(
      getStockExchangeFieldName(simNo),
      "" + instrument?.stockExchange.text
    );
    setValue(
      getInstrumentSecurityNumberFieldName(simNo),
      "" + instrument?.instrSecurityNumber
    );
  };

  return (
    <React.Fragment>
      <Typography
        color={"secondary"}
        style={{
          textTransform: "uppercase",
          fontSize: "12px",
          marginTop: "15px",
        }}
      >
        {"Group 3"}
      </Typography>
      <Divider />
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getSegmentFieldName(simNo)}
          label={<FormFieldLabel>Segment</FormFieldLabel>}
          control={control}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormSelect
          name={getInstrumentSecurityCurrencyFieldName(simNo)}
          control={control}
          label={
            <FormFieldLabel isRequired>
              Instrument, Security Currency
            </FormFieldLabel>
          }
          handleChange={onInstrumentSecurityCurrencyChange}
          fullWidth
          autoComplete="off"
          options={mapEntitiesWithNoCodeToFormSelectOptions(instruments)}
          rules={{ required: "Instrument, Security Currency is Required." }}
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getTransactionFieldName(simNo)}
          control={control}
          label={<FormFieldLabel>Transaction</FormFieldLabel>}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getInstrumentTypeFieldName(simNo)}
          control={control}
          label={<FormFieldLabel>Instrument Type</FormFieldLabel>}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getDirectionFieldName(simNo)}
          control={control}
          label={<FormFieldLabel>Direction</FormFieldLabel>}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getFullNameFieldName(simNo)}
          control={control}
          label={<FormFieldLabel>Underlying (Full Name)</FormFieldLabel>}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getQuantityFieldName(simNo)}
          control={control}
          label={<FormFieldLabel>Quantity</FormFieldLabel>}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getOverrideQuantityFieldName(simNo)}
          type="number"
          label={
            <FormFieldLabel>
              <b style={{ color: "red" }}>Override Quantity</b>
            </FormFieldLabel>
          }
          fullWidth
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getQuantity4SimFieldName(simNo)}
          label={<FormFieldLabel>Quantity 4 sim</FormFieldLabel>}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getContractLotSizeFieldName(simNo)}
          label={<FormFieldLabel>Contract / Lot Size</FormFieldLabel>}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getPremiumPerContractLotFieldName(simNo)}
          label={<FormFieldLabel>Premium per contract / lot</FormFieldLabel>}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getOverridePremiumPerContractLotFieldName(simNo)}
          label={
            <FormFieldLabel>
              <b style={premiumPerContrLot ? { color: "red" } : {}}>
                Override Premium per contract / lot
              </b>
            </FormFieldLabel>
          }
          disabled={!premiumPerContrLot}
          fullWidth
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getPremiumPerContractLot4SimFieldName(simNo)}
          label={
            <FormFieldLabel>Premium per contract / lot 4 sim</FormFieldLabel>
          }
          fullWidth
          disabled
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getCurrenciesFieldName(simNo)}
          label={<FormFieldLabel>Currencies</FormFieldLabel>}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getExchangeRateFieldName(simNo)}
          label={<FormFieldLabel isRequired>Exchange Rate</FormFieldLabel>}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getOverrideExchangeRateFieldName(simNo)}
          type={"number"}
          label={
            <FormFieldLabel>
              <b style={{ color: "red" }}>Override Exchange Rate</b>
            </FormFieldLabel>
          }
          fullWidth
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getExchangeRate4SimFieldName(simNo)}
          label={<FormFieldLabel>Exchange Rate 4 sim</FormFieldLabel>}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getStockExchangeFieldName(simNo)}
          label={<FormFieldLabel>Stock Exchange</FormFieldLabel>}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item style={{ marginTop: "30px" }}>
        <FormInput
          name={getInstrumentSecurityNumberFieldName(simNo)}
          label={
            <FormFieldLabel>
              Instr. Security Number (if available)
            </FormFieldLabel>
          }
          fullWidth
          disabled
        />
      </Grid>
    </React.Fragment>
  );
};
