import { UserPanel } from "./components/UserPanel";
import { AuthenticatedLayout } from "../../components/layouts/AuthenticatedLayout";
import { Breadcrumbs } from "../../components/core/Breadcrumbs";

export const Home = () => {
  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <UserPanel />
    </AuthenticatedLayout>
  );
};
