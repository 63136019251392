import { createSlice } from "@reduxjs/toolkit";
import { PricingModelDto } from "../../openapi";

export type PricingModelsState = {
  pricingModels: PricingModelDto[];
  selected?: PricingModelDto;
  isFetching: boolean;
};

export const INITIAL_STATE: PricingModelsState = {
  pricingModels: [],
  selected: undefined,
  isFetching: false,
};

export type FetchPricingModelsAction = {
  type: string;
  payload: {
    bookingCenterId: number;
  }
}

const pricingModelsSlice = createSlice({
  name: "pricingModels",
  initialState: INITIAL_STATE,
  reducers: {
    fetchPricingModels: (state: PricingModelsState, _action: FetchPricingModelsAction) => {
      state.isFetching = true;
    },
    fetchPricingModelsSuccess: (state: PricingModelsState, action) => {
      state.pricingModels = action.payload.pricingModels;
      state.isFetching = false;
    },
    pricingModelSelected: (state: PricingModelsState, action) => {
      state.selected = action.payload;
    },
    resetPricingModels: (state: PricingModelsState) => {
      state.pricingModels = [];
    }
  },
});

export const {
  fetchPricingModels,
  fetchPricingModelsSuccess,
  pricingModelSelected,
  resetPricingModels
} = pricingModelsSlice.actions;

export default pricingModelsSlice.reducer;
