import { Card } from "../../../components/core/Card";
import { UserActions } from "./UserActions";
import { Box } from "@mui/material";
import { useUser } from "../../../hooks/useUser";
import { styled } from "@mui/material/styles";
import { useAuth } from "react-oidc-context";

const StyledUserPanel = styled(Box)`
  display: flex;
  justify-content: center;
`;

const CardComponent = () => {
  const auth = useAuth();

  return (
    <StyledUserPanel>{auth.isAuthenticated && <UserActions />}</StyledUserPanel>
  );
};

export const UserPanel = () => {
  const { isAdmin } = useUser();

  const adminLabel = isAdmin && "Admin Panel";
  const userLabel = !isAdmin && "User Panel";
  const cardLabel = adminLabel || userLabel || "";

  return (
    <Card
      title={cardLabel}
      style={{ width: "100%", minHeight: "646px" }}
      Component={CardComponent}
    />
  );
};
