import { RootState } from "../../app/store";
import { InstrumentTypeDto } from "../../openapi";

export const selectIsFetchingInstrumentTypes = (state: RootState): boolean =>
  state.itReducer.isFetching;
export const selectInstrumentTypes = (state: RootState): InstrumentTypeDto[] =>
  state.itReducer.instrumentTypes;
export const selectSelectedInstrumentType = (
  state: RootState
): InstrumentTypeDto | undefined => state.itReducer.selected;
