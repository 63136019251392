import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";

export interface SpinnerProps {
  height?: string;
  width?: string;
}

const StyledContainer = styled(Box)`
  height: ${(props: SpinnerProps) => props.height};
  width: ${(props: SpinnerProps) => props.width};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Spinner = (props: SpinnerProps) => {
  const { height, width } = props;

  return (
    <StyledContainer width={width} height={height}>
      <CircularProgress data-testid="spinner" />
    </StyledContainer>
  );
};
