import { Routes, Route, Navigate } from "react-router-dom";
import { Home } from "../pages/Home";
import { Login } from "../pages/Login";
import { SingleSimulation } from "../pages/SingleSimulation";
import { ProtectedRoute } from "./ProtectedRoute";
import { MultipleSimulation } from "../pages/MultipleSimulation";
import { EditFeeMatrix3p } from "../pages/EditFeeMatrix3p";
import { EditValueBand } from "../pages/EditValueBand";
import { EditFeeTable } from "../pages/EditFeeTable/";
import { EditInstrument } from "../pages/EditInstrument";
import { EditFeeMatrixBJB } from "../pages/EditFeeMatrixBJB";
import { ValueBand } from "../pages/ValueBand/ValueBand";
import { FeeMatrix3p } from "../pages/FeeMatrix3p";
import { FeeTable } from "../pages/FeeTable";
import { FeeMatrixBJB } from "../pages/FeeMatrixBJB";
import { Instrument } from "../pages/Instrument";
import { ClonedSingleSimulation } from "../pages/ClonedSingleSimulation";
import {
  UnauthenticatedTemplate,
  AuthenticatedTemplate,
} from "../app/authentication-templates";

export const AppRoutes = () => {
  return (
    <>
      <UnauthenticatedTemplate>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route
            path="table/value-bands"
            element={
              <ProtectedRoute>
                <ValueBand />
              </ProtectedRoute>
            }
          />

          <Route
            path="table/fee-matrix-3p"
            element={
              <ProtectedRoute>
                <FeeMatrix3p />
              </ProtectedRoute>
            }
          />

          <Route
            path="table/fee-table"
            element={
              <ProtectedRoute>
                <FeeTable />
              </ProtectedRoute>
            }
          />

          <Route
            path="table/fee-matrix-bjb"
            element={
              <ProtectedRoute>
                <FeeMatrixBJB />
              </ProtectedRoute>
            }
          />

          <Route
            path="table/instruments"
            element={
              <ProtectedRoute>
                <Instrument />
              </ProtectedRoute>
            }
          />

          <Route
            path="/single-simulation"
            element={
              <ProtectedRoute>
                <SingleSimulation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/multiple-simulation"
            element={
              <ProtectedRoute>
                <MultipleSimulation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cloned-single-simulation"
            element={
              <ProtectedRoute forcePath={"/single-simulation"}>
                <ClonedSingleSimulation />
              </ProtectedRoute>
            }
          />
          <Route
            path="table/fee-matrix-3p/edit/:id"
            element={
              <ProtectedRoute>
                <EditFeeMatrix3p />
              </ProtectedRoute>
            }
          />
          <Route
            path="table/value-bands/edit/:id"
            element={
              <ProtectedRoute>
                <EditValueBand />
              </ProtectedRoute>
            }
          />
          <Route
            path="table/fee-table/edit/:id"
            element={
              <ProtectedRoute>
                <EditFeeTable />
              </ProtectedRoute>
            }
          />
          <Route
            path="table/fee-matrix-bjb/edit/:id"
            element={
              <ProtectedRoute>
                <EditFeeMatrixBJB />
              </ProtectedRoute>
            }
          />
          <Route
            path="table/instruments/edit/:id"
            element={
              <ProtectedRoute>
                <EditInstrument />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AuthenticatedTemplate>
    </>
  );
};
