import { takeLatest, put } from "redux-saga/effects";
import {
  fetchChargeModels,
  FetchChargeModelsAction,
  fetchChargeModelsForMultipleSimulation,
  fetchChargeModelsSuccess,
  FetchChargeModelsActionForMultipleSimulationAction,
  fetchChargeModelsForMultipleSimulationSuccess,
} from "./chargeModels.reducer";
import {
  BookingCenterControllerService,
  EntityModelChargeModelDto,
} from "../../openapi";
import authorizedCall from "../../utils/authorizedCall";

export function* fetchChargeModelsSaga(action: FetchChargeModelsAction) {
  const response: EntityModelChargeModelDto[] = yield authorizedCall(
    BookingCenterControllerService.getChargeModels,
    {
      bookingCenterId: action.payload.bookingCenterId,
      pricingModelId: action.payload.pricingModelId,
      categoryId: action.payload.categoryId,
      size: 10,
      page: 0,
    }
  );

  yield put(fetchChargeModelsSuccess({ chargeModels: response }));
}

export function* fetchChargeModelsForMultipleSimulationSaga(
  action: FetchChargeModelsActionForMultipleSimulationAction
) {
  const response: EntityModelChargeModelDto[] = yield authorizedCall(
    BookingCenterControllerService.getChargeModelsNonEurex,
    {
      bookingCenterId: action.payload.bookingCenterId,
    }
  );

  yield put(
    fetchChargeModelsForMultipleSimulationSuccess({ chargeModels: response })
  );
}

export default function* rootChargeModelsSaga() {
  yield takeLatest(fetchChargeModels.type, fetchChargeModelsSaga);
  yield takeLatest(
    fetchChargeModelsForMultipleSimulation.type,
    fetchChargeModelsForMultipleSimulationSaga
  );
}
