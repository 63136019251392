import { FormSelectOption } from "../components/hook-form/FormSelect";
import { Entity } from "../types/Entity";
import { SingleSimulationFormInputs } from "../pages/SingleSimulation/components/SingleSimulationPanel/SingleSimulationForm/types/SingleSimulationFormInputs";

import {
  CurrencyDto,
  DirectionDto,
  EntityModelBookingCenter,
  EntityModelEtdCategory,
  EntityModelFeeCode,
  EntityModelLanguage,
  InputMultipleDto,
  InstrumentTypeDto,
  PricingModelDto,
  SpecialConditionType,
  TransactionDto,
} from "../openapi";
import { MultipleSimulationFormInputs } from "../pages/MultipleSimulation/components/MultipleSimulationPanel/MultipleSimulationForm/types/MultipleSimulationFormInputs";
import { MultipleOutput } from "../features/multipleOutput/multipleOutput.reducer";
import { getAccessToken, parseJwt } from "./jwtUtils";

type pricingModelByIndex =
  | "pricingModel1"
  | "pricingModel2"
  | "pricingModel3"
  | "pricingModel4";
type specialConditionsByIndex =
  | "specialConditions1"
  | "specialConditions2"
  | "specialConditions3"
  | "specialConditions4";
type etdCategoryByIndex =
  | "etdCategory1"
  | "etdCategory2"
  | "etdCategory3"
  | "etdCategory4";
type instrumentSecurityCurrencyByIndex =
  | "instrumentSecurityCurrency1"
  | "instrumentSecurityCurrency2"
  | "instrumentSecurityCurrency3"
  | "instrumentSecurityCurrency4";
type currenciesByIndex =
  | "currencies1"
  | "currencies2"
  | "currencies3"
  | "currencies4";
type chargesModelSimByIndex =
  | "chargesModelSim1"
  | "chargesModelSim2"
  | "chargesModelSim3"
  | "chargesModelSim4";
type instrumentTypeByIndex =
  | "instrumentType1"
  | "instrumentType2"
  | "instrumentType3"
  | "instrumentType4";
type fullNameByIndex = "fullName1" | "fullName2" | "fullName3" | "fullName4";
type stockExchangeByIndex =
  | "stockExchange1"
  | "stockExchange2"
  | "stockExchange3"
  | "stockExchange4";
type contractLotSizeByIndex =
  | "contractLotSize1"
  | "contractLotSize2"
  | "contractLotSize3"
  | "contractLotSize4";
type transactionByIndex =
  | "transaction1"
  | "transaction2"
  | "transaction3"
  | "transaction4";
type directionByIndex =
  | "direction1"
  | "direction2"
  | "direction3"
  | "direction4";
type quantityByIndex = "quantity1" | "quantity2" | "quantity3" | "quantity4";
type specialConditionsAmountByIndex =
  | "specialConditionsAmount1"
  | "specialConditionsAmount2"
  | "specialConditionsAmount3"
  | "specialConditionsAmount4";
type minimumFeeByIndex =
  | "minimumFee1"
  | "minimumFee2"
  | "minimumFee3"
  | "minimumFee4";

export type Options = {
  useCodeAsLabel?: boolean;
  useNameAsLabel?: boolean;
};

const DEFAULT_OPTIONS: Options = {
  useCodeAsLabel: false,
  useNameAsLabel: false,
};

export const mapEntitiesToFormSelectOptions = (
  entities: Entity[],
  options: Options = DEFAULT_OPTIONS
): FormSelectOption[] => {
  let selectOptions: FormSelectOption[] = [];
  const { useCodeAsLabel, useNameAsLabel } = options;

  for (let entity of entities) {
    switch (true) {
      case useCodeAsLabel:
        selectOptions.push({
          label: String(entity.code),
          value: String(entity.code),
        });
        break;
      case useNameAsLabel:
        selectOptions.push({
          label: String(entity.name),
          value: String(entity.code),
        });
        break;
      default:
        selectOptions.push({
          label: entity.text ?? String(entity.code),
          value: String(entity.code),
        });
        break;
    }
  }

  return selectOptions;
};

export const mapEntitiesWithNoCodeToFormSelectOptions = (
  entities: Entity[]
): FormSelectOption[] => {
  const options = entities.map((entity) => ({
    label: String(entity.text),
    value: String(entity.text),
  }));
  return options;
};

const parseNumber = (str: string) => {
  if (str.indexOf(".") !== -1) {
    return parseFloat(str);
  } else {
    return parseInt(str);
  }
};

export const prepareDataForSingleOutput = (
  data: SingleSimulationFormInputs,
  languages: EntityModelLanguage[],
  etdCategories: EntityModelEtdCategory[],
  bookingCenters: EntityModelBookingCenter[],
  currencies: CurrencyDto[],
  instrumentTypes: InstrumentTypeDto[],
  transactions: TransactionDto[],
  directions: DirectionDto[]
): SingleSimulationFormInputs & { languageId: number } => {
  const languageId = languages.find(
    (language) => language.code === data.outputLanguage
  )?.id as number;
  const etdCategory = etdCategories.find((etd) => etd.code === data.etdCategory)
    ?.text as string;
  const bookingCenter = bookingCenters.find(
    (bc) => bc.code === data.bookingCenter
  )?.code as string;
  const instrumentSecurityCurrency = currencies.find(
    (c) => c.code === data.instrumentSecurityCurrency
  )?.code as string;
  const bookingCenterRateCardCurrency = bookingCenters.find(
    (bc) => bc.code === data.bookingCenter
  )?.currency?.code as string;
  const instrumentType = instrumentTypes.find(
    (it) => it.code === data.instrumentType
  )?.text as string;
  const transaction = transactions.find((t) => t.code === data.transaction)
    ?.text as string;
  const direction = directions.find((d) => d.code === data.direction)
    ?.text as string;

  return {
    ...data,
    languageId,
    etdCategory,
    bookingCenter,
    instrumentSecurityCurrency,
    bookingCenterRateCardCurrency,
    instrumentType,
    transaction,
    direction,
    quantity: Number(data.quantity),
    totalTransactionFeeInstrumentCurrency: Number(
      data.totalTransactionFeeInstrumentCurrency
    ),
    premiumPerContractLot: Number(data.premiumPerContractLot),
  };
};

const defaultToZero = (n: number): number => {
  if (isNaN(n)) {
    return 0;
  }

  return n;
};

const getInputModelDtoByIndex = ({
  index,
  languageId,
  data,
  bookingCenters,
  pricingModels,
  specialConditionTypes,
}: {
  index: 1 | 2 | 3 | 4;
  languageId: number;
  data: MultipleSimulationFormInputs;
  languages: EntityModelLanguage[];
  bookingCenters: EntityModelBookingCenter[];
  feeCodes: EntityModelFeeCode[];
  pricingModels: PricingModelDto[];
  specialConditionTypes: SpecialConditionType[];
}): InputMultipleDto => {
  const bookingCenter = bookingCenters.find(
    (bc) => bc.code === data.bookingCenter
  );

  const pricingModelId = pricingModels.find(
    (pm) => pm.text === data[("pricingModel" + index) as pricingModelByIndex]
  )?.id as number;

  const specialConditionTypeId = specialConditionTypes.find((sc) => {
    return (
      sc.text ===
      data[("specialConditions" + index) as specialConditionsByIndex]
    );
  })?.id as number;

  const isOverride = data[("overrideQuantity" + index) as quantityByIndex]
    ? parseNumber(data[("overrideQuantity" + index) as quantityByIndex])
    : parseNumber(data[("quantity" + index) as quantityByIndex]);

  const isPremiumPerContractOverride = data[
    ("overridePremiumPerContractLot" + index) as quantityByIndex
  ]
    ? parseNumber(
        data[("overridePremiumPerContractLot" + index) as quantityByIndex]
      )
    : parseNumber(data[("premiumPerContractLot" + index) as quantityByIndex]);

  const isExchangeExist = data[("exchangeRate4Sim" + index) as quantityByIndex]
    ? data[("exchangeRate4Sim" + index) as quantityByIndex]
    : data[("exchangeRate" + index) as quantityByIndex];

  return {
    languageId,
    etdCategory: data[("etdCategory" + index) as etdCategoryByIndex],
    bookingCenter: bookingCenter?.code as string,
    instrumentSecurityCurrency:
      data[("currencies" + index) as currenciesByIndex],
    instrumentShortName:
      data[
        ("instrumentSecurityCurrency" +
          index) as instrumentSecurityCurrencyByIndex
      ],
    bookingCenterRateCardCurrency: bookingCenter?.currency?.code as string,
    specialConditions:
      data[("specialConditions" + index) as specialConditionsByIndex],
    thirdPartyChargesModel:
      data[("chargesModelSim" + index) as chargesModelSimByIndex],
    noSimulation3rdPartyCharges: data.simulateThirdPartyCharges === "no",
    userId: parseJwt(getAccessToken() ?? "")?.upn,
    portfolioNo: data.portfolioNo,
    instrumentType: data[("instrumentType" + index) as instrumentTypeByIndex],
    underlyingFullName: data[("fullName" + index) as fullNameByIndex],
    tickerIsinOfUnderlying: "n.a",
    instrumentSecurityNumber: "n.a",
    stockExchangeIndicative:
      data[("stockExchange" + index) as stockExchangeByIndex],
    strike: "n.a",
    contractLotSize: defaultToZero(
      parseInt(data[("contractLotSize" + index) as contractLotSizeByIndex])
    ),
    exchangeRate: isExchangeExist,
    transaction: data[("transaction" + index) as transactionByIndex],
    direction: data[("direction" + index) as directionByIndex],
    quantity: isOverride,
    premiumPerContractLot: isPremiumPerContractOverride,
    pricingModelId,
    specialConditionTypeId,
    specialConditionAmount: defaultToZero(
      parseNumber(
        data[
          ("specialConditionsAmount" + index) as specialConditionsAmountByIndex
        ]
      )
    ),
    minimumFee: defaultToZero(
      parseFloat(data[("minimumFee" + index) as minimumFeeByIndex])
    ),
  };
};

export const prepareDataForMultipleOutput = (
  data: MultipleSimulationFormInputs,
  languages: EntityModelLanguage[],
  bookingCenters: EntityModelBookingCenter[],
  feeCodes: EntityModelFeeCode[],
  pricingModels: PricingModelDto[],
  specialConditionTypes: SpecialConditionType[]
): Omit<MultipleOutput, "userId" | "leftVerticalSign"> => {
  const languageId = languages.find(
    (language) => language.code === data.outputLanguage
  )?.id as number;

  const feeCodeId = feeCodes.find((fc) => fc.code === parseInt(data.feeCode))
    ?.id as number;

  const bookingCenterId = bookingCenters.find(
    (bc) => bc.code === data.bookingCenter
  )?.id as number;

  const inputMultipleDtoList = [];

  for (let index = 1; index < 5; index++) {
    inputMultipleDtoList.push(
      getInputModelDtoByIndex({
        index: index as 1 | 2 | 3 | 4,
        languageId,
        data,
        languages,
        bookingCenters,
        feeCodes,
        pricingModels,
        specialConditionTypes,
      })
    );
  }

  return {
    languageId,
    bookingCenterId,
    portfolioNumber: data.portfolioNo,
    feeCodeId,
    comments: data.comments,
    inputMultipleDtoList,
  };
};
