/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FeeMatrix3PUpdateDto } from "../models/FeeMatrix3PUpdateDto";
import type { PagedModelEntityModelFeeMatrix3P } from "../models/PagedModelEntityModelFeeMatrix3P";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class FeeMatrix3PControllerService {
  /**
   * It updates some columns on FEE_MATRIX_3P table
   * @param feeMatrix3PId
   * @param requestBody
   * @param authorization
   * @returns void
   * @throws ApiError
   */
  public static updateFeeMatrix3P(
    feeMatrix3PId: number,
    requestBody: FeeMatrix3PUpdateDto,
    authorization?: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/fee-matrix-3p/{feeMatrix3PId}",
      path: {
        feeMatrix3PId: feeMatrix3PId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        403: `Your role must have update grants to fee matrix 3p table`,
      },
    });
  }

  /**
   * It will return a paged list of the Fee Matrices 3P
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param search
   * @param authorization
   * @param pricingModel
   * @param chargeModel
   * @param rate
   * @param marketPlace
   * @param perLot
   * @param bookingCenter
   * @param etdCategory
   * @param currency
   * @param id
   * @param source
   * @returns PagedModelEntityModelFeeMatrix3P Fee Matrix 3P list paged and filtered by the given mandatory and optional fields
   * @throws ApiError
   */
  public static getFeeMatrices3P(input: {
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
    authorization?: string;
    pricingModel?: string;
    chargeModel?: string;
    rate?: number;
    marketPlace?: string;
    perLot?: number;
    bookingCenter?: string;
    etdCategory?: string;
    currency?: string;
    id?: number;
    source?: string;
  }): CancelablePromise<PagedModelEntityModelFeeMatrix3P> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/fee-matrix-3p",
      headers: {
        Authorization: input.authorization,
      },
      query: {
        page: input.page,
        size: input.size ?? 10,
        sort: input.sort,
        search: input.search,
        pricingModel: input.pricingModel,
        chargeModel: input.chargeModel,
        rate: input.rate,
        marketPlace: input.marketPlace,
        perLot: input.perLot,
        bookingCenter: input.bookingCenter,
        etdCategory: input.etdCategory,
        currency: input.currency,
        id: input.id,
        source: input.source,
      },
      errors: {
        403: `Your role must have read grants to FEE_MATRIX_3P table`,
      },
    });
  }
}
