import { RootState } from "../../app/store";
import { EntityModelInstrumentDto } from "../../openapi";

export const selectIsFetchingInstruments = (state: RootState): boolean =>
  state.iReducer.isFetching;
export let selectInstruments = (state: RootState): EntityModelInstrumentDto[] =>
  state.iReducer.instruments;
export const selectSelectedInstrument = (
  state: RootState
): EntityModelInstrumentDto | undefined => state.iReducer.selected;
