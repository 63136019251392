import React, { useRef } from "react";
import { StyledDiv } from "../../../../index.style";
import { Grid, Typography } from "@mui/material";
import Button from "../../../../../../components/core/Button";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Group1 } from "./Group1";
import { Group2 } from "./Group2";
import { Group3 } from "./Group3";
import { useFormContext, useWatch } from "react-hook-form";
import { MultipleSimulationFormInputs } from "../types/MultipleSimulationFormInputs";
import {
  getChargesModelFieldName,
  getContractLotSizeFieldName,
  getCurrenciesFieldName,
  getDirectionFieldName,
  getEtdCategoryFieldName,
  getExchangeRate4SimFieldName,
  getFullNameFieldName,
  getInstrumentSecurityCurrencyFieldName,
  getInstrumentTypeFieldName,
  getMinimumFeeFieldName,
  getPremiumPerContractLot4SimFieldName,
  getPricingModelFieldName,
  getQuantity4SimFieldName,
  getSpecialConditionsAmountFieldName,
  getSpecialConditionsFieldName,
  getStockExchangeFieldName,
  getTransactionFieldName,
} from "./utils";
import { UtilsControllerService } from "../../../../../../openapi";
import authorizedCall from "../../../../../../utils/authorizedCall";

type SimulationProps = {
  simNo: 1 | 2 | 3 | 4;
};

const clonedUrl = "/cloned-single-simulation?";

export const Simulation = (props: SimulationProps): React.ReactElement => {
  const { simNo } = props;
  const { control } = useFormContext<MultipleSimulationFormInputs>();
  let expiryDate = useRef("");

  const portfolioNo = useWatch({ control, name: "portfolioNo" });
  const language = useWatch({ control, name: "outputLanguage" });
  const bookingCenter = useWatch({ control, name: "bookingCenter" });
  const simulateThirdPartyCharges = useWatch({
    control,
    name: "simulateThirdPartyCharges",
  });
  const etdCategory = useWatch({
    control,
    name: getEtdCategoryFieldName(simNo),
  });
  const instrumentType = useWatch({
    control,
    name: getInstrumentTypeFieldName(simNo),
  });
  const transaction = useWatch({
    control,
    name: getTransactionFieldName(simNo),
  });
  const direction = useWatch({ control, name: getDirectionFieldName(simNo) });
  const fullName = useWatch({ control, name: getFullNameFieldName(simNo) });
  const pricingModel = useWatch({
    control,
    name: getPricingModelFieldName(simNo),
  });
  const chargesModel = useWatch({
    control,
    name: getChargesModelFieldName(simNo),
  });
  const instrumentShortName = useWatch({
    control,
    name: getInstrumentSecurityCurrencyFieldName(simNo),
  });
  const currency = useWatch({ control, name: getCurrenciesFieldName(simNo) });
  const quantity = useWatch({ control, name: getQuantity4SimFieldName(simNo) });
  const specialConditionAmount = useWatch({
    control,
    name: getSpecialConditionsAmountFieldName(simNo),
  });
  const minimumFee = useWatch({ control, name: getMinimumFeeFieldName(simNo) });
  const contractLotSize = useWatch({
    control,
    name: getContractLotSizeFieldName(simNo),
  });
  const premiumPerContractLot = useWatch({
    control,
    name: getPremiumPerContractLot4SimFieldName(simNo),
  });
  const exchangeRate = useWatch({
    control,
    name: getExchangeRate4SimFieldName(simNo),
  });
  const stockExchange = useWatch({
    control,
    name: getStockExchangeFieldName(simNo),
  });
  const specialConditionType = useWatch({
    control,
    name: getSpecialConditionsFieldName(simNo),
  });

  React.useEffect(() => {
    authorizedCall(UtilsControllerService.getExpiryDate, {}, true).then(
      (date: { expiry: string }) => {
        expiryDate.current = date.expiry;
      }
    );
  }, []);

  const onSimulateClone = () => {
    let url = clonedUrl;
    url += "pNo=" + portfolioNo;
    url += "&iSN=" + instrumentShortName;
    url += "&q=" + quantity;
    url += "&ufn=" + fullName;
    url += "&sca=" + specialConditionAmount;
    url += "&nsmf=" + minimumFee;
    url += "&cls=" + contractLotSize;
    url += "&pcl=" + premiumPerContractLot;
    url += "&exr=" + exchangeRate;
    url += "&stpc=" + simulateThirdPartyCharges;
    url += "&exp=" + expiryDate.current;

    url += "&lCode=" + language;
    url += "&bcCode=" + bookingCenter;
    url += "&ecText=" + etdCategory;
    url += "&itText=" + instrumentType;
    url += "&tText=" + transaction;
    url += "&dText=" + direction;
    url += "&iscCode=" + currency;
    url += "&pmText=" + pricingModel;
    url += "&tpcmText=" + chargesModel;
    url += "&mpText=" + stockExchange;
    url += "&sctText=" + specialConditionType;

    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <React.Fragment>
      <Grid item md={3}>
        <StyledDiv>
          <Typography
            color={"primary"}
            style={{
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            {"Sample Simulation " + simNo}
          </Typography>

          <Button
            variant={"outlined"}
            size={"small"}
            style={{ fontSize: "12px", width: "100%", marginTop: "15px" }}
            onClick={onSimulateClone}
          >
            Open as Single Sample{" "}
            <OpenInNewIcon style={{ fontSize: "12px", marginLeft: "5px" }} />
          </Button>

          <Group1 simNo={simNo} />
          <Group2 simNo={simNo} />
          <Group3 simNo={simNo} />
        </StyledDiv>
      </Grid>
    </React.Fragment>
  );
};
