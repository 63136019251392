import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import { Alert, Grid, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Spinner } from "../../../components/core/Spinner";
import { Card } from "../../../components/core/Card";
import { useUser } from "../../../hooks/useUser";
import { FormInput } from "../../../components/hook-form/FormInput";
import { FormFieldLabel } from "../../../components/hook-form/FormFieldLabel";
import {
  FeeTableUpdateDto,
  FeeTableControllerService,
  EntityModelFcdToBcToEcToPmToSt,
  UserControllerService,
} from "../../../openapi";
import { AlertMessage } from "../../../components/core/AlertMessage/AlertMessage";
import { parseJwt } from "../../../utils/jwtUtils";

export interface EditFeeTableInputs {
  bsRemark: string;
  itRemark: string;
  feeCode: string;
  bookingCenter: string;
  etdCategory: string;
  pricingModel: string;
  specialConditionType: string;
  discount: string;
  feeMin: string;
}

export interface EditFeeTableFormProps {
  id?: string;
}

export const EditFeeTableForm = (props: EditFeeTableFormProps) => {
  const [feeTable, setFeeTable] = useState<EntityModelFcdToBcToEcToPmToSt>();
  const [feeTableError, setFeeTableError] = useState("");
  const [feeTableIsFetching, setFeeTableIsFetching] = useState(false);

  const { id } = props;
  const { token, user } = useUser();

  const form = useForm<EditFeeTableInputs>({
    defaultValues: {
      feeCode: "",
      bookingCenter: "",
      etdCategory: "",
      pricingModel: "",
      specialConditionType: "",
      discount: "",
      feeMin: "",
      bsRemark: "",
      itRemark: "",
    },
  });

  const { reset, formState, handleSubmit, control } = form;

  useEffect(() => {
    setFeeTableIsFetching(true);
    if (token) {
      FeeTableControllerService.getFeeTables({
        id: Number(id),
        authorization: `Bearer ${token}`,
        size: 10,
      })
        .then((data) => {
          setFeeTableIsFetching(false);
          setFeeTable(data?._embedded?.entities?.[0] ?? {});
          reset({
            feeCode: data?._embedded?.entities?.[0].feeCode?.text ?? "",
            bookingCenter:
              data?._embedded?.entities?.[0].bookingCenter?.text ?? "",
            pricingModel:
              data?._embedded?.entities?.[0].pricingModel?.text ?? "",
            etdCategory: data?._embedded?.entities?.[0].etdCategory?.text ?? "",
            specialConditionType:
              data?._embedded?.entities?.[0].specialConditionType?.text ?? "",
            discount:
              data?._embedded?.entities?.[0].discount !== null
                ? String(data?._embedded?.entities?.[0].discount)
                : "",
            feeMin:
              data?._embedded?.entities?.[0].feeMin !== null
                ? String(data?._embedded?.entities?.[0].feeMin)
                : "",
            bsRemark: data?._embedded?.entities?.[0].bsRemark ?? "",
            itRemark: data?._embedded?.entities?.[0].itRemark ?? "",
          });
        })
        .catch((error) => {
          setFeeTableIsFetching(false);
          setFeeTableError(error.message);
        });
    }
  }, [id, reset, token]);

  const onSubmit: SubmitHandler<EditFeeTableInputs> = useCallback(
    (data) => {
      if (id) {
        const { discount, feeMin, bsRemark, itRemark } = data;

        const bodyReq: FeeTableUpdateDto = {
          bsRemark: null,
          itRemark: null,
          discount: null,
          feeMin: null,
        };

        if (discount) bodyReq.discount = Number(discount);
        if (feeMin) bodyReq.feeMin = Number(feeMin);
        if (bsRemark) bodyReq.bsRemark = bsRemark;
        if (itRemark) bodyReq.itRemark = itRemark;

        FeeTableControllerService.updateFeeTable(
          Number(id),
          bodyReq,
          `Bearer ${token}`
        )
          .then(() => {
            UserControllerService.addUserAction(
              {
                user: parseJwt(token ?? "")?.upn,
                cdOperation: "entity_FeeTable_edit",
                operation: "ecs_ee",
                description:
                  "The user edited the FeeTable entity with id " + id,
              },
              `Bearer ${token}`
            );
          })
          .catch((error) => setFeeTableError(error.message));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, token, user]
  );

  return (
    <Card
      title="Edit Fee Table"
      style={{ width: "1512px" }}
      actions={
        feeTable &&
        !feeTableError && (
          <LoadingButton
            variant="contained"
            endIcon={<SaveAltOutlinedIcon />}
            form="edit-fee-table-form"
            type="submit"
            sx={{ textTransform: "none" }}
            disabled={formState.isSubmitting}
            loading={formState.isSubmitting}
          >
            Save
          </LoadingButton>
        )
      }
      Component={() => (
        <Box sx={{ margin: "20px" }}>
          {feeTableError && <Alert severity="error">{feeTableError}</Alert>}
          {feeTableIsFetching && !feeTableError && <Spinner height="353px" />}
          {feeTable && !feeTableError && (
            <form
              id="edit-fee-table-form"
              data-testid="edit-fee-table-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container columnSpacing={2} rowSpacing={5}>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="feeCode"
                    label={<FormFieldLabel>Fee Code</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="bookingCenter"
                    label={<FormFieldLabel>Booking Center</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="etdCategory"
                    label={<FormFieldLabel>Edt Category</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="pricingModel"
                    label={<FormFieldLabel>Pricing Model</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="discount"
                    label={<FormFieldLabel>Discount</FormFieldLabel>}
                    fullWidth
                    InputProps={{ inputProps: { min: 0, step: 0.0001 } }}
                    type="number"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    InputProps={{ inputProps: { min: 0, step: 0.0001 } }}
                    name="feeMin"
                    label={<FormFieldLabel>Fee Min</FormFieldLabel>}
                    fullWidth
                    type="number"
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="bsRemark"
                    label={<FormFieldLabel>Bs Remark</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="itRemark"
                    label={<FormFieldLabel>It Remark</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
              </Grid>
            </form>
          )}
          <AlertMessage
            show={
              formState.isSubmitted &&
              formState.isSubmitSuccessful &&
              !feeTableError
            }
            message="Data has been updated correctly"
          />
        </Box>
      )}
    />
  );
};
