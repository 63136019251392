import React from "react";
import { AuthenticatedLayout } from "../../components/layouts/AuthenticatedLayout";
import { SingleSimulationPanel } from "./components/SingleSimulationPanel";
import { OutputPanel } from "./components/OutputPanel";
import { FormProvider, useForm } from "react-hook-form";
import { SingleSimulationFormInputs } from "./components/SingleSimulationPanel/SingleSimulationForm/types/SingleSimulationFormInputs";
import { defaultValues } from "./components/SingleSimulationPanel/SingleSimulationForm/defaultValues";
import { Breadcrumbs } from "../../components/core/Breadcrumbs";

export const SingleSimulation = () => {
  const form = useForm<SingleSimulationFormInputs>({
    defaultValues,
  });

  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <FormProvider {...form}>
        <SingleSimulationInternal />
      </FormProvider>
    </AuthenticatedLayout>
  );
};

export const SingleSimulationInternal = () => {
  const [showSingleSimulation, setShowSingleSimulation] = React.useState(true);

  const switchPanels = () => {
    setShowSingleSimulation((state) => !state);
  };

  return (
    <React.Fragment>
      <SingleSimulationPanel
        hide={!showSingleSimulation}
        onCalculate={switchPanels}
      />
      <OutputPanel hide={showSingleSimulation} handleBack={switchPanels} />
    </React.Fragment>
  );
};
