import { createSlice } from "@reduxjs/toolkit";
import {
  CoverLetterRequestDto,
  EntityModelCoverLetterResponseDto,
} from "../../openapi";

export type MultipleOutput = EntityModelCoverLetterResponseDto &
  CoverLetterRequestDto & { leftVerticalSign: string };

export type MultipleOutputState = {
  output?: MultipleOutput;
  isFetching: boolean;
};

export const INITIAL_STATE: MultipleOutputState = {
  output: undefined,
  isFetching: false,
};

export type FetchMultipleOutputAction = {
  type: string;
  payload: CoverLetterRequestDto;
};

const multipleOutputSlice = createSlice({
  name: "multipleOutput",
  initialState: INITIAL_STATE,
  reducers: {
    fetchMultipleOutput: (state: MultipleOutputState, _action?) => {
      state.isFetching = true;
    },
    fetchMultipleOutputSuccess: (state: MultipleOutputState, action) => {
      state.output = action.payload.output;
      state.isFetching = false;
    },
    resetMultipleOutput: (state: MultipleOutputState) => {
      state.output = undefined;
    },
  },
});

export const {
  fetchMultipleOutput,
  fetchMultipleOutputSuccess,
  resetMultipleOutput,
} = multipleOutputSlice.actions;

export default multipleOutputSlice.reducer;
