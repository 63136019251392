import { takeLatest, put } from "redux-saga/effects";
import { fetchCurrencies, fetchCurrenciesSuccess } from "./currencies.reducer";
import { CurrencyControllerService, CurrencyDto } from "../../openapi";
import authorizedCall from "../../utils/authorizedCall";

export function* fetchCurrenciesSaga() {
  const response: CurrencyDto[] = yield (
    authorizedCall(CurrencyControllerService.getCurrencies, {
      size: 10,
      page: 0,
    })
  );
  yield put(fetchCurrenciesSuccess({ currencies: response }));
}

export default function* rootCurrenciesSaga() {
  yield takeLatest(fetchCurrencies.type, fetchCurrenciesSaga);
}
