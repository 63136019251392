import React, { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { Grid, InputAdornment, SelectChangeEvent } from "@mui/material";
import { FormFieldLabel } from "../../../../../../components/hook-form/FormFieldLabel";
import { FormInput } from "../../../../../../components/hook-form/FormInput";
import { FormSelect } from "../../../../../../components/hook-form/FormSelect";
import { Header } from "../../../../../../components/core/Header";
import { Content } from "../../../../../../components/core/Content";
import { useAppDispatch } from "../../../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../../../hooks/useAppSelector";
import { selectSelectedEtdCategory } from "../../../../../../features/etdCategories/etdCategories.selectors";
import {
  selectCurrencies,
  selectSelectedCurrency,
} from "../../../../../../features/currencies/currencies.selectors";
import {
  currencySelected,
  fetchCurrencies,
} from "../../../../../../features/currencies/currencies.reducer";
import { mapEntitiesToFormSelectOptions } from "../../../../../../utils/formUtils";
import { SingleSimulationFormInputs } from "../types/SingleSimulationFormInputs";
import { selectSelectedBookingCenter } from "../../../../../../features/bookingCenters/bookingCenters.selectors";
import { FormCurrencyInput } from "components/hook-form/FormCurrencyInput";

const BASE_EXCHANGE_RATE_URL = "https://www.x-rates.com/calculator/";

export const InstrumentFields = () => {
  const { control, setValue } = useFormContext<SingleSimulationFormInputs>();
  const dispatch = useAppDispatch();

  const currencies = useAppSelector(selectCurrencies);

  const selectedEtdCategory = useAppSelector(selectSelectedEtdCategory);

  const selectedBookingCenter = useAppSelector(selectSelectedBookingCenter);
  const selectedInstrumentSecurityCurrency = useAppSelector(
    selectSelectedCurrency
  );

  const currency1 =
    selectedBookingCenter?.code?.toLowerCase() === "lux"
      ? selectedBookingCenter?.currency?.code
      : selectedInstrumentSecurityCurrency?.code;
  const currency2 =
    selectedBookingCenter?.code?.toLowerCase() === "lux"
      ? selectedInstrumentSecurityCurrency?.code
      : selectedBookingCenter?.currency?.code;

  const exchangeRateInputProps = useMemo(() => {
    const exchangeRateLabel =
      selectedInstrumentSecurityCurrency?.code &&
      selectedBookingCenter?.currency?.code
        ? currency1 + "/" + currency2
        : null;

    return {
      startAdornment: exchangeRateLabel ? (
        <InputAdornment position="start">{exchangeRateLabel}</InputAdornment>
      ) : null,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedBookingCenter?.currency?.code,
    selectedInstrumentSecurityCurrency?.code,
  ]);

  const etdCategory = useAppSelector(selectSelectedEtdCategory);

  React.useEffect(() => {
    if (!etdCategory) {
      return;
    }

    if (etdCategory?.text.trim().toLowerCase() === "future") {
      setValue("premiumPerContractLot", undefined);
    }
  }, [etdCategory, setValue]);

  const onCurrencyChanged = (evt: SelectChangeEvent<string>) => {
    const cCode = evt.target.value;
    const selected = currencies.find((c) => c.code === cCode);
    dispatch(currencySelected(selected));
  };

  useEffect(() => {
    dispatch(fetchCurrencies());
  }, [dispatch]);

  useEffect(() => {
    if (
      selectedBookingCenter?.currency?.code ===
      selectedInstrumentSecurityCurrency?.code
    ) {
      setValue("exchangeRate", "1");
    } else {
      setValue("exchangeRate", "");
    }
  }, [setValue, selectedBookingCenter, selectedInstrumentSecurityCurrency]);

  return (
    <>
      <Content>
        <Header title="Instruments Fields" />
      </Content>
      <Grid item xs={6}>
        <FormInput
          name="instrumentShortName"
          label={
            <FormFieldLabel isRequired>Instrument Short Name</FormFieldLabel>
          }
          control={control}
          autoComplete="off"
          fullWidth
          rules={{
            required: "Instrument Short Name is Required.",
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <FormInput
          name="instrumentSecurityNumber"
          label={
            <FormFieldLabel>Ins. Security Number (if available)</FormFieldLabel>
          }
          control={control}
          autoComplete="off"
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <FormInput
          name="underlyingFullName"
          label={<FormFieldLabel>Underlying (Full Name)</FormFieldLabel>}
          control={control}
          autoComplete="off"
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <FormInput
          name="tickerIsinOfUnderlying"
          label={<FormFieldLabel>Ticker / ISIN Of Underlying</FormFieldLabel>}
          control={control}
          autoComplete="off"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <FormCurrencyInput
          name="quantity"
          label={<FormFieldLabel isRequired>Quantity</FormFieldLabel>}
          control={control}
          autoComplete="off"
          fullWidth
          rules={{
            required: "Quantity is Required.",
            validate: (value: string | boolean | number | undefined) =>
              (value as number) > 0 || "Quantity must be greater than 0",
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <FormInput
          name="strike"
          label={<FormFieldLabel>Strike</FormFieldLabel>}
          control={control}
          autoComplete="off"
          InputProps={{ inputProps: { min: 0, step: 1 } }}
          fullWidth
          disabled={selectedEtdCategory?.text.toLowerCase() === "future"}
        />
      </Grid>

      <Grid item xs={6}>
        <FormCurrencyInput
          name="contractLotSize"
          label={
            <FormFieldLabel isRequired>Contract / Lot Size</FormFieldLabel>
          }
          control={control}
          autoComplete="off"
          fullWidth
          InputProps={{ inputProps: { min: 0, step: 1 } }}
          rules={{
            required: "Contract / Lot Size is Required.",
            validate: (value: string | boolean | number | undefined) =>
              (value as number) > 0 ||
              "Premium per Contract / Lot must be greater than 0",
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <FormCurrencyInput
          name="premiumPerContractLot"
          label={
            <FormFieldLabel
              isRequired={etdCategory?.text.trim().toLowerCase() === "future"}
            >
              Premium per Contract / Lot
            </FormFieldLabel>
          }
          control={control}
          autoComplete="off"
          fullWidth
          InputProps={{ inputProps: { min: 0, step: 1 } }}
          rules={{
            required:
              etdCategory?.text.trim().toLowerCase() === "future"
                ? false
                : "Premium per Contract / Lot is Required.",
          }}
          disabled={etdCategory?.text.trim().toLowerCase() === "future"}
        />
      </Grid>

      <Grid item xs={6}>
        <FormInput
          name="expiry"
          label={<FormFieldLabel>Expiry</FormFieldLabel>}
          control={control}
          autoComplete="off"
          fullWidth
          rules={{
            pattern: {
              value: /^\d{1,2}.\d{1,2}.\d{4}$/,
              message: "dd.mm.yyyy accepted",
            },
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <FormInput
          name="bookingCenterRateCardCurrency"
          label="Booking Center / Rate Card Currency"
          variant="filled"
          InputProps={{ readOnly: true }}
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <FormSelect
          name="instrumentSecurityCurrency"
          label={
            <FormFieldLabel isRequired>
              Instrument / Security Currency
            </FormFieldLabel>
          }
          control={control}
          fullWidth
          autoComplete="off"
          handleChange={onCurrencyChanged}
          options={mapEntitiesToFormSelectOptions(currencies, {
            useCodeAsLabel: true,
          })}
          rules={{
            required: "Instrument / Security Currency is Required.",
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <FormInput
          name="exchangeRate"
          variant="outlined"
          label={<FormFieldLabel isRequired>Exchange Rate</FormFieldLabel>}
          autoComplete="off"
          InputProps={exchangeRateInputProps}
          fullWidth
          type={"number"}
          disabled={
            !selectedBookingCenter ||
            selectedBookingCenter?.currency?.code ===
              selectedInstrumentSecurityCurrency?.code
          }
          sx={{
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                display: "none",
              },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
          rules={{ required: "Exchange Rate is Required." }}
        />
      </Grid>

      {selectedBookingCenter?.currency?.code &&
        selectedInstrumentSecurityCurrency?.code && (
          <>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <a
                href={`${BASE_EXCHANGE_RATE_URL}?from=${selectedInstrumentSecurityCurrency?.code}&to=${selectedBookingCenter.currency.code}&amount=1`}
                target="_blank"
                rel="noreferrer"
              >
                Verify Exchange Rate
              </a>
            </Grid>
          </>
        )}
    </>
  );
};
