import { RootState } from "../../app/store";
import { CurrencyDto } from "../../openapi";

export let selectIsFetchingCurrencies = (state: RootState): boolean =>
  state.cReducer.isFetching;
export let selectCurrencies = (state: RootState): CurrencyDto[] =>
  state.cReducer.currencies;
export let selectSelectedCurrency = (
  state: RootState
): CurrencyDto | undefined => state.cReducer.selected;
