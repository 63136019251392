import {
  EntityModelBookingCenter,
  EntityModelInstrumentDto,
  EntityModelValueBand,
} from "../openapi";

/**
 * It's a fixed value and depends on.
 * @param t - Transaction
 */
export const getPremiumDirection = (t: string) => {
  if (t.trim() === "B") return "PAID";
  if (t.trim() === "S") return "RECEIVED";
  return "";
};

/**
 * It's a fixed value and depends on: .
 * @param bc - Booking Center
 */
export const getBookingCenterRateCardCurrency = (bc: string) => {
  if (bc === "CH/GUE") return "CHF";
  if (bc === "LUX") return "EUR";
  return "";
};

/**
 * The transaction volume amount in Instrument / Security Currency.
 * Calculated value depending on:
 * @param q - Quantity
 * @param cls - Contract Lot Size
 * @param ppcl - Premium per Contract Lot
 */
export const calculateTransactionVolumeISC = (
  q: number,
  cls: number,
  ppcl: number
) => {
  return q * cls * ppcl;
};

export const getExchangeRate = (er: number, bc: string) => {
  if (bc.toLowerCase() === "lux") {
    return 1 / er;
  }

  return er;
}

/**
 * The transaction volume amount in Booking Center / Rate Card Currency.
 * Calculated value depending on:
 * @param tsvISC - Transaction Volume ISC (prev.)
 * @param er - Exchange Rate
 * @param bc - Booking Center label
 * @returns
 */
export const calculateTransactionVolumeBC = (tsvISC: number, er: number, bc: string) => {
  return tsvISC * getExchangeRate(er, bc);
};

export const getValueBand = (vbs: EntityModelValueBand[], tv: number) => {
  return vbs.find(
    (vb) => (vb?.valMin ?? 0) <= tv && tv <= (vb?.valMax ?? 0)
  ) as EntityModelValueBand;
};

/**
 * Calculated value depending on:
 * @param tv - Transaction Volume
 * @param vbm - Value Band Max
 * @param vbp1m - Value Band Portion 1 Max
 */
export const calculateValueBandPortion1 = (
  tv: number,
  vbm: number,
  vbp1m: number
) => {
  return tv < vbp1m ? tv : vbm;
};

/**
 * Calculated value depending on:
 * @param vbp1m - Value Band Portion 1 Max
 * @param vbp1 - Value Band Portion 1
 * @param vbp2m - Value Band Portion 2 Max
 * @param tv - Transaction Value
 */
export const calculateValueBandPortion2 = (
  vbp1m: number,
  vbp1: number,
  vbp2m: number,
  tv: number
) => {
  return tv < vbp2m ? tv - vbp1 : vbp1m;
};

/**
 * Calculated value depending on:
 * @param vbp1 - Value Band Portion 1
 * @param vbp1m - Value Band Portion 1 Max
 * @param vbp2m - Value Band Portion 2 Max
 * @param tv - Transaction Volume
 */
export const calculateValueBandPortion3 = (
  vbp3m: number,
  vbp2: number,
  vbp1: number,
  vbp2m: number,
  tv: number
) => {
  return tv < vbp3m ? tv - vbp1 - vbp2 : vbp2m;
};

/**
 * BJB_TA_Rate.min.Fee
 * min Fee
 * BJB_TA__Rate.Band 1
 * BJB_TA__Rate.Band 2
 * BJB_TA__Rate.Band 3
 */

/**
 * Calculated value depending on:
 * @param b1 - BJB_TA__Rate.Band 1
 * @param b2 - BJB_TA__Rate.Band 2
 * @param b3 - BJB_TA__Rate.Band 3
 * @param vbp1 - Value Band Portion 1
 * @param vbp2 - Value Band Portion 2
 * @param vbp3 - Value Band Portion 3
 */
export const calculateOptionsStandardTransactionFee = (
  b1: number,
  b2: number,
  b3: number,
  vbp1: number,
  vbp2: number,
  vbp3: number
) => {
  return (vbp1 * b1) / 100 + (vbp2 * b2) / 100 + (vbp3 * b3) / 100;
};

/**
 * Calculated value depending on:
 * @param q -Quantity
 * @param ffpl - Features - Fee Per Lot
 */
export const calculateFeaturesStandardTransactionFee = (
  q: number,
  fpl: number
) => {
  return q * fpl;
};

/**
 * This is the “standard” transaction fee in the rate card (booking center) currency
 * @param ec - EDT Category
 * @param ostf - Options - Standard Transaction Fee
 * @param fstf - Futures - Standard Transaction Fee
 */
export const getStandardTransactionFee = (
  ec: string,
  ostf: string,
  fstf: string
) => {
  return ec === "OPTION" ? ostf : fstf;
};

/**
 * Calculated value based on:
 * @param ttf - Total Transaction Fee (Rate card curr.)
 * @param er - Exchange Rate
 */
export const calculateTotalTransactionFeeInstrCurr = (
  ttf: number,
  er: number
) => {
  return ttf / er;
};

/**
 * Calculated value based on:
 * @param ttf - Total Transaction Fee
 * @param tpc - 3rd Total Party Charges
 */
export const calculateTotalFee = (ttf: number, tpc: number) => {
  return ttf + tpc;
};

export const getSimulationEtdCategoryAndPricingModelBasedOnBookingCenterAndSimNo =
  (bookingCenter: EntityModelBookingCenter, simNo: number) => {
    const etdCategory = simNo < 3 ? "Option" : "Future";
    let pricingModel = "Global";

    if (bookingCenter.code === "CH/GUE") {
      pricingModel = simNo % 2 === 0 ? "Non-Eurex" : "Eurex";
    }

    return {
      etdCategory,
      pricingModel,
    };
  };

export const getSimulationThirdPartyChargesModelBasedOnBookingCenterAndSimNo = (
  bookingCenter: EntityModelBookingCenter | undefined,
  thirdPartyChargesModel: string,
  simNo: number
): string => {
  let tpcModel = "";

  switch (simNo) {
    case 1:
    case 3:
      if (!bookingCenter) {
        break;
      }

      tpcModel =
        bookingCenter.code !== "LUX" ? "Eurex-Fee" : thirdPartyChargesModel;
      break;
    case 2:
    case 4:
      tpcModel = thirdPartyChargesModel;
      break;
  }

  return tpcModel;
};

export const filterInstrumentsBasedOnEtdCategoryAndPricingModel = (
  instruments: EntityModelInstrumentDto[],
  etdCategory: string,
  pricingModel: string
): EntityModelInstrumentDto[] => {
  return instruments.filter(
    (i) =>
      i.etdCategory.text === etdCategory && i.pricingModel.text === pricingModel
  );
};
