import { takeLatest, put } from "redux-saga/effects";
import {
  fetchEtdCategories,
  fetchEtdCategoriesSuccess,
} from "./etdCategories.reducer";
import { EtdCategoryControllerService, EtdCategoryDto } from "../../openapi";
import authorizedCall from "../../utils/authorizedCall";

export function* fetchEtdCategoriesSaga() {
  const response: EtdCategoryDto[] = yield authorizedCall(
    EtdCategoryControllerService.getCategories,
    {
      page: 0,
      size: 10,
    }
  );
  yield put(fetchEtdCategoriesSuccess({ etdCategories: response }));
}

export default function* rootEtdCategoriesSaga() {
  yield takeLatest(fetchEtdCategories.type, fetchEtdCategoriesSaga);
}
