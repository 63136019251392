/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InstrumentUpdateDto } from "../models/InstrumentUpdateDto";
import type { PagedModelEntityModelInstrument } from "../models/PagedModelEntityModelInstrument";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class InstrumentControllerService {
  /**
   * It updates some fields on Instrument table (IM)
   * @param instrumentId
   * @param requestBody
   * @param authorization
   * @returns void
   * @throws ApiError
   */
  public static updateInstruments(
    instrumentId: number,
    requestBody: InstrumentUpdateDto,
    authorization?: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/instruments/{instrumentId}",
      path: {
        instrumentId: instrumentId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        403: `Your role must have update grants to IM table`,
      },
    });
  }

  /**
   * It will return a paged list of the Instruments
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param search
   * @param authorization
   * @param pricingModel
   * @param stockExchange
   * @param instrumentType
   * @param quantity
   * @param simulationNumber
   * @param etdCategory
   * @param fullName
   * @param instrSecurityNumber
   * @param sid
   * @param premiumPerContrLot
   * @param contrLotSize
   * @param exchangeRate
   * @param id
   * @param text
   * @param instrSecurityCurrency
   * @param transaction
   * @param bookingCenterCurrency
   * @param direction
   * @returns PagedModelEntityModelInstrument Instrument list paged and filtered by the given mandatory and optional fields
   * @throws ApiError
   */
  public static getInstruments(input: {
    page?: number;
    size: number;
    sort?: Array<string>;
    search?: string;
    authorization?: string;
    pricingModel?: string;
    stockExchange?: string;
    instrumentType?: string;
    quantity?: number;
    simulationNumber?: number;
    etdCategory?: string;
    fullName?: string;
    instrSecurityNumber?: string;
    sid?: number;
    premiumPerContrLot?: number;
    contrLotSize?: number;
    exchangeRate?: number;
    id?: number;
    text?: string;
    instrSecurityCurrency?: string;
    transaction?: string;
    bookingCenterCurrency?: string;
    direction?: string;
  }): CancelablePromise<PagedModelEntityModelInstrument> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/instruments",
      headers: {
        Authorization: input.authorization,
      },
      query: {
        page: input.page,
        size: input.size,
        sort: input.sort,
        search: input.search,
        "pricingModel.text": input.pricingModel,
        stockExchange: input.stockExchange,
        instrumentType: input.instrumentType,
        quantity: input.quantity,
        simulationNumber: input.simulationNumber,
        etdCategory: input.etdCategory,
        fullName: input.fullName,
        instrSecurityNumber: input.instrSecurityNumber,
        sid: input.sid,
        premiumPerContrLot: input.premiumPerContrLot,
        contrLotSize: input.contrLotSize,
        exchangeRate: input.exchangeRate,
        id: input.id,
        text: input.text,
        instrSecurityCurrency: input.instrSecurityCurrency,
        transaction: input.transaction,
        bookingCenterCurrency: input.bookingCenterCurrency,
        direction: input.direction,
      },
      errors: {
        403: `Your role must have read grants to IM table`,
      },
    });
  }
}
