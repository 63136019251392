/* istanbul ignore file */
import envJson from "./env.json";
type envType = {
  REACT_APP_API_URL: string;
  REACT_APP_API_DOCS_URL: string;
  REACT_APP_ADFS_AUTHORITY: string;
  REACT_APP_ADFS_CLIENT_ID: string;
  REACT_APP_ADFS_REDIRECT_URL: string;
  REACT_APP_ADFS_RESOURCE: string;
  REACT_APP_ENV: string;
  REACT_APP_PDF_VERSION: string;
} & { [x: string]: string };

const env: envType = {
  REACT_APP_API_URL: "",
  REACT_APP_API_DOCS_URL: "",
  REACT_APP_ADFS_AUTHORITY: "",
  REACT_APP_ADFS_CLIENT_ID: "",
  REACT_APP_ADFS_REDIRECT_URL: "",
  REACT_APP_ADFS_RESOURCE: "",
  REACT_APP_ENV:"",
  REACT_APP_PDF_VERSION:""
};

function load() {
  console.log("in envJson load --- env.REACT_APP_API_URL--->");
  let promise;
  if (process.env.NODE_ENV === "production") {
    promise = fetch("/env.json");
  } else {
    promise = Promise.resolve({ json: (): envType => envJson });
  }

  return promise
    .then((result) => result.json())
    .then((newConfig: envType) => {
      for (let prop in env) {
        delete env[prop];
      }
      for (let prop in newConfig) {
        env[prop] = newConfig[prop];
      }
      console.log("in envJson END load --- env.REACT_APP_API_URL--->");
      return env;
    });
}

export default env;
export { load };
