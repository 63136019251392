/* istanbul ignore file */
import React from "react";
import { App } from "./app";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { load } from "./config/env";
import { initialize } from "./openapi/core/OpenAPI";
import { AuthConfig } from "config/auth";
import { AuthProvider } from "react-oidc-context";

const container = document.getElementById("root")!;
const root = createRoot(container);

if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_MOCK_API === "1"
) {
  /**
 *   const { worker } = require("./mocks/browser");
  worker.start();
 */
}

const LoadConfigRender = () => {
  const [hasLoaded, setHasLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    load().then(() => {
      initialize();
      setHasLoaded(true);
    });
  });

  if (!hasLoaded) {
    return <></>;
  }

  return (
    <AuthConfig>
      {(authProviderProps) => (
        <AuthProvider {...authProviderProps}>
          <App />
        </AuthProvider>
      )}
    </AuthConfig>
  );
};

root.render(
  <React.StrictMode>
    <LoadConfigRender />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
