import { createSlice } from "@reduxjs/toolkit";
import { EntityModelFfeMatrixBjbDto } from "../../openapi";

export type FfeMatricesState = {
  ffeMatrices: EntityModelFfeMatrixBjbDto[];
  ffeMatricesForMultipleSimulation1: EntityModelFfeMatrixBjbDto[];
  ffeMatricesForMultipleSimulation2: EntityModelFfeMatrixBjbDto[];
  ffeMatricesForMultipleSimulation3: EntityModelFfeMatrixBjbDto[];
  ffeMatricesForMultipleSimulation4: EntityModelFfeMatrixBjbDto[];
  selected?: EntityModelFfeMatrixBjbDto;
  isFetching: boolean;
};

export const INITIAL_STATE: FfeMatricesState = {
  ffeMatrices: [],
  ffeMatricesForMultipleSimulation1: [],
  ffeMatricesForMultipleSimulation2: [],
  ffeMatricesForMultipleSimulation3: [],
  ffeMatricesForMultipleSimulation4: [],
  selected: undefined,
  isFetching: false,
};

export type FetchFfeMatricesAction = {
  type: string;
  payload: {
    bookingCenterId: number;
    pricingModelId: number;
    categoryId: number;
  };
};

export type FetchFfeMatricesForMultipleSimulationAction = {
  type: string;
  payload: {
    bookingCenterId: number;
    pricingModelId: number;
    categoryId: number;
    simNo: number;
  };
};

export type FetchFfeMatricesForMultipleSimulationSuccessAction = {
  type: string;
  payload: {
    ffeMatrices?: EntityModelFfeMatrixBjbDto[];
    simNo: number;
  };
};

const ffeMatricesSlice = createSlice({
  name: "ffeMatrices",
  initialState: INITIAL_STATE,
  reducers: {
    fetchFfeMatrices: (
      state: FfeMatricesState,
      _action: FetchFfeMatricesAction
    ) => {
      state.isFetching = true;
    },
    fetchFfeMatricesSuccess: (state: FfeMatricesState, action) => {
      state.ffeMatrices = action.payload.ffeMatrices;
      state.isFetching = false;
    },
    fetchFfeMatricesForMultipleSimulation: (
      state: FfeMatricesState,
      _action: FetchFfeMatricesForMultipleSimulationAction
    ) => {
      state.isFetching = true;
    },
    fetchFfeMatricesForMultipleSimulationSuccess: (
      state: FfeMatricesState,
      action: FetchFfeMatricesForMultipleSimulationSuccessAction
    ) => {
      switch (action.payload.simNo) {
        case 1:
          state.ffeMatricesForMultipleSimulation1 = action.payload.ffeMatrices ?? [];
          break;
        case 2:
          state.ffeMatricesForMultipleSimulation2 = action.payload.ffeMatrices ?? [];
          break;
        case 3:
          state.ffeMatricesForMultipleSimulation3 = action.payload.ffeMatrices ?? [];
          break;
        case 4:
          state.ffeMatricesForMultipleSimulation4 = action.payload.ffeMatrices ?? [];
          break;
      }

      state.isFetching = false;
    },
    ffeMatrixSelected: (state: FfeMatricesState, action) => {
      state.selected = action.payload;
    },
    resetFfeMatrices: (state: FfeMatricesState) => {
      state.selected = undefined;
      state.isFetching = false;
      state.ffeMatrices = [];
      state.ffeMatricesForMultipleSimulation1 = [];
      state.ffeMatricesForMultipleSimulation2 = [];
      state.ffeMatricesForMultipleSimulation3 = [];
      state.ffeMatricesForMultipleSimulation4 = [];
    },
  },
});

export const {
  fetchFfeMatrices,
  fetchFfeMatricesSuccess,
  ffeMatrixSelected,
  fetchFfeMatricesForMultipleSimulation,
  fetchFfeMatricesForMultipleSimulationSuccess,
  resetFfeMatrices,
} = ffeMatricesSlice.actions;

export default ffeMatricesSlice.reducer;
