export function downloadPDF(url: string, token: string, defaultFilename = "ecs_result.pdf") {
    fetchPDF(url, token)
      .then((blob) => {
        const filename = getFilenameFromResponseHeaders(blob, defaultFilename);
        downloadBlobAsFile(blob, filename);
      })
      .catch((error) => {
        console.error("Error downloading PDF: ", error);
      });
  }
  
  export function fetchPDF(url: string, token: string) {
    return fetch(url, {
      headers: {
        "Content-Disposition": "attachment",
        Authorization: "Bearer " + token,
      },
    }).then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.blob();
    });
  }
  
  export function getFilenameFromResponseHeaders(blob: Blob, defaultFilename: string) {
    const contentDisposition = blob.type === "application/json" ? null : blob.type;
    let filename = defaultFilename;
  
    if (contentDisposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }
  
    return filename;
  }
  
  export function downloadBlobAsFile(blob: Blob, filename: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("style", "display:none;");
    document.body.appendChild(a);
    a.href = url;
    a.download = filename;
    a.click();
    a.remove();
  }

  
  