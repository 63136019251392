import { takeLatest, put } from "redux-saga/effects";
import {
  fetchPricing,
  FetchPricingAction,
  fetchPricingSuccess,
} from "./pricing.reducer";
import { BookingCenterControllerService, PricingModelDto } from "../../openapi";
import authorizedCall from "../../utils/authorizedCall";

export function* fetchPricingSaga(action: FetchPricingAction) {
  const response: PricingModelDto[] = yield (
    authorizedCall(BookingCenterControllerService.getPricings, {
      bookingCenterId: action.payload.bookingCenterId,
      page: 0,
      size: 10,
    })
  );

  yield put(fetchPricingSuccess({ pricing: response }));
}

export default function* rootPricingSaga() {
  yield takeLatest(fetchPricing.type, fetchPricingSaga);
}
