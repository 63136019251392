import { createSlice } from "@reduxjs/toolkit";
import { EntityModelInstrumentDto } from "../../openapi";

export type InstrumentsState = {
  instruments: EntityModelInstrumentDto[];
  selected?: EntityModelInstrumentDto;
  isFetching: boolean;
};

export const INITIAL_STATE: InstrumentsState = {
  instruments: [],
  selected: undefined,
  isFetching: false,
};

export type FetchInstrumentsAction = {
  type: string;
  payload: {
    search?: string;
    pricingModel?: string;
  };
}

export type FetchInstrumentsForMultipleSimulationAction = {
  type: string;
  payload: {
    categoryId: number;
    pricingModelId: number;
    transactionId: number;
    instrumentTypeId: number;
    directionId: number;
  };
}

const instrumentsSlice = createSlice({
  name: "instruments",
  initialState: INITIAL_STATE,
  reducers: {
    fetchInstruments: (state: InstrumentsState, _action?) => {
      state.isFetching = true;
    },
    fetchInstrumentsSuccess: (state: InstrumentsState, action) => {
      state.instruments = action.payload.instruments;
      state.isFetching = false;
    },
    fetchInstrumentsForMultipleSimulation: (state: InstrumentsState, _action: FetchInstrumentsForMultipleSimulationAction) => {
      state.isFetching = true;
    },
    fetchInstrumentsForMultipleSimulationSuccess: (state: InstrumentsState, action) => {
      state.instruments = action.payload.instruments;
      state.isFetching = false;
    },
    instrumentSelected: (state: InstrumentsState, action) => {
      state.selected = action.payload;
    },
    resetInstruments: (state: InstrumentsState) => {
      state.instruments = [];
    }
  },
});

export const {
  fetchInstruments,
  fetchInstrumentsSuccess,
  fetchInstrumentsForMultipleSimulation,
  fetchInstrumentsForMultipleSimulationSuccess,
  instrumentSelected,
  resetInstruments
} = instrumentsSlice.actions;

export default instrumentsSlice.reducer;
