import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import { EntityTable } from "../../components/core/EntityTable";
import { AuthenticatedLayout } from "../../components/layouts/AuthenticatedLayout";
import { FeeMatrix3PControllerService } from "../../openapi";

export const FeeMatrix3p = () => {
  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <EntityTable
        title="Fee Matrix 3p"
        service={FeeMatrix3PControllerService.getFeeMatrices3P}
        endpoint="fee-matrix-3p"
        columns={[
          {
            label: "Booking Center",
            id: "bookingCenter.text",
            numeric: false,
          },
          {
            label: "Charge Model",
            id: "chargeModel.text",
            numeric: false,
          },
          {
            label: "Currency",
            id: "currency.text",
            numeric: false,
          },
          {
            label: "Etd Category",
            id: "etdCategory.text",
            numeric: false,
          },
          {
            label: "Market Place",
            id: "marketPlace.text",
            numeric: false,
          },
          {
            label: "Pricing Model",
            id: "pricingModel.text",
            numeric: false,
          },
          {
            label: "source",
            id: "soruce",
            numeric: false,
          },
          {
            label: "Per lot",
            id: "perLot",
            numeric: true,
          },
          {
            label: "Rate",
            id: "rate",
            numeric: true,
          },
          {
            label: "BS Remark",
            id: "bsRemark",
            numeric: false,
          },
          {
            label: "IT Remark",
            id: "itRemark",
            numeric: false,
          },
        ]}
      />
    </AuthenticatedLayout>
  );
};
