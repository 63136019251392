import React from "react";
import { Box, Grid, Typography, TextField } from "@mui/material";
import { StyledDiv, StyledSection } from "./index.style";
import { Card } from "../../../../components/core/Card";
import { Header } from "../../../../components/core/Header";
import logo from "../../../../assets/images/logo-output.png";
import { ButtonWrapper } from "../../index.style";
import DownloadOutlined from "@mui/icons-material/DownloadOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  selectIsFetchingMultipleOutput,
  selectMultipleOutput,
} from "../../../../features/multipleOutput/multipleOutput.selectors";
import { useSelector } from "react-redux";
import { Spinner } from "../../../../components/core/Spinner";
import { MultipleSimulationFormInputs } from "../MultipleSimulationPanel/MultipleSimulationForm/types/MultipleSimulationFormInputs";
import { useFormContext } from "react-hook-form";
import { useUser } from "hooks/useUser";
import { downloadPDF } from "utils/pdfUtils";
import Button from "components/core/Button";
import env from "../../../../config/env";

type OutputPanelProps = {
  hide: boolean;
  handleBack: () => void;
};

export const MultipleOutputPanel = (props: OutputPanelProps) => {
  const { hide, handleBack } = props;

  const multipleOutput = useSelector(selectMultipleOutput);
  const multipleOutputIsFetching = useSelector(selectIsFetchingMultipleOutput);

  const { getValues } = useFormContext<MultipleSimulationFormInputs>();
  const bookingCenter = getValues("bookingCenter");

  const bcIsNotLUX = bookingCenter !== "LUX";

  const { token } = useUser();

  const url = `${env.REACT_APP_API_URL}/api/pdf/download?reportName=${multipleOutput?.reportName}&type=MULTIPLE`;

  const handleDownloadPdf = () =>
    downloadPDF(url, token, multipleOutput?.reportName);

  if (multipleOutputIsFetching) {
    return <Spinner width="100vw" height="calc(100vh - 264px)" />;
  }

  if (!multipleOutput) {
    return <></>;
  }

  const {
    coverHeader,
    portfolioNumber,
    coverText,
    coverSection12,
    portfolioNumberLabel,
    pricingModelLabel,
    pricingModelValue1,
    pricingModelValue2,
    pricingModelValue3,
    pricingModelValue4,
    specialConditionTypeLabel,
    specialConditionTypeValue1,
    specialConditionTypeValue2,
    specialConditionTypeValue3,
    specialConditionTypeValue4,
    specialConditionAmountLabel,
    specialConditionAmountValue1,
    specialConditionAmountValue2,
    specialConditionAmountValue3,
    specialConditionAmountValue4,
    minimumFeeLabel,
    minimumFeeValue1,
    minimumFeeValue2,
    minimumFeeValue3,
    minimumFeeValue4,
    coverDisclaimer,
    bankName,
    leftVerticalSign,
  } = multipleOutput;
  const newLeftVerticalSign = leftVerticalSign?.split("@")[0];
  return (
    <Card
      title="Output"
      Component={StyledSection}
      style={{ width: "100%", display: hide ? "none" : "block" }}
      actions={
        <ButtonWrapper>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleDownloadPdf}
          >
            Download PDF
            <DownloadOutlined />
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleBack}>
            <ArrowBackIcon /> Back
          </Button>
        </ButtonWrapper>
      }
    >
      <Grid item xs={12} sx={{ ml: "-20px" }}>
        <StyledDiv>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Box
              component="img"
              sx={{
                width: 250,
                color: "#001489",
                mt: "-50px",
              }}
              alt="Julius Baer logo"
              src={logo}
            />
          </Grid>
          <Grid item xs={12} marginTop={5}>
            <Header title={coverHeader ?? ""} smallMarginBottom />

            <Grid container marginTop={4}>
              <Grid item xs={4}>
                <Typography>
                  <strong>{portfolioNumberLabel}</strong>
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography>{portfolioNumber}</Typography>
              </Grid>
            </Grid>

            <Grid container marginTop={4}>
              <Typography variant="body2">{coverText}</Typography>
            </Grid>

            <Grid item xs={12} marginTop={4}>
              <Typography>
                <strong>{coverSection12}</strong>
              </Typography>
            </Grid>

            <Grid container marginTop={2}>
              <Grid container item xs={12} columnSpacing={2} rowSpacing={1}>
                <Grid item xs={4}>
                  <Typography>{pricingModelLabel}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>{pricingModelValue1}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>{pricingModelValue2}</Typography>
                </Grid>
              </Grid>

              <Grid item container xs={12} columnSpacing={2} rowSpacing={1}>
                <Grid item container xs={8} rowSpacing={1}>
                  <Grid item xs={6} display="flex" alignItems="center">
                    <Typography>{specialConditionTypeLabel}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={specialConditionTypeValue1}
                      size="small"
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6} display="flex" alignItems="center">
                    <Typography>{specialConditionAmountLabel}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={specialConditionAmountValue1}
                      size="small"
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6} display="flex" alignItems="center">
                    <Typography>{minimumFeeLabel}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={minimumFeeValue1}
                      size="small"
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                {bcIsNotLUX && (
                  <Grid item container xs={4} columnSpacing={2} rowSpacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        value={specialConditionTypeValue2}
                        size="small"
                        variant="filled"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        value={specialConditionAmountValue2}
                        size="small"
                        variant="filled"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        value={minimumFeeValue2}
                        size="small"
                        variant="filled"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} marginTop={4}>
              <Typography>
                <strong>Futures: </strong>
              </Typography>
            </Grid>

            <Grid container marginTop={2} columnSpacing={2} rowSpacing={1}>
              <Grid item xs={4}>
                <Typography>{pricingModelLabel}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>{pricingModelValue3}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>{pricingModelValue4}</Typography>
              </Grid>

              <Grid item container xs={12} columnSpacing={2} rowSpacing={1}>
                <Grid item container xs={8} rowSpacing={1}>
                  <Grid item xs={6} display="flex" alignItems="center">
                    <Typography>{specialConditionTypeLabel}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={specialConditionTypeValue3}
                      size="small"
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6} display="flex" alignItems="center">
                    <Typography>{specialConditionAmountLabel}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={specialConditionAmountValue3}
                      size="small"
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6} display="flex" alignItems="center">
                    <Typography>{minimumFeeLabel}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={minimumFeeValue3}
                      size="small"
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                {bcIsNotLUX && (
                  <Grid item container xs={4} columnSpacing={2} rowSpacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        value={specialConditionTypeValue4}
                        size="small"
                        variant="filled"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        value={specialConditionAmountValue4}
                        size="small"
                        variant="filled"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        value={minimumFeeValue4}
                        size="small"
                        variant="filled"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid container marginTop={8}>
              <Typography variant="body2">{coverDisclaimer}</Typography>
            </Grid>

            <Grid container>
              <Grid
                container
                marginTop={5}
                marginBottom={-8}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography sx={{ marginBottom: "30px" }} color="primary">
                  {bankName}
                </Typography>
              </Grid>
              <div
                style={{
                  position: "relative",
                  width: "20px",
                  minHeight: "33px;",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    transform: "translateX(-50%) rotate(270deg)",
                    minHeight: "100px",
                    minWidth: "350px",
                    whiteSpace: "nowrap",
                    left: "-100%",
                    bottom: "169px",
                  }}
                >
                  {newLeftVerticalSign?.replace(/\\/g, "")}
                </span>
              </div>
            </Grid>
          </Grid>
        </StyledDiv>
      </Grid>
    </Card>
  );
};
