import { takeLatest, put } from "redux-saga/effects";
import {
  fetchSingleOutput,
  FetchSingleOutputAction,
  fetchSingleOutputSuccess,
  SingleOutput,
} from "./singleOutput.reducer";
import { OutputControllerService } from "../../openapi";
import authorizedCall from "../../utils/authorizedCall";

export function* fetchSingleOutputSaga(action: FetchSingleOutputAction) {
  const input = action.payload;

  let response: SingleOutput = yield authorizedCall(
    OutputControllerService.output,
    {
      requestBody: {
        bookingCenter: input.bookingCenter,
        etdCategory: input.etdCategory,
        grossPremium: input.transactionVolume1 as unknown as number,
        bookingCenterRateCardCurrency: input.bookingCenterRateCardCurrency,
        instrumentSecurityCurrency: input.instrumentSecurityCurrency,
        languageId: input.languageId,
        noSimulation3rdPartyCharges: input.simulateThirdPartyCharges === "no",
        remarks: input.remarks,
        specialConditions: input.specialConditionsType,
        thirdPartyChargesModel: input.thirdPartyChargesModel,
        total3rdPartyCharges: input.totalThirdCharges,
        totalFee: input.totalFee,
        userId: "{useremail}",
        portfolioNo: input.portfolioNo,
        instrumentType: input.instrumentType,
        underlyingFullName: input.underlyingFullName,
        tickerIsinOfUnderlying: input.tickerIsinOfUnderlying,
        expiry: input.expiry,
        instrumentShortName: input.instrumentShortName,
        instrumentSecurityNumber: input.instrumentSecurityNumber,
        stockExchangeIndicative: input.stockExchangeIndicative,
        strike: input.strike,
        contractLotSize: input.contractLotSize,
        exchangeRate: input.exchangeRate,
        transaction: input.transaction,
        direction: input.direction,
        quantity: input.quantity,
        premiumPerContractLot: input.premiumPerContractLot,
        premiumDirection: input.premiumDirection,
        transactionVolume1: input.transactionVolume1,
        totalTransactionFeeInstrumentCurrency:
          input.totalTransactionFeeInstrumentCurrency,
      },
    },
    true
  );

  response = {
    ...response,
    ...input,
  };

  yield put(fetchSingleOutputSuccess({ output: response }));
}

export default function* rootSingleOutputSaga() {
  yield takeLatest(fetchSingleOutput.type, fetchSingleOutputSaga);
}
