import { takeLatest, put } from "redux-saga/effects";
import {
  fetchTransactions,
  fetchTransactionsSuccess,
} from "./transactions.reducer";
import { TransactionControllerService, TransactionDto } from "../../openapi";
import authorizedCall from "../../utils/authorizedCall";

export function* fetchTransactionsSaga() {
  const response: TransactionDto[] = yield (
    authorizedCall(TransactionControllerService.getTransactions, {
      page: 0,
      size: 10,
    })
  );
  yield put(fetchTransactionsSuccess({ transactions: response }));
}

export default function* rootTransactionsSaga() {
  yield takeLatest(fetchTransactions.type, fetchTransactionsSaga);
}
