import { RootState } from "../../app/store";
import { DirectionDto } from "../../openapi";

export const selectIsFetchingDirections = (state: RootState): boolean =>
  state.dReducer.isFetching;
export const selectDirections = (state: RootState): DirectionDto[] =>
  state.dReducer.directions;
export const selectSelectedDirection = (
  state: RootState
): DirectionDto | undefined => state.dReducer.selected;
