import React, { useMemo } from "react";
import { Card } from "../Card";
import { Container, Grid } from "@mui/material";
import { StyledSection, ButtonWrapper, StyledButton } from "./index.style";
import { Table, TableEntity } from "../Table";
import { SearchBar } from "../SearchBar";
import { useUser } from "../../../hooks/useUser";
import useUserPermissions from "../../../hooks/useUserPermissions";
import { hasRoutePermission } from "../../../utils/userPermissionUtils";

export type EntityTableProps = {
  title: string;
  endpoint: string;
  service: any;
  columns: {
    label: string;
    id: string;
    numeric: boolean;
  }[];
};

export const EntityTable = (props: EntityTableProps) => {
  const [entities, setEntities] = React.useState<TableEntity[]>([]);
  const [editEnabled, setEditEnabled] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<TableEntity | undefined>();
  const [loadedPages, setLoadedPages] = React.useState<number[]>([]);
  const [page, setPage] = React.useState<number>(0);
  const [size, setSize] = React.useState<number>(10);
  const [sort, setSort] = React.useState<string>("id");
  const [direction, setDirection] = React.useState<"asc" | "desc">("asc");
  const [search, setSearch] = React.useState<string>("");
  const [totalEntities, setTotalEntities] = React.useState<number>(0);
  const { title, endpoint, columns } = props;

  const { service } = props;
  const { token } = useUser();
  const { userPermissions } = useUserPermissions();

  const canEditEntity = useMemo(
    () => hasRoutePermission(userPermissions, `/table/${endpoint}/edit`),
    [endpoint, userPermissions]
  );

  React.useEffect(() => {
    if (loadedPages.includes(page)) {
      return;
    }

    const params: any = {
      authorization: `Bearer ${token}`,
      size,
      page,
    };

    if (sort !== "id") {
      params.sort = sort + "," + direction;
    }

    if (search !== "") {
      params.search = search;
    }

    if (token) {
      service(params).then((data: any) => {
        if (
          totalEntities === 0 ||
          totalEntities !== data._embedded?.entities?.length
        ) {
          setTotalEntities(data.page.totalElements);
        }

        setEntities([...entities, ...(data._embedded?.entities ?? [])]);

        loadedPages.push(page);
        setLoadedPages(loadedPages);
      }).catch((error: any) => {
        console.log(error);
      });
    }
  }, [
    direction,
    endpoint,
    entities,
    loadedPages,
    page,
    search,
    service,
    size,
    sort,
    token,
    totalEntities,
  ]);

  const onEditClicked = () => {
    window.location.href += "/edit/" + selected?.id;
  };

  const onSelected = (newSelected: TableEntity, enabled: boolean) => {
    setEditEnabled(enabled);
    setSelected(newSelected);
  };

  const reset = () => {
    setPage(0);
    setEntities([]);
    setLoadedPages([]);
  };

  const onSizeChanged = (pageSize: number) => {
    setSize(pageSize);
    reset();
  };

  const onSortChanged = (newSort: string, newDirection: "asc" | "desc") => {
    setSort(newSort);
    setDirection(newDirection);
    reset();
  };

  const onSearch = (searched: string) => {
    setSearch(searched);
    reset();
  };

  return (
    <React.Fragment>
      <Card
        title={title}
        Component={StyledSection}
        style={{ width: "100%" }}
        actions={
          <React.Fragment>
            <div style={{ marginRight: "15px" }}>
              <SearchBar setSearchQuery={onSearch} />
            </div>
            <ButtonWrapper>
              <StyledButton
                variant={"contained"}
                disabled={!editEnabled || !canEditEntity}
                onClick={onEditClicked}
              >
                Edit
              </StyledButton>
            </ButtonWrapper>
          </React.Fragment>
        }
      >
        <Grid item xs={12}>
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              <Table
                entities={entities}
                columns={columns}
                onSelected={onSelected}
                onPageChange={setPage}
                onSizeChange={onSizeChanged}
                totalEntities={totalEntities}
                onSortChanged={onSortChanged}
                orderDefault={direction}
                orderByDefault={sort}
              />
            </Grid>
          </Container>
        </Grid>
      </Card>
    </React.Fragment>
  );
};
