import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import { AuthenticatedLayout } from "../../components/layouts/AuthenticatedLayout";
import { EditFeeMatrix3pForm } from "./components/EditFeeMatrix3pForm";

export const EditFeeMatrix3p = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      navigate("/");
    }
  }, [id, navigate]);

  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <EditFeeMatrix3pForm id={id} />
    </AuthenticatedLayout>
  );
};
