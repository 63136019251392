import { takeLatest, put } from "redux-saga/effects";
import {
  fetchInstruments,
  FetchInstrumentsAction,
  fetchInstrumentsForMultipleSimulation,
  FetchInstrumentsForMultipleSimulationAction,
  fetchInstrumentsSuccess,
} from "./instruments.reducer";
import {
  InstrumentControllerService,
  EtdCategoryControllerService,
  EntityModelInstrumentDto,
} from "../../openapi";
import authorizedCall from "../../utils/authorizedCall";

export function* fetchInstrumentsSaga(action: FetchInstrumentsAction) {
  const response: EntityModelInstrumentDto[] = yield authorizedCall(
    InstrumentControllerService.getInstruments,
    {
      page: 0,
      size: 10,
      ...action.payload,
    }
  );
  yield put(fetchInstrumentsSuccess({ instruments: response }));
}

export function* fetchInstrumentsForMultipleSimulationSaga(
  action: FetchInstrumentsForMultipleSimulationAction
) {
  const response: EntityModelInstrumentDto[] = yield authorizedCall(
    EtdCategoryControllerService.getInstruments1,
    {
      categoryId: action.payload.categoryId,
      pricingModelId: action.payload.pricingModelId,
      transactionId: action.payload.transactionId,
      instrumentTypeId: action.payload.instrumentTypeId,
      directionId: action.payload.directionId,
      size: 10,
      page: 0,
    }
  );
  yield put(fetchInstrumentsSuccess({ instruments: response }));
}

export default function* rootInstrumentsSaga() {
  yield takeLatest(fetchInstruments.type, fetchInstrumentsSaga);
  yield takeLatest(
    fetchInstrumentsForMultipleSimulation.type,
    fetchInstrumentsForMultipleSimulationSaga
  );
}
