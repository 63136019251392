import { Box, Container, Grid } from "@mui/material";
import type { ReactNode } from "react";

export interface PageLayoutProps {
  children: ReactNode;
}

export const PageLayout = (props: PageLayoutProps) => {
  const { children } = props;

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Container maxWidth="xl" sx={{ mt: 16, mb: 4 }}>
          <Grid container spacing={3}>
            {children}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
