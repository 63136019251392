/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedModelEntityModelBookingCenter } from "../models/PagedModelEntityModelBookingCenter";
import type { PagedModelEntityModelChargeModelDto } from "../models/PagedModelEntityModelChargeModelDto";
import type { PagedModelEntityModelFfeMatrixBjbDto } from "../models/PagedModelEntityModelFfeMatrixBjbDto";
import type { PagedModelEntityModelMarketPlaceDto } from "../models/PagedModelEntityModelMarketPlaceDto";
import type { PagedModelEntityModelPricingDto } from "../models/PagedModelEntityModelPricingDto";
import type { PagedModelEntityModelPricingModelDto } from "../models/PagedModelEntityModelPricingModelDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import env from "../../config/env";

export class BookingCenterControllerService {
  /**
   * It will return a paged list of the managed Booking Centers
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param authorization
   * @param code
   * @param currency
   * @param id
   * @param text
   * @returns PagedModelEntityModelBookingCenter Fee type matrix list paged and filtered by the given mandatory and optional fields
   * @throws ApiError
   */
  public static getBookingCenters(input: {
    sort?: Array<string>;
    code?: string;
    currency?: string;
    id?: number;
    text?: string;
    page?: number;
    size: number;
    authorization?: string;
  }): CancelablePromise<PagedModelEntityModelBookingCenter> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/booking-centers",
      headers: {
        Authorization: input.authorization,
      },
      query: {
        page: input.page,
        size: input.size,
        sort: input.sort,
        code: input.code,
        currency: input.currency,
        id: input.id,
        text: input.text,
      },
      errors: {
        403: `Your role must have read grants to fee type matrix table`,
      },
    });
  }

  /**
   * It will return a paged list of the Pricing filtered by a booking center id
   * @returns PagedModelEntityModelPricingDto Pricing list paged and filtered by the given mandatory and optional fields
   * @throws ApiError
   * @param input
   */
  public static getPricings(input: {
    bookingCenterId: number;
    sort?: Array<string>;
    comments?: string;
    bookingCenter?: string;
    id?: number;
    pricing?: string;
    page?: number;
    size: number;
    authorization?: string;
  }): CancelablePromise<PagedModelEntityModelPricingDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/booking-centers/{bookingCenterId}/pricings",
      path: {
        bookingCenterId: input.bookingCenterId,
      },
      headers: {
        Authorization: input.authorization,
      },
      query: {
        page: input.page,
        size: input.size,
        sort: input.sort,
        comments: input.comments,
        bookingCenter: input.bookingCenter,
        id: input.id,
        pricing: input.pricing,
      },
      errors: {
        403: `Your role must have read grants to Pricing table`,
      },
    });
  }

  /**
   * It will return a paged list of the managed Pricing Models filtered by a booking center id
   * @param bookingCenterId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param authorization
   * @param pricingModel
   * @param bookingCenter
   * @param id
   * @returns PagedModelEntityModelPricingModelDto Pricing Models list paged and filtered by the given mandatory and optional fields
   * @throws ApiError
   */
  public static getPricingModels(input: {
    bookingCenterId: number;
    sort?: Array<string>;
    pricingModel?: string;
    bookingCenter?: string;
    id?: number;
    page?: number;
    size: number;
    authorization?: string;
  }): CancelablePromise<PagedModelEntityModelPricingModelDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/booking-centers/{bookingCenterId}/pricing-models",
      path: {
        bookingCenterId: input.bookingCenterId,
      },
      headers: {
        Authorization: input.authorization,
      },
      query: {
        page: input.page,
        size: input.size,
        sort: input.sort,
        pricingModel: input.pricingModel,
        bookingCenter: input.bookingCenter,
        id: input.id,
      },
      errors: {
        403: `Your role must have read grants to relationship booking center to pricing model`,
      },
    });
  }

  /**
   * It will return a paged list of the FFE Matrix BJB filtered by a booking center id, an etd category id and a pricing model id
   * @param bookingCenterId
   * @param pricingModelId
   * @param categoryId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param authorization
   * @param pricingModel
   * @param amount
   * @param bookingCenter
   * @param etdCategory
   * @param id
   * @param feeClass
   * @returns PagedModelEntityModelFfeMatrixBjbDto FFE Matrix BJB list paged and filtered by the given mandatory and optional fields
   * @throws ApiError
   */
  public static getFfeMatrixBjb(input: {
    bookingCenterId: number;
    pricingModelId: number;
    categoryId: number;
    page?: number;
    size: number;
    sort?: Array<string>;
    pricingModel?: string;
    amount?: number;
    bookingCenter?: string;
    etdCategory?: string;
    id?: number;
    feeClass?: string;
    authorization?: string;
  }): CancelablePromise<PagedModelEntityModelFfeMatrixBjbDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/booking-centers/{bookingCenterId}/pricing-models/{pricingModelId}/categories/{categoryId}/ffe-matrix-bjb",
      path: {
        bookingCenterId: input.bookingCenterId,
        pricingModelId: input.pricingModelId,
        categoryId: input.categoryId,
      },
      headers: {
        Authorization: input.authorization,
      },
      query: {
        page: input.page,
        size: input.size,
        sort: input.sort,
        pricingModel: input.pricingModel,
        amount: input.amount,
        bookingCenter: input.bookingCenter,
        etdCategory: input.etdCategory,
        id: input.id,
        feeClass: input.feeClass,
      },
      errors: {
        403: `Your role must have read grants to FfeMatrixBjb table`,
      },
    });
  }

  /**
   * It will return a paged list of the Charge Models filtered by a booking center id, an etd category id and a pricing model id
   * @param bookingCenterId
   * @param pricingModelId
   * @param categoryId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param authorization
   * @param pricingModel
   * @param chargeModel
   * @param bookingCenter
   * @param etdCategory
   * @param id
   * @returns PagedModelEntityModelChargeModelDto Charge Models list paged and filtered by the given mandatory and optional fields
   * @throws ApiError
   */
  public static getChargeModels(input: {
    bookingCenterId: number;
    pricingModelId: number;
    categoryId: number;
    sort?: Array<string>;
    pricingModel?: string;
    chargeModel?: string;
    bookingCenter?: string;
    etdCategory?: string;
    id?: number;
    page?: number;
    size: number;
    authorization?: string;
  }): CancelablePromise<PagedModelEntityModelChargeModelDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/booking-centers/{bookingCenterId}/pricing-models/{pricingModelId}/categories/{categoryId}/charge-models",
      path: {
        bookingCenterId: input.bookingCenterId,
        pricingModelId: input.pricingModelId,
        categoryId: input.categoryId,
      },
      headers: {
        Authorization: input.authorization,
      },
      query: {
        page: input.page,
        size: input.size,
        sort: input.sort,
        pricingModel: input.pricingModel,
        chargeModel: input.chargeModel,
        bookingCenter: input.bookingCenter,
        etdCategory: input.etdCategory,
        id: input.id,
      },
      errors: {
        403: `Your role must have read grants to relationship BC_TO_EC_TO_PM_TO_CM table`,
      },
    });
  }

  /**
   * It will return a paged list of the Market Places filtered by a booking center id, an etd category id, a pricing model id, a charge model id and a currency id
   * @param bookingCenterId
   * @param pricingModelId
   * @param categoryId
   * @param chargeModelId
   * @param currencyId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param authorization
   * @param pricingModel
   * @param chargeModel
   * @param rate
   * @param marketPlace
   * @param perLot
   * @param bookingCenter
   * @param etdCategory
   * @param currency
   * @param id
   * @param source
   * @returns PagedModelEntityModelMarketPlaceDto Market Places list paged and filtered by the given mandatory and optional fields
   * @throws ApiError
   */
  public static getMarketPlaces(input: {
    bookingCenterId: number;
    pricingModelId: number;
    categoryId: number;
    chargeModelId: number;
    currencyId: number;
    page?: number;
    size: number;
    sort?: Array<string>;
    pricingModel?: string;
    chargeModel?: string;
    rate?: number;
    marketPlace?: string;
    perLot?: number;
    bookingCenter?: string;
    etdCategory?: string;
    currency?: string;
    id?: number;
    source?: string;
    authorization?: string;
  }): CancelablePromise<PagedModelEntityModelMarketPlaceDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/booking-centers/{bookingCenterId}/pricing-models/{pricingModelId}/categories/{categoryId}/charge-models/{chargeModelId}/currencies/{currencyId}/market-places",
      path: {
        bookingCenterId: input.bookingCenterId,
        pricingModelId: input.pricingModelId,
        categoryId: input.categoryId,
        chargeModelId: input.chargeModelId,
        currencyId: input.currencyId,
      },
      headers: {
        Authorization: input.authorization,
      },
      query: {
        page: input.page,
        size: input.size,
        sort: input.sort,
        pricingModel: input.pricingModel,
        chargeModel: input.chargeModel,
        rate: input.rate,
        marketPlace: input.marketPlace,
        perLot: input.perLot,
        bookingCenter: input.bookingCenter,
        etdCategory: input.etdCategory,
        currency: input.currency,
        id: input.id,
        source: input.source,
      },
      errors: {
        403: `Your role must have read grants to FeeMatrix3P table`,
      },
    });
  }

  /**
   * It will return a paged list of the managed Charge Models filtered by a booking center id and the fixed Pricing Model "Non-Eurex"
   * @param input
   * @returns PagedModelEntityModelChargeModelDto Charge Models list paged and filtered by the given mandatory and optional fields
   * @throws ApiError
   */
  public static getChargeModelsNonEurex(input: {
    bookingCenterId: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
    authorization?: string;
    pricingModel?: string;
    chargeModel?: string;
    bookingCenter?: string;
    etdCategory?: string;
    id?: number;
  }): CancelablePromise<PagedModelEntityModelChargeModelDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/booking-centers/{bookingCenterId}/pricing-models/non-eurex/charge-models",
      path: {
        bookingCenterId: input.bookingCenterId,
      },
      headers: {
        Authorization: input.authorization,
      },
      query: {
        page: input.page ?? 0,
        size: input.size ?? 10,
        sort: input.sort,
        pricingModel: input.pricingModel,
        chargeModel: input.chargeModel,
        bookingCenter: input.bookingCenter,
        etdCategory: input.etdCategory,
        id: input.id,
      },
      errors: {
        403: `Your role must have read grants to relationship BookingCenterToEtdCategoryToPricingModelToChargeModel`,
      },
    });
  }
}
