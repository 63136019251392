import { Grid, InputAdornment } from "@mui/material";
import { FormFieldLabel } from "../../../../../../components/hook-form/FormFieldLabel";
import { FormSelect } from "../../../../../../components/hook-form/FormSelect";
import { Header } from "../../../../../../components/core/Header";
import { Content } from "../../../../../../components/core/Content";
import { useFormContext, useWatch } from "react-hook-form";
import { SingleSimulationFormInputs } from "../types/SingleSimulationFormInputs";
import { useAppSelector } from "../../../../../../hooks/useAppSelector";
import {
  selectIsFetchingSpecialConditionTypes,
  selectSpecialConditionTypes,
} from "../../../../../../features/specialConditionTypes/specialConditionTypes.selectors";
import { mapEntitiesWithNoCodeToFormSelectOptions } from "../../../../../../utils/formUtils";
import { useAppDispatch } from "../../../../../../hooks/useAppDispatch";
import {
  fetchSpecialConditionTypes,
  specialConditionTypeSelected,
} from "../../../../../../features/specialConditionTypes/specialConditionTypes.reducer";
import { selectSelectedEtdCategory } from "../../../../../../features/etdCategories/etdCategories.selectors";
import { selectSelectedCurrency } from "../../../../../../features/currencies/currencies.selectors";
import { selectSelectedBookingCenter } from "../../../../../../features/bookingCenters/bookingCenters.selectors";
import { FormCurrencyInput } from "components/hook-form/FormCurrencyInput";
import React from "react";

export const SpecialConditionsFields = () => {
  const { control, setValue } = useFormContext<SingleSimulationFormInputs>();
  const dispatch = useAppDispatch();

  const specialConditionTypes = useAppSelector(selectSpecialConditionTypes);
  const isFetchingSpecialConditionTypes = useAppSelector(
    selectIsFetchingSpecialConditionTypes
  );
  const selectedConditionType = useWatch({
    control,
    name: "specialConditionsType",
  });
  const enabledFields = selectedConditionType && selectedConditionType !== "no";
  const selectedEtdCategory = useAppSelector(selectSelectedEtdCategory);
  const quantity = useWatch({ control, name: "quantity" });
  const selectedCurrency = useAppSelector(selectSelectedCurrency);
  const contractLotSize = useWatch({ control, name: "contractLotSize" });
  const premiumPerContractLot = useWatch({
    control,
    name: "premiumPerContractLot",
  });
  const selectedBookingCenter = useAppSelector(selectSelectedBookingCenter);
  const exchangeRate = useWatch({ control, name: "exchangeRate" });
  const transactionVolume1 = useWatch({ control, name: "transactionVolume1" });
  const standardTransactionFee = useWatch({
    control,
    name: "standardTransactionFee",
  });
  const bookingCenter = useWatch({
    control,
    name: "bookingCenter",
  });
  const totalTransactionFeeInstrumentCurrency = useWatch({
    control,
    name: "totalTransactionFeeInstrumentCurrency",
  });
  const totalFee = useWatch({ control, name: "totalFee" });

  React.useEffect(() => {
    if (!selectedConditionType || selectedConditionType === "no") {
      setValue("specialConditionsAmount", 0);
      setValue("noStandardMinimumFee", 0);
    }
  }, [selectedConditionType, setValue]);

  React.useEffect(() => {
    if (selectedEtdCategory) {
      dispatch(
        fetchSpecialConditionTypes({ categoryId: selectedEtdCategory.id })
      );
    } else {
      dispatch(fetchSpecialConditionTypes({}));
    }
  }, [dispatch, selectedEtdCategory]);

  React.useEffect(() => {
    if (isFetchingSpecialConditionTypes || specialConditionTypes.length === 0) {
      return;
    }

    const selected = specialConditionTypes.find(
      (s) => s.text?.toLowerCase() === "no"
    );

    setValue("specialConditionsType", selected?.text ?? "");
    dispatch(specialConditionTypeSelected(selected));
  }, [
    specialConditionTypes,
    isFetchingSpecialConditionTypes,
    setValue,
    dispatch,
  ]);

  return (
    <>
      <Content>
        <Header title="Special Conditions Fields" />
      </Content>
      <Grid item xs={6} md={4}>
        <FormSelect
          name="specialConditionsType"
          label={
            <FormFieldLabel isRequired>Special Conditions Type</FormFieldLabel>
          }
          control={control}
          fullWidth
          autoComplete="off"
          options={mapEntitiesWithNoCodeToFormSelectOptions(
            specialConditionTypes
          )}
          rules={{
            required: "Special Conditions Type is Required.",
          }}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <FormCurrencyInput
          name="specialConditionsAmount"
          label={<FormFieldLabel>Special Condition Amount</FormFieldLabel>}
          control={control}
          autoComplete="off"
          fullWidth
          disabled={!enabledFields}
          InputProps={{ inputProps: { min: 0, step: 1 } }}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <FormCurrencyInput
          name="noStandardMinimumFee"
          label={<FormFieldLabel>(non standard) Minimum Fee</FormFieldLabel>}
          control={control}
          autoComplete="off"
          fullWidth
          disabled={!enabledFields}
          InputProps={{ inputProps: { min: 0, step: 1 } }}
        />
      </Grid>
      {!!quantity &&
        !!contractLotSize &&
        !!premiumPerContractLot &&
        selectedCurrency && (
          <Grid item xs={6} md={6}>
            <FormCurrencyInput
              name="transactionVolume1"
              label="Transaction Volume"
              variant="filled"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {selectedCurrency.code}
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              fullWidth
              sx={{ mt: "10px" }}
            />
          </Grid>
        )}
      {!!exchangeRate && transactionVolume1 && selectedCurrency && (
        <Grid item xs={6} md={6}>
          <FormCurrencyInput
            name="transactionVolume2"
            label="Transaction Volume"
            variant="filled"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {selectedBookingCenter?.currency?.code ?? ""}
                </InputAdornment>
              ),
              readOnly: true,
            }}
            fullWidth
            sx={{ mt: "10px" }}
          />
        </Grid>
      )}
      {standardTransactionFee &&
      selectedEtdCategory &&
      selectedBookingCenter ? (
        <Grid item xs={6} md={6}>
          <FormCurrencyInput
            name={"standardTransactionFee"}
            label={"Standard Transaction Fee"}
            variant="filled"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {selectedBookingCenter.currency?.code}
                </InputAdornment>
              ),
              readOnly: true,
            }}
            fullWidth
            sx={{ mt: "10px" }}
          />
        </Grid>
      ) : (
        <React.Fragment />
      )}
      {!isNaN(totalTransactionFeeInstrumentCurrency) && bookingCenter ? (
        <Grid item xs={6} md={6}>
          <FormCurrencyInput
            name={"totalTransactionFeeInstrumentCurrency"}
            label={"Total Transaction Fee (Instr. curr.)"}
            variant="filled"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {selectedCurrency?.code}
                </InputAdornment>
              ),
              readOnly: true,
            }}
            fullWidth
            sx={{ mt: "10px" }}
          />
        </Grid>
      ) : (
        <React.Fragment />
      )}
      {totalFee && selectedCurrency ? (
        <Grid item md={12}>
          <FormCurrencyInput
            name="totalFee"
            label="Total Fee"
            variant="filled"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {selectedCurrency.code}
                </InputAdornment>
              ),
              readOnly: true,
            }}
            fullWidth
            sx={{ mt: "10px" }}
          />
        </Grid>
      ) : (
        <React.Fragment />
      )}
    </>
  );
};
