import { useEffect } from "react";
import { languageSelected } from "../languages.reducer";
import { useAppSelector } from "../../../hooks/useAppSelector";
import {
  selectIsFetchingLanguages,
  selectLanguages,
} from "../languages.selectors";
import { useFormContext } from "react-hook-form";
import { useAppDispatch } from "../../../hooks/useAppDispatch";

export const useAutoSelectEN = () => {
  const { setValue } = useFormContext();
  const languages = useAppSelector(selectLanguages);
  const isFetchingLanguages = useAppSelector(selectIsFetchingLanguages);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isFetchingLanguages || languages.length === 0) {
      return;
    }

    const selected = languages.find((l) => l.code?.toLowerCase() === "en");

    setValue("outputLanguage", selected?.code ?? "");
    dispatch(languageSelected(selected));
  }, [languages, isFetchingLanguages, setValue, dispatch]);
};
