import React from "react";
import { InputAdornment } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { useAppSelector } from "../../../../../../hooks/useAppSelector";
import { selectFfeMatrices } from "../../../../../../features/ffeMatrices/ffeMatrices.selectors";
import { SingleSimulationFormInputs } from "../../SingleSimulationForm/types/SingleSimulationFormInputs";
import { selectSelectedCurrency } from "../../../../../../features/currencies/currencies.selectors";
import { selectSelectedBookingCenter } from "../../../../../../features/bookingCenters/bookingCenters.selectors";
import { FormCurrencyInput } from "components/hook-form/FormCurrencyInput";

export const TARates = (): React.ReactElement => {
  const { control, setValue } = useFormContext<SingleSimulationFormInputs>();

  const selectedBookingCenter = useAppSelector(selectSelectedBookingCenter);

  const noStandardMinimumFee = useWatch({
    control,
    name: "noStandardMinimumFee",
  });
  const bjbTaRateMinFee = useWatch({ control, name: "bjbTaRateMinFee" });
  const pricingModel = useWatch({ control, name: "pricingModel" });

  const ffeMatrices = useAppSelector(selectFfeMatrices);
  const selectedCurrency = useAppSelector(selectSelectedCurrency);

  React.useEffect(() => {
    if (!ffeMatrices) {
      return;
    }

    const bjb_TA_Rate_min_fee = ffeMatrices.find(
      (fm) => fm?.feeClass?.text.toLowerCase() === "min. Fee".toLowerCase()
    )?.amount as number;
    setValue("bjbTaRateMinFee", bjb_TA_Rate_min_fee);

    const bjb_TA_Rate_band_1 = ffeMatrices.find(
      (fm) => fm?.feeClass?.text.toLowerCase() === "Band_1".toLowerCase()
    )?.amount as number;
    setValue("bjbTaRateBand1", bjb_TA_Rate_band_1);

    const bjb_TA_Rate_band_2 = ffeMatrices.find(
      (fm) => fm?.feeClass?.text.toLowerCase() === "Band_2".toLowerCase()
    )?.amount as number;
    setValue("bjbTaRateBand2", bjb_TA_Rate_band_2);

    const bjb_TA_Rate_band_3 = ffeMatrices.find(
      (fm) => fm?.feeClass?.text.toLowerCase() === "Band_3".toLowerCase()
    )?.amount as number;
    setValue("bjbTaRateBand3", bjb_TA_Rate_band_3);
  }, [ffeMatrices, setValue]);

  React.useEffect(() => {
    if (!noStandardMinimumFee || noStandardMinimumFee === 0) {
      setValue("minFee", bjbTaRateMinFee);
    } else {
      setValue("minFee", noStandardMinimumFee);
    }
  }, [noStandardMinimumFee, bjbTaRateMinFee, setValue]);

  return (
    <React.Fragment>
      {bjbTaRateMinFee && ffeMatrices && selectedCurrency ? (
        <FormCurrencyInput
          name="bjbTaRateMinFee"
          label="BJB_TA_Rate.min.Fee"
          variant="filled"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {selectedBookingCenter?.currency?.code ?? ""}
              </InputAdornment>
            ),
            readOnly: true,
          }}
          fullWidth
          sx={{ mt: "10px" }}
        />
      ) : (
        <React.Fragment />
      )}
      {bjbTaRateMinFee && selectedCurrency ? (
        <FormCurrencyInput
          name="minFee"
          label="min Fee"
          variant="filled"
          InputProps={{
            readOnly: true,
          }}
          fullWidth
          sx={{ mt: "10px" }}
        />
      ) : (
        <React.Fragment />
      )}
      {ffeMatrices.length > 0 && pricingModel ? (
        <FormCurrencyInput
          name="bjbTaRateBand1"
          label="BJB_TA__Rate.Band 1"
          variant="filled"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">{"%"}</InputAdornment>
            ),
            readOnly: true,
          }}
          fullWidth
          sx={{ mt: "10px" }}
        />
      ) : (
        <React.Fragment />
      )}
      {ffeMatrices.length > 0 && pricingModel ? (
        <FormCurrencyInput
          name="bjbTaRateBand2"
          label="BJB_TA__Rate.Band 2"
          variant="filled"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">{"%"}</InputAdornment>
            ),
            readOnly: true,
          }}
          fullWidth
          sx={{ mt: "10px" }}
        />
      ) : (
        <React.Fragment />
      )}
      {ffeMatrices.length > 0 && pricingModel ? (
        <FormCurrencyInput
          name="bjbTaRateBand3"
          label="BJB_TA__Rate.Band 3"
          variant="filled"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">{"%"}</InputAdornment>
            ),
            readOnly: true,
          }}
          fullWidth
          sx={{ mt: "10px" }}
        />
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};
