import React, { useCallback } from "react";
import { Grid, Box } from "@mui/material";
import { ButtonWrapper, StyledButton, StyledSection } from "../../index.style";
import { Card } from "../../../../components/core/Card";
import MultipleSimulationForm from "./MultipleSimulationForm/MultipleSimulationForm";

type MultipleSimulationPanelProps = {
  hide: boolean;
  onCalculate: () => void;
};

export const MultipleSimulationPanel = (
  props: MultipleSimulationPanelProps
): React.ReactElement => {
  const { hide, onCalculate } = props;

  //const { reset } = useFormContext<SingleSimulationFormInputs>();
  /** Should reset all reducers state ? */
  // const handleReset = useCallback(() => reset(), [reset]);
  /**
   * Because of some difficult management between react-hook-form and redux
   * we decided to go for a page reload like PCS
   */
  const handleReset = useCallback(() => window.location.reload(), []);

  return (
    <Card
      title={<Box sx={{ textAlign: "center" }}>ECS Multiple Simulation</Box>}
      Component={StyledSection}
      style={{ width: "100%", display: hide ? "none" : "block" }}
      actions={
        <ButtonWrapper>
          <StyledButton
            variant="outlined"
            color="secondary"
            onClick={handleReset}
          >
            Reset
          </StyledButton>
          <StyledButton
            form="multiple-simulation-form"
            variant="contained"
            color="primary"
            type="submit"
          >
            Calculate ECS
          </StyledButton>
        </ButtonWrapper>
      }
    >
      <Grid item xs={12} sx={{ ml: "-20px" }}>
        <MultipleSimulationForm onValidSubmit={onCalculate} />
      </Grid>
    </Card>
  );
};
