import { takeLatest, put } from "redux-saga/effects";
import {
  fetchBookingCenters,
  fetchBookingCentersSuccess,
} from "./bookingCenters.reducer";
import {
  BookingCenterControllerService,
  EntityModelBookingCenter,
} from "../../openapi";
import authorizedCall from "../../utils/authorizedCall";

export function* fetchBookingCentersSaga() {
  const response: EntityModelBookingCenter[] = yield (
    authorizedCall(BookingCenterControllerService.getBookingCenters, {
      page: 0,
      size: 10,
    })
  );

  yield put(fetchBookingCentersSuccess({ bookingCenters: response }));
}

export default function* rootBookingCentersSaga() {
  yield takeLatest(fetchBookingCenters.type, fetchBookingCentersSaga);
}
