import { RootState } from "../../app/store";
import { TransactionDto } from "../../openapi";

export const selectIsFetchingTransactions = (state: RootState): boolean =>
  state.tReducer.isFetching;
export const selectTransactions = (state: RootState): TransactionDto[] =>
  state.tReducer.transactions;
export const selectSelectedTransaction = (
  state: RootState
): TransactionDto | undefined => state.tReducer.selected;
