import { createSlice } from "@reduxjs/toolkit";
import { TransactionDto } from "../../openapi";

export type TransactionsState = {
  transactions: TransactionDto[];
  selected?: TransactionDto;
  isFetching: boolean;
};

export const INITIAL_STATE: TransactionsState = {
  transactions: [],
  selected: undefined,
  isFetching: false,
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState: INITIAL_STATE,
  reducers: {
    fetchTransactions: (state: TransactionsState) => {
      state.isFetching = true;
    },
    fetchTransactionsSuccess: (state: TransactionsState, action) => {
      state.transactions = action.payload.transactions;
      state.isFetching = false;
    },
    transactionSelected: (state: TransactionsState, action) => {
      state.selected = action.payload;
    },
    resetTransactions: (state: TransactionsState) => {
      state.transactions = [];
    }
  },
});

export const {
  fetchTransactions,
  fetchTransactionsSuccess,
  transactionSelected,
  resetTransactions
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
