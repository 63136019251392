import React from "react";
import Grid, { GridSize } from "@mui/material/Grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { Divider, Button, Typography } from "@mui/material";

export type HeaderProps = {
  title: string | React.ReactNode;
  subtitle?: string;
  size?: GridSize;
  showBackButton?: boolean;
  backLink?: string;
  smallMarginBottom?: boolean;
};

export const Header = (props: HeaderProps): React.ReactElement => {
  const {
    title,
    subtitle,
    size,
    showBackButton,
    backLink,
    smallMarginBottom = false,
  } = props;

  const maybeRenderBackButton = (): React.ReactElement => {
    if (!showBackButton) return <React.Fragment />;

    return (
      <Link to={backLink ?? "/login"} style={{ textDecoration: "none" }}>
        <Button
          sx={{ marginRight: "15px", borderRadius: 0 }}
          variant="outlined"
          color="primary"
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
      </Link>
    );
  };

  return (
    <React.Fragment>
      <Grid item xs={size ?? 12} role="grid">
        <Typography
          variant="h6"
          gutterBottom
          color={"primary"}
          sx={{ height: smallMarginBottom ? "25px" : "40px" }}
        >
          {maybeRenderBackButton()}
          <span id="header-title">{title}</span>
        </Typography>
        {subtitle ? (
          <Typography
            variant="subtitle1"
            gutterBottom
            color={"primary"}
            sx={{ height: smallMarginBottom ? "25px" : "40px" }}
          >
            <span id="header-title">{subtitle}</span>
          </Typography>
        ) : (
          <React.Fragment />
        )}
        <Divider />
      </Grid>
    </React.Fragment>
  );
};

export default Header;
