import { takeLatest, put } from "redux-saga/effects";
import {
  fetchValueBands,
  FetchValueBandsAction,
  fetchValueBandsSuccess,
} from "./valueBands.reducer";
import {
  ValueBandControllerService,
  EntityModelValueBand,
} from "../../openapi";
import authorizedCall from "../../utils/authorizedCall";

export function* fetchValueBandsSaga(action: FetchValueBandsAction) {
  const response: EntityModelValueBand[] = yield (
    authorizedCall(ValueBandControllerService.getValueBands, {
      page: 0,
      size: 10,
      search: action.payload?.search,
    })
  );
  yield put(fetchValueBandsSuccess({ valueBands: response }));
}

export default function* rootValueBandsSaga() {
  yield takeLatest(fetchValueBands.type, fetchValueBandsSaga);
}
