import { createSlice } from "@reduxjs/toolkit";
import { GrantAndFunctionDto } from "../../openapi";

export type GrantFuncsState = {
  grantFuncs: GrantAndFunctionDto[];
  isFetching: boolean;
};

export const INITIAL_STATE: GrantFuncsState = {
  grantFuncs: [],
  isFetching: false,
};

const grantFuncsSlice = createSlice({
  name: "grantFuncs",
  initialState: INITIAL_STATE,
  reducers: {
    fetchGrantFuncs: (state: GrantFuncsState) => {
      state.isFetching = true;
    },
    fetchGrantFuncsSuccess: (state: GrantFuncsState, action) => {
      state.grantFuncs = action.payload.grantFuncs;
      state.isFetching = false;
    },
    resetGrantFuncs: (state: GrantFuncsState) => {
      state.grantFuncs = [];
    },
  },
});

export const { fetchGrantFuncs, fetchGrantFuncsSuccess, resetGrantFuncs } =
  grantFuncsSlice.actions;

export default grantFuncsSlice.reducer;
