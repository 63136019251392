import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import { AuthenticatedLayout } from "../../components/layouts/AuthenticatedLayout";
import { EditFeeMatrixBJBForm } from "./components/EditFeeMatrixBJBForm";

export const EditFeeMatrixBJB = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      navigate("/");
    }
  }, [id, navigate]);

  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <EditFeeMatrixBJBForm id={id} />
    </AuthenticatedLayout>
  );
};
