/* istanbul ignore file */
import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import { all } from "redux-saga/effects";
import createSagaMiddleware from "redux-saga";

import bookingCentersReducer from "../features/bookingCenters/bookingCenters.reducer";
import grantFuncsReducer from "../features/grantFuncs/grantFuncs.reducer";
import pricingModelReducer from "../features/pricingModels/pricingModels.reducer";
import pricingReducer from "../features/pricing/pricing.reducer";
import instrumentsReducer from "../features/instruments/instruments.reducer";
import instrumentTypesReducer from "../features/instrumentTypes/instrumentTypes.reducer";
import languagesReducer from "../features/languages/languages.reducer";
import etdCategoriesReducer from "../features/etdCategories/etdCategories.reducer";
import transactionsReducer from "../features/transactions/transactions.reducer";
import directionsReducer from "../features/directions/directions.reducer";
import currenciesReducer from "../features/currencies/currencies.reducer";
import chargeModelsReducer from "../features/chargeModels/chargeModels.reducer";
import marketPlacesReducer from "../features/marketPlaces/marketPlaces.reducer";
import valueBandsReducer from "../features/valueBands/valueBands.reducer";
import ffeMatricesReducer from "../features/ffeMatrices/ffeMatrices.reducer";
import specialConditionTypesReducer from "../features/specialConditionTypes/specialConditionTypes.reducer";
import feeCodesReducer from "../features/feeCodes/feeCodes.reducer";
import feeMatrices3PReducer from "../features/feeMatrices3P/feeMatrices3P.reducer";
import singleOutputReducer from "../features/singleOutput/singleOutput.reducer";
import multipleOutputReducer from "../features/multipleOutput/multipleOutput.reducer";

import rootBookingCentersSaga from "../features/bookingCenters/bookingCenters.saga";
import rootGrantFuncsSaga from "../features/grantFuncs/grantFuncs.saga";
import rootPricingModelsSaga from "../features/pricingModels/pricingModels.saga";
import rootPricingSaga from "../features/pricing/pricing.saga";
import rootInstrumentsSaga from "../features/instruments/instruments.saga";
import rootInstrumentTypesSaga from "../features/instrumentTypes/instrumentTypes.saga";
import rootLanguagesSaga from "../features/languages/languages.saga";
import rootEtdCategoriesSaga from "../features/etdCategories/etdCategories.saga";
import rootTransactionsSaga from "../features/transactions/transactions.saga";
import rootDirectionsSaga from "../features/directions/directions.saga";
import rootCurrenciesSaga from "../features/currencies/currencies.saga";
import rootChargeModelsSaga from "../features/chargeModels/chargeModels.saga";
import rootMarketPlacesSaga from "../features/marketPlaces/marketPlaces.saga";
import rootValueBandsSaga from "../features/valueBands/valueBands.saga";
import rootFfeMatricesSaga from "../features/ffeMatrices/ffeMatrices.saga";
import rootSpecialConditionTypesSaga from "../features/specialConditionTypes/specialConditionTypes.saga";
import rootFeeCodesSaga from "../features/feeCodes/feeCodes.saga";
import rootFeeMatrices3PSaga from "../features/feeMatrices3P/feeMatrices3P.saga";
import rootSingleOutputSaga from "../features/singleOutput/singleOutput.saga";
import rootMultipleOutputSaga from "../features/multipleOutput/multipleOutput.saga";

const sagaMiddleware = createSagaMiddleware();

const combinedReducer = combineReducers({
  bcReducer: bookingCentersReducer,
  gfReducer: grantFuncsReducer,
  pmReducer: pricingModelReducer,
  pReducer: pricingReducer,
  iReducer: instrumentsReducer,
  itReducer: instrumentTypesReducer,
  lReducer: languagesReducer,
  ecReducer: etdCategoriesReducer,
  tReducer: transactionsReducer,
  dReducer: directionsReducer,
  cReducer: currenciesReducer,
  cmReducer: chargeModelsReducer,
  mpReducer: marketPlacesReducer,
  vbReducer: valueBandsReducer,
  fmReducer: ffeMatricesReducer,
  sctReducer: specialConditionTypesReducer,
  fcReducer: feeCodesReducer,
  fm3pReducer: feeMatrices3PReducer,
  soReducer: singleOutputReducer,
  moReducer: multipleOutputReducer,
});

function* rootSaga() {
  yield all([
    rootBookingCentersSaga(),
    rootGrantFuncsSaga(),
    rootPricingModelsSaga(),
    rootPricingSaga(),
    rootInstrumentTypesSaga(),
    rootInstrumentsSaga(),
    rootLanguagesSaga(),
    rootEtdCategoriesSaga(),
    rootTransactionsSaga(),
    rootDirectionsSaga(),
    rootCurrenciesSaga(),
    rootChargeModelsSaga(),
    rootMarketPlacesSaga(),
    rootValueBandsSaga(),
    rootFfeMatricesSaga(),
    rootSpecialConditionTypesSaga(),
    rootFeeCodesSaga(),
    rootFeeMatrices3PSaga(),
    rootSingleOutputSaga(),
    rootMultipleOutputSaga(),
  ]);
}

const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

sagaMiddleware.run(rootSaga);

export default store;
