export const defaultValues = {
  portfolioNo: "",
  outputLanguage: "",
  bookingCenter: "",
  pricing: "",
  feeCode: "",
  ticketFeeHost: undefined,
  chargesModel: "",
  simulateThirdPartyCharges: "yes",
  comments: "",
  etdCategory1: "",
  etdCategory2: "",
  etdCategory3: "",
  etdCategory4: "",
  pricingModel1: "",
  pricingModel2: "",
  pricingModel3: "",
  pricingModel4: "",
  simulationPossible1: "",
  simulationPossible2: "",
  simulationPossible3: "",
  simulationPossible4: "",
  segment1: "",
  segment2: "",
  segment3: "",
  segment4: "",
  specialConditions1: "",
  specialConditions2: "",
  specialConditions3: "",
  specialConditions4: "",
  specialConditionsUnit1: "",
  specialConditionsUnit2: "",
  specialConditionsUnit3: "",
  specialConditionsUnit4: "",
  specialConditionsAmount1: "",
  specialConditionsAmount2: "",
  specialConditionsAmount3: "",
  specialConditionsAmount4: "",
  minimumFee1: "",
  minimumFee2: "",
  minimumFee3: "",
  minimumFee4: "",
  chargesModelSim1: "",
  chargesModelSim2: "",
  chargesModelSim3: "",
  chargesModelSim4: "",
  instrumentSecurityCurrency1: "",
  instrumentSecurityCurrency2: "",
  instrumentSecurityCurrency3: "",
  instrumentSecurityCurrency4: "",
  transaction1: "",
  transaction2: "",
  transaction3: "",
  transaction4: "",
  instrumentType1: "",
  instrumentType2: "",
  instrumentType3: "",
  instrumentType4: "",
  direction1: "",
  direction2: "",
  direction3: "",
  direction4: "",
  fullName1: "",
  fullName2: "",
  fullName3: "",
  fullName4: "",
  quantity1: "",
  quantity2: "",
  quantity3: "",
  quantity4: "",
  overrideQuantity1: "",
  overrideQuantity2: "",
  overrideQuantity3: "",
  overrideQuantity4: "",
  quantity4Sim1: "",
  quantity4Sim2: "",
  quantity4Sim3: "",
  quantity4Sim4: "",
  contractLotSize1: "",
  contractLotSize2: "",
  contractLotSize3: "",
  contractLotSize4: "",
  premiumPerContractLot1: "",
  premiumPerContractLot2: "",
  premiumPerContractLot3: "",
  premiumPerContractLot4: "",
  overridePremiumPerContractLot1: "",
  overridePremiumPerContractLot2: "",
  overridePremiumPerContractLot3: "",
  overridePremiumPerContractLot4: "",
  premiumPerContractLot4Sim1: "",
  premiumPerContractLot4Sim2: "",
  premiumPerContractLot4Sim3: "",
  premiumPerContractLot4Sim4: "",
  currencies1: "",
  currencies2: "",
  currencies3: "",
  currencies4: "",
  exchangeRate1: "",
  exchangeRate2: "",
  exchangeRate3: "",
  exchangeRate4: "",
  overrideExchangeRate1: "",
  overrideExchangeRate2: "",
  overrideExchangeRate3: "",
  overrideExchangeRate4: "",
  exchangeRate4Sim1: "",
  exchangeRate4Sim2: "",
  exchangeRate4Sim3: "",
  exchangeRate4Sim4: "",
  stockExchange1: "",
  stockExchange2: "",
  stockExchange3: "",
  stockExchange4: "",
  instrumentSecurityNumber1: "",
  instrumentSecurityNumber2: "",
  instrumentSecurityNumber3: "",
  instrumentSecurityNumber4: "",
};
