import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { AppRoutes } from "../routes/Routes";
import { theme } from "./theme";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter } from "react-router-dom";
import { useAuth } from "react-oidc-context";

export const App = () => {
  const auth = useAuth();

  React.useEffect(() => {
    auth.startSilentRenew();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.startSilentRenew]);

  React.useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
      auth.signinSilent();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.signinSilent, auth.events.addAccessTokenExpiring]);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
