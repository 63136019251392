import { takeLatest, put } from "redux-saga/effects";
import {
  fetchMarketPlaces,
  FetchMarketPlacesAction,
  fetchMarketPlacesSuccess,
} from "./marketPlaces.reducer";
import {
  BookingCenterControllerService,
  EntityModelMarketPlaceDto,
} from "../../openapi";
import authorizedCall from "../../utils/authorizedCall";

export function* fetchMarketPlacesSaga(action: FetchMarketPlacesAction) {
  const response: EntityModelMarketPlaceDto[] = yield (
    authorizedCall(BookingCenterControllerService.getMarketPlaces, {
      page: 0,
      size: 10,
      bookingCenterId: action.payload.bookingCenterId,
      pricingModelId: action.payload.pricingModelId,
      categoryId: action.payload.categoryId,
      chargeModelId: action.payload.chargeModelId,
      currencyId: action.payload.currencyId,
    })
  );

  yield put(fetchMarketPlacesSuccess({ marketPlaces: response }));
}

export default function* rootMarketPlacesSaga() {
  yield takeLatest(fetchMarketPlaces.type, fetchMarketPlacesSaga);
}
