import { createSlice } from "@reduxjs/toolkit";
import { SpecialConditionType } from "../../openapi";

export type SpecialConditionTypesState = {
  specialConditionTypes: SpecialConditionType[];
  selected?: SpecialConditionType;
  selectedForMultipleSimulation1?: SpecialConditionType;
  selectedForMultipleSimulation2?: SpecialConditionType;
  selectedForMultipleSimulation3?: SpecialConditionType;
  selectedForMultipleSimulation4?: SpecialConditionType;
  isFetching: boolean;
  isMultiple: boolean;
  discounts: number[];
  minFees: number[];
};

export const INITIAL_STATE: SpecialConditionTypesState = {
  specialConditionTypes: [],
  selected: undefined,
  selectedForMultipleSimulation1: undefined,
  selectedForMultipleSimulation2: undefined,
  selectedForMultipleSimulation3: undefined,
  selectedForMultipleSimulation4: undefined,
  isFetching: false,
  isMultiple: false,
  discounts: [],
  minFees: [],
};

export type FetchSpecialConditionTypesAction = {
  type: string;
  payload: {
    categoryId?: number;
  };
};

export type FetchSpecialConditionTypesForMultipleSimulationAction = {
  type: string;
  payload: {
    feeCodeId: number;
    bookingCenterId: number;
  };
};

export type SpecialConditionTypeForMultipleSimulationSelected = {
  type: string;
  payload: {
    selected: SpecialConditionType;
    simNo: number;
  };
};

const specialConditionTypesSlice = createSlice({
  name: "specialConditionTypes",
  initialState: INITIAL_STATE,
  reducers: {
    fetchSpecialConditionTypes: (
      state: SpecialConditionTypesState,
      _action: FetchSpecialConditionTypesAction
    ) => {
      state.isFetching = true;
      state.isMultiple = false;
    },
    fetchSpecialConditionTypesSuccess: (
      state: SpecialConditionTypesState,
      action
    ) => {
      state.specialConditionTypes = action.payload.specialConditionTypes;
      state.isFetching = false;
    },
    fetchSpecialConditionTypesForMultipleSimulation: (
      state: SpecialConditionTypesState,
      _action: FetchSpecialConditionTypesForMultipleSimulationAction
    ) => {
      state.isFetching = true;
      state.isMultiple = true;
    },
    fetchSpecialConditionTypesForMultipleSimulationSuccess: (
      state: SpecialConditionTypesState,
      action
    ) => {
      state.specialConditionTypes = action.payload.specialConditionTypes;
      state.isFetching = false;
      state.discounts = action.payload.discounts;
      state.minFees = action.payload.minFees;
    },
    specialConditionTypeSelected: (
      state: SpecialConditionTypesState,
      action
    ) => {
      state.selected = action.payload;
    },
    specialConditionTypeForMultipleSimulationSelected: (
      state: SpecialConditionTypesState,
      action: SpecialConditionTypeForMultipleSimulationSelected
    ) => {
      switch (action.payload.simNo) {
        case 1:
          state.selectedForMultipleSimulation1 = action.payload.selected;
          break;
        case 2:
          state.selectedForMultipleSimulation2 = action.payload.selected;
          break;
        case 3:
          state.selectedForMultipleSimulation3 = action.payload.selected;
          break;
        case 4:
          state.selectedForMultipleSimulation4 = action.payload.selected;
          break;
      }
    },
    resetSpecialConditionTypes: (state: SpecialConditionTypesState) => {
      state.specialConditionTypes = [];
      state.selectedForMultipleSimulation1 = undefined;
      state.selectedForMultipleSimulation2 = undefined;
      state.selectedForMultipleSimulation3 = undefined;
      state.selectedForMultipleSimulation4 = undefined;
      state.selected = undefined;
      state.isFetching = false;
      state.isMultiple = false;
    },
  },
});

export const {
  fetchSpecialConditionTypes,
  fetchSpecialConditionTypesSuccess,
  fetchSpecialConditionTypesForMultipleSimulation,
  fetchSpecialConditionTypesForMultipleSimulationSuccess,
  specialConditionTypeSelected,
  specialConditionTypeForMultipleSimulationSelected,
  resetSpecialConditionTypes,
} = specialConditionTypesSlice.actions;

export default specialConditionTypesSlice.reducer;
