import { takeLatest, put } from "redux-saga/effects";
import {
  fetchPricingModels,
  FetchPricingModelsAction,
  fetchPricingModelsSuccess,
} from "./pricingModels.reducer";
import { BookingCenterControllerService, PricingModelDto } from "../../openapi";
import authorizedCall from "../../utils/authorizedCall";

export function* fetchPricingModelsSaga(action: FetchPricingModelsAction) {
  const response: PricingModelDto[] = yield (
    authorizedCall(BookingCenterControllerService.getPricingModels, {
      bookingCenterId: action.payload.bookingCenterId,
      page: 0,
      size: 10,
    })
  );

  yield put(fetchPricingModelsSuccess({ pricingModels: response }));
}

export default function* rootPricingModelsSaga() {
  yield takeLatest(fetchPricingModels.type, fetchPricingModelsSaga);
}
