import { RootState } from "../../app/store";
import { EntityModelChargeModelDto } from "../../openapi";

export let selectIsFetchingChargeModels = (state: RootState): boolean =>
  state.cmReducer.isFetching;
export let selectChargeModels = (
  state: RootState
): EntityModelChargeModelDto[] => state.cmReducer.chargeModels;
export let selectSelectedChargeModel = (
  state: RootState
): EntityModelChargeModelDto | undefined => state.cmReducer.selected;
