import React from "react";

import { Box } from "@mui/material";
import { Header } from "../../../../../components/core/Header";
import { StyledDiv } from "../../../index.style";
import { StyledSection } from "./index.style";

import { Basic } from "./Basic";
import { ValueBands } from "./ValueBands";
import { TARates } from "./TARates";
import { Fees } from "./Fees";
import { ThirdPartyCharges } from "./ThirdPartyCharges";
import { fetchFfeMatrices } from "../../../../../features/ffeMatrices/ffeMatrices.reducer";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import { selectSelectedBookingCenter } from "../../../../../features/bookingCenters/bookingCenters.selectors";
import { selectSelectedEtdCategory } from "../../../../../features/etdCategories/etdCategories.selectors";
import { selectSelectedPricingModel } from "../../../../../features/pricingModels/pricingModels.selectors";
import { useAppDispatch } from "../../../../../hooks/useAppDispatch";
import { fetchValueBands } from "../../../../../features/valueBands/valueBands.reducer";
import { fetchFeeMatrices3P } from "../../../../../features/feeMatrices3P/feeMatrices3P.reducer";

import { selectSelectedChargeModel } from "../../../../../features/chargeModels/chargeModels.selectors";
import { selectSelectedCurrency } from "../../../../../features/currencies/currencies.selectors";
import { selectSelectedMarketPlace } from "../../../../../features/marketPlaces/marketPlaces.selectors";

export const OutPreview = () => {
  const dispatch = useAppDispatch();

  const selectedBookingCenter = useAppSelector(selectSelectedBookingCenter);
  const selectedEtdCategory = useAppSelector(selectSelectedEtdCategory);
  const selectedPricingModel = useAppSelector(selectSelectedPricingModel);
  const selectedChargeModel = useAppSelector(selectSelectedChargeModel);
  const selectedCurrency = useAppSelector(selectSelectedCurrency);
  const selectedMarketPlace = useAppSelector(selectSelectedMarketPlace);

  React.useEffect(() => {
    if (
      !selectedBookingCenter ||
      !selectedEtdCategory ||
      !selectedPricingModel
    ) {
      return;
    }

    dispatch(
      fetchFfeMatrices({
        bookingCenterId: selectedBookingCenter.id as number,
        categoryId: selectedEtdCategory.id,
        pricingModelId: selectedPricingModel.id,
      })
    );
  }, [
    dispatch,
    selectedBookingCenter,
    selectedEtdCategory,
    selectedPricingModel,
  ]);

  React.useEffect(() => {
    dispatch(fetchValueBands());
  }, [dispatch]);

  React.useEffect(() => {
    let input: any = {};

    if (selectedMarketPlace) {
      input.marketPlace = selectedMarketPlace.id;
    }

    if (selectedBookingCenter) {
      input.bookingCenter = selectedBookingCenter.id;
      //input.currency = selectedCurrency
    }

    if (selectedPricingModel) {
      input.pricingModel = selectedPricingModel.id;
    }

    if (selectedChargeModel) {
      input.chargeModel = selectedChargeModel.id;
    }

    if (selectedEtdCategory) {
      input.etdCategory = selectedEtdCategory.id;
    }

    //removed, using booking center currency
    if (selectedCurrency) {
      input.currency = selectedCurrency.id;
    }

    dispatch(fetchFeeMatrices3P(input));
  }, [
    dispatch,
    selectedMarketPlace,
    selectedBookingCenter,
    selectedPricingModel,
    selectedChargeModel,
    selectedEtdCategory,
    selectedCurrency,
  ]);

  return (
    <StyledDiv>
      <Header
        title={<Box sx={{ textAlign: "center" }}>Calculation-parameters</Box>}
      />
      <StyledSection>
        <Box sx={{ width: "100%", marginRight: "10px" }}>
          <Basic />
          <ValueBands />
          <TARates />
          <Fees />
          <ThirdPartyCharges />
        </Box>
      </StyledSection>
    </StyledDiv>
  );
};
