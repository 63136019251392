import { takeLatest, takeEvery, put } from "redux-saga/effects";
import {
    fetchFfeMatrices,
    FetchFfeMatricesAction,
    fetchFfeMatricesForMultipleSimulation, FetchFfeMatricesForMultipleSimulationAction,
    fetchFfeMatricesForMultipleSimulationSuccess,
    fetchFfeMatricesSuccess,
} from "./ffeMatrices.reducer";
import {
  BookingCenterControllerService,
  EntityModelFfeMatrixBjbDto,
} from "../../openapi";
import authorizedCall from "../../utils/authorizedCall";

export function* fetchFfeMatricesSaga(action: FetchFfeMatricesAction) {
  const response: EntityModelFfeMatrixBjbDto[] = yield authorizedCall(
    BookingCenterControllerService.getFfeMatrixBjb,
    {
      bookingCenterId: action.payload.bookingCenterId,
      pricingModelId: action.payload.pricingModelId,
      categoryId: action.payload.categoryId,
      size: 10,
      page: 0,
    }
  );

  yield put(fetchFfeMatricesSuccess({ ffeMatrices: response }));
}

export function* fetchFfeMatricesSagaForMultipleSimulation(
  action: FetchFfeMatricesForMultipleSimulationAction
) {
  const response: EntityModelFfeMatrixBjbDto[] = yield authorizedCall(
    BookingCenterControllerService.getFfeMatrixBjb,
    {
      bookingCenterId: action.payload.bookingCenterId,
      pricingModelId: action.payload.pricingModelId,
      categoryId: action.payload.categoryId,
      size: 10,
      page: 0,
    }
  );

  yield put(
    fetchFfeMatricesForMultipleSimulationSuccess({ ffeMatrices: response, simNo: action.payload.simNo })
  );
}

export default function* rootFfeMatricesSaga() {
  yield takeLatest(fetchFfeMatrices.type, fetchFfeMatricesSaga);
  yield takeEvery(
    fetchFfeMatricesForMultipleSimulation.type,
    fetchFfeMatricesSagaForMultipleSimulation
  );
}
