import { takeLatest, put } from "redux-saga/effects";
import {
  fetchMultipleOutput,
  FetchMultipleOutputAction,
  fetchMultipleOutputSuccess, MultipleOutput,
} from "./multipleOutput.reducer";
import {CoverLetterControllerService} from "../../openapi";
import authorizedCall from "../../utils/authorizedCall";

export function* fetchMultipleOutputSaga(action: FetchMultipleOutputAction) {
  const input = action.payload;
  input.userId = "{useremail}";

  let response: MultipleOutput = yield authorizedCall(
      CoverLetterControllerService.createCoverLetter,
    {
      requestBody: input,
    },
    true
  );

  response = {
    ...response,
    ...input,
  };

  yield put(fetchMultipleOutputSuccess({ output: response }));
}

export default function* rootMultipleOutputSaga() {
  yield takeLatest(fetchMultipleOutput.type, fetchMultipleOutputSaga);
}
