import { Controller, UseControllerProps } from "react-hook-form";
import type { FieldValues } from "react-hook-form/dist/types/fields";
import {
  CurrencyInput,
  CurrencyInputProps,
} from "components/core/CurrencyInput/CurrencyInput";

export type FormCurrencyInputProps<T extends FieldValues = FieldValues> =
  UseControllerProps<T> & CurrencyInputProps;

export const FormCurrencyInput = <T extends FieldValues = FieldValues>(
  props: FormCurrencyInputProps<T>
) => {
  const { name, control, rules, onChange, ...restProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      data-testid={name}
      render={({
        field: { onChange: onChangeHF, ref, ...restHFProps },
        fieldState: { error },
      }) => (
        <CurrencyInput
          {...restHFProps}
          {...restProps}
          getInputRef={ref}
          error={error as any}
          data-testid={name}
          onValueChange={(values) => {
            // hook form onChange (set the value of hook form context)
            onChangeHF(values.floatValue);
            // custom onChange
            onChange?.(values.floatValue as any);
          }}
        />
      )}
    />
  );
};
