import { takeLatest, put } from "redux-saga/effects";
import {
  fetchSpecialConditionTypes,
  fetchSpecialConditionTypesForMultipleSimulation,
  fetchSpecialConditionTypesForMultipleSimulationSuccess,
  fetchSpecialConditionTypesSuccess,
  FetchSpecialConditionTypesAction,
  FetchSpecialConditionTypesForMultipleSimulationAction,
} from "./specialConditionTypes.reducer";
import {
  SpecialConditionTypeControllerService,
  FeeCodeControllerService,
  EntityModelSpecialConditionType,
  EntityModelFcdToBcToEcToPmToSt,
  SpecialConditionType,
} from "../../openapi";
import authorizedCall from "../../utils/authorizedCall";

export function* fetchSpecialConditionTypesSaga(
  action: FetchSpecialConditionTypesAction
) {
  const response: EntityModelSpecialConditionType[] = yield authorizedCall(
    SpecialConditionTypeControllerService.getSpecialConditionType,
    { page: 0, size: 10, categoryId: action.payload?.categoryId }
  );
  yield put(
    fetchSpecialConditionTypesSuccess({ specialConditionTypes: response })
  );
}

export function* fetchSpecialConditionTypesForMultipleSimulationSaga(
  action: FetchSpecialConditionTypesForMultipleSimulationAction
) {
  const response: EntityModelFcdToBcToEcToPmToSt[] = yield authorizedCall(
    FeeCodeControllerService.getSpecialConditions,
    {
      page: 0,
      size: 10,
      feeCodeId: action.payload.feeCodeId,
      bookingCenterId: action.payload.bookingCenterId,
    }
  );

  let entities: SpecialConditionType[] = [];
  let discounts: number[] = [];
  let minFees: number[] = [];

  for (let entity of response ?? []) {
    entities.push(entity.specialConditionType as SpecialConditionType);
    discounts.push(entity.discount ?? 0);
    minFees.push(entity.feeMin ?? 0);
  }

  yield put(
    fetchSpecialConditionTypesForMultipleSimulationSuccess({
      specialConditionTypes: entities,
      discounts,
      minFees,
    })
  );
}

export default function* rootSpecialConditionTypesSaga() {
  yield takeLatest(
    fetchSpecialConditionTypes.type,
    fetchSpecialConditionTypesSaga
  );
  yield takeLatest(
    fetchSpecialConditionTypesForMultipleSimulation.type,
    fetchSpecialConditionTypesForMultipleSimulationSaga
  );
}
