import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import { Alert, Grid, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Spinner } from "../../../components/core/Spinner";
import { Card } from "../../../components/core/Card";
import { useUser } from "../../../hooks/useUser";
import { FormInput } from "../../../components/hook-form/FormInput";
import { FormFieldLabel } from "../../../components/hook-form/FormFieldLabel";
import {
  EntityModelFfeMatrixBjb,
  FfeMatrixBjbControllerService,
  FfeMatrixBjbUpdateDto,
  UserControllerService,
} from "../../../openapi";
import { AlertMessage } from "../../../components/core/AlertMessage/AlertMessage";
import { parseJwt } from "../../../utils/jwtUtils";

export interface EditFeeMatrixBJBInputs {
  bsRemark: string;
  itRemark: string;
  bookingCenter: string;
  pricingModel: string;
  etdCategory: string;
  feeClass: string;
  amount: string;
}

export interface EditFeeMatrixBJBFormProps {
  id?: string;
}

export const EditFeeMatrixBJBForm = (props: EditFeeMatrixBJBFormProps) => {
  const [feeMatrixBJB, setFeeMatrix3p] = useState<EntityModelFfeMatrixBjb>();
  const [feeMatrixBJBError, setFeeMatrix3pError] = useState("");
  const [feeMatrixBJBIsFetching, setFeeMatrix3pIsFetching] = useState(false);

  const { id } = props;
  const { token, user } = useUser();

  const form = useForm<EditFeeMatrixBJBInputs>({
    defaultValues: {
      bsRemark: "",
      itRemark: "",
      bookingCenter: "",
      pricingModel: "",
      etdCategory: "",
      feeClass: "",
      amount: "",
    },
  });

  const { reset, formState, handleSubmit, control } = form;

  useEffect(() => {
    setFeeMatrix3pIsFetching(true);
    if (token) {
      FfeMatrixBjbControllerService.getFfeMatricesBjb({
        id: Number(id),
        authorization: `Bearer ${token}`,
        size: 10,
      })
        .then((data) => {
          setFeeMatrix3pIsFetching(false);
          setFeeMatrix3p(data?._embedded?.entities?.[0] ?? {});
          reset({
            bsRemark: data?._embedded?.entities?.[0].bsRemark ?? "",
            itRemark: data?._embedded?.entities?.[0].itRemark ?? "",
            bookingCenter:
              data?._embedded?.entities?.[0].bookingCenter?.text ?? "",
            pricingModel:
              data?._embedded?.entities?.[0].pricingModel?.text ?? "",
            etdCategory: data?._embedded?.entities?.[0].etdCategory?.text ?? "",
            feeClass: data?._embedded?.entities?.[0].feeClass?.text ?? "",
            amount:
              data?._embedded?.entities?.[0].amount !== null
                ? String(data?._embedded?.entities?.[0].amount)
                : "",
          });
        })
        .catch((error) => {
          setFeeMatrix3pIsFetching(false);
          setFeeMatrix3pError(error.message);
        });
    }
  }, [id, reset, token]);

  const onSubmit: SubmitHandler<EditFeeMatrixBJBInputs> = useCallback(
    (data) => {
      if (id) {
        const { bsRemark, itRemark, amount } = data;

        const bodyReq: FfeMatrixBjbUpdateDto = {
          bsRemark: null,
          itRemark: null,
          amount: null,
        };

        if (bsRemark) bodyReq.bsRemark = bsRemark;
        if (itRemark) bodyReq.itRemark = itRemark;
        if (amount) bodyReq.amount = Number(amount);

        FfeMatrixBjbControllerService.updateFfeMatrixBjb(
          Number(id),
          bodyReq,
          `Bearer ${token}`
        )
          .then(() => {
            UserControllerService.addUserAction(
              {
                user: parseJwt(token ?? "")?.upn,
                cdOperation: "entity_FeeMatrixBJB_edit",
                operation: "ecs_ee",
                description:
                  "The user edited the FeeMatrixBJB entity with id " + id,
              },
              `Bearer ${token}`
            );
          })
          .catch((error) => setFeeMatrix3pError(error.message));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, token, user]
  );

  return (
    <Card
      title="Edit Matrix BJB"
      style={{ width: "1512px" }}
      actions={
        feeMatrixBJB &&
        !feeMatrixBJBError && (
          <LoadingButton
            variant="contained"
            endIcon={<SaveAltOutlinedIcon />}
            form="edit-fee-matrix-bjb-form"
            type="submit"
            sx={{ textTransform: "none" }}
            disabled={formState.isSubmitting}
            loading={formState.isSubmitting}
          >
            Save
          </LoadingButton>
        )
      }
      Component={() => (
        <Box sx={{ margin: "20px" }}>
          {feeMatrixBJBError && (
            <Alert severity="error">{feeMatrixBJBError}</Alert>
          )}
          {feeMatrixBJBIsFetching && !feeMatrixBJBError && (
            <Spinner height="353px" />
          )}
          {feeMatrixBJB && !feeMatrixBJBError && (
            <form
              id="edit-fee-matrix-bjb-form"
              data-testid="edit-fee-matrix-bjb-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container columnSpacing={2} rowSpacing={5}>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="bookingCenter"
                    label={<FormFieldLabel>Booking Center</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="pricingModel"
                    label={<FormFieldLabel>Pricing Model</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="etdCategory"
                    label={<FormFieldLabel>Etd Category</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="feeClass"
                    label={<FormFieldLabel>Fee Class</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    InputProps={{ inputProps: { min: 0, step: 0.0001 } }}
                    name="amount"
                    label={<FormFieldLabel>Amount</FormFieldLabel>}
                    fullWidth
                    type="number"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="bsRemark"
                    label={<FormFieldLabel>Bs Remark</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="itRemark"
                    label={<FormFieldLabel>It Remark</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
              </Grid>
            </form>
          )}
          <AlertMessage
            show={
              formState.isSubmitted &&
              formState.isSubmitSuccessful &&
              !feeMatrixBJBError
            }
            message="Data has been updated correctly"
          />
        </Box>
      )}
    />
  );
};
