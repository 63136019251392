import React from 'react';
import { Navigate, useLocation, useParams } from "react-router-dom";
import { Spinner } from "../../components/core/Spinner";
import { AuthenticatedLayout } from "../../components/layouts/AuthenticatedLayout";
import { useUserPermissions } from "../../hooks/useUserPermissions";
import { hasRoutePermission } from "../../utils/userPermissionUtils";

export interface ProtectedRouteProps {
  forcePath?: string;
  children: React.ReactNode;
}

export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { userPermissions } = useUserPermissions();
  const { pathname } = useLocation();
  const params = useParams();

  const { children, forcePath } = props;

  const path = Object.values(params).reduce(
    (_path, param) => _path?.replace("/" + param, ""),
    pathname
  );

  const route = forcePath ?? path ?? pathname;
  const isAllowed = hasRoutePermission(userPermissions, route);


  if (!userPermissions) {
    return (
      <AuthenticatedLayout>
        <Spinner width="100vw" height="calc(100vh - 264px)" />
      </AuthenticatedLayout>
    );
  }

  if (isAllowed) {
    return <div>{children}</div>;
  }

  return <Navigate to={"/"} />;
};
