import React from "react";
import { StyledDiv, StyledSection } from "../../../index.style";
import { Header } from "../../../../../components/core/Header/Header";
import { useFormContext } from "react-hook-form";
import { MultipleSimulationFormInputs } from "./types/MultipleSimulationFormInputs";
import { Grid, Box } from "@mui/material";
import { SharedFields } from "./SharedFields";
import { Simulation } from "./Simulation";
import { fetchPricingModels } from "../../../../../features/pricingModels/pricingModels.reducer";
import { useAppDispatch } from "../../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import {
  selectBookingCenters,
  selectSelectedBookingCenter,
} from "../../../../../features/bookingCenters/bookingCenters.selectors";
import { fetchEtdCategories } from "../../../../../features/etdCategories/etdCategories.reducer";
import {
  fetchSpecialConditionTypesForMultipleSimulation,
  resetSpecialConditionTypes,
} from "../../../../../features/specialConditionTypes/specialConditionTypes.reducer";
import { selectSelectedPricing } from "../../../../../features/pricing/pricing.selectors";
import {
  EntityModelPricingDto,
  UserControllerService,
} from "../../../../../openapi";
import {
  selectFeeCodes,
  selectSelectedFeeCode,
} from "../../../../../features/feeCodes/feeCodes.selectors";
import { fetchInstruments } from "../../../../../features/instruments/instruments.reducer";
import { fetchMultipleOutput } from "../../../../../features/multipleOutput/multipleOutput.reducer";
import { prepareDataForMultipleOutput } from "../../../../../utils/formUtils";
import { selectLanguages } from "../../../../../features/languages/languages.selectors";
import { selectPricingModels } from "../../../../../features/pricingModels/pricingModels.selectors";
import { selectSpecialConditionTypes } from "../../../../../features/specialConditionTypes/specialConditionTypes.selectors";
import { useUser } from "../../../../../hooks/useUser";
import { parseJwt } from "../../../../../utils/jwtUtils";

type MultipleSimulationFormProps = {
  onValidSubmit: () => void;
};

const MultipleSimulationForm = (
  props: MultipleSimulationFormProps
): React.ReactElement => {
  const form = useFormContext<MultipleSimulationFormInputs>();
  const { onValidSubmit } = props;
  const { handleSubmit } = form;

  const dispatch = useAppDispatch();
  const { token } = useUser();

  const bookingCenter = useAppSelector(selectSelectedBookingCenter);
  const feeCodes = useAppSelector(selectFeeCodes);
  const pricing = useAppSelector(
    selectSelectedPricing
  ) as EntityModelPricingDto;
  const feeCode = useAppSelector(selectSelectedFeeCode);
  const languages = useAppSelector(selectLanguages);
  const pricingModels = useAppSelector(selectPricingModels);
  const bookingCenters = useAppSelector(selectBookingCenters);
  const specialConditionTypes = useAppSelector(selectSpecialConditionTypes);

  React.useEffect(() => {
    if (!bookingCenter) {
      return;
    }

    dispatch(
      fetchPricingModels({ bookingCenterId: bookingCenter.id as number })
    );

    dispatch(fetchEtdCategories());
  }, [dispatch, bookingCenter]);

  React.useEffect(() => {
    dispatch(fetchInstruments({}));
  }, [dispatch]);

  React.useEffect(() => {
    if (
      !bookingCenter ||
      !pricing ||
      !feeCode ||
      pricing.text.toLowerCase() === "manual"
    ) {
      return;
    }

    dispatch(resetSpecialConditionTypes());

    dispatch(
      fetchSpecialConditionTypesForMultipleSimulation({
        bookingCenterId: bookingCenter.id as number,
        feeCodeId: feeCode.id as number,
      })
    );
  }, [bookingCenter, pricing, feeCode, dispatch]);

  const onSubmit = handleSubmit((data) => {
    if (token) {
      const input = prepareDataForMultipleOutput(
        data,
        languages,
        bookingCenters,
        feeCodes,
        pricingModels,
        [...specialConditionTypes, (window as any).specialCond || {}]
      );
      dispatch(fetchMultipleOutput(input));
      UserControllerService.addUserAction(
        {
          user: parseJwt(token ?? "")?.upn,
          cdOperation: "multiple_output_calculation",
          operation: "ecs_oc",
          description: "The user performed a calculation",
        },
        `Bearer ${token}`
      );
      onValidSubmit();
    }
  });

  return (
    <StyledDiv>
      <Header
        title={<Box sx={{ textAlign: "center" }}>ECS calculation form</Box>}
      />
      <StyledSection>
        <form
          style={{ marginTop: "10px", width: "100%" }}
          onSubmit={onSubmit}
          id="multiple-simulation-form"
        >
          <Grid
            container
            columnSpacing={2}
            rowSpacing={5}
            sx={{ width: "100%" }}
          >
            <SharedFields />
          </Grid>
          <Grid
            container
            columnSpacing={2}
            rowSpacing={5}
            sx={{ width: "100%", marginTop: "20px" }}
          >
            <Header title="Simulation" />
            <Simulation simNo={1} />
            <Simulation simNo={2} />
            <Simulation simNo={3} />
            <Simulation simNo={4} />
          </Grid>
        </form>
      </StyledSection>
    </StyledDiv>
  );
};

export default MultipleSimulationForm;
