import { Alert, Grid, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../../components/core/Spinner";
import { splitElementsInColumns } from "../../../utils/layoutUtils";
import { useUserPermissions } from "../../../hooks/useUserPermissions";
import { mapCodeGrantToLabel } from "../../../utils/userPermissionUtils";
import React from "react";
import { getRoles } from "utils/jwtUtils";

export const StyledButton = styled(Button)`
  border-radius: 0;
  text-transform: none;
  font-weight: bold;
  width: 300px;
`;

const PermissionChecker = (props: { children: React.ReactNode }) => {
  const [hasAccess, setHasAccess] = React.useState<boolean | undefined>(
    undefined
  );

  const { children } = props;

  React.useEffect(() => {
    setHasAccess(getRoles()?.length > 0);
  }, []);

  if (hasAccess === undefined) {
    return <React.Fragment />;
  }

  if (!hasAccess) {
    return (
      <Grid container sx={{ pl: "45px" }}>
        <Alert
          children="Access denied. Please make the ServiceNow request to receive access."
          sx={{ width: "100%", ml: "-25px" }}
          severity="info"
        />
      </Grid>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export const UserActions = () => {
  const {
    userPermissions: userActions,
    userPermissionsIsFetching,
    userPermissionsError,
  } = useUserPermissions();

  const navigate = useNavigate();

  const columns = useMemo(() => {
    let cols: Array<typeof userActions> = [];

    if (userActions && userActions.length > 0) {
      cols = splitElementsInColumns(1, 10, userActions);
    }

    return cols;
  }, [userActions]);

  const handleAction = useCallback(
    (url: string) => () => {
      navigate(url);
    },
    [navigate]
  );

  return (
    <PermissionChecker>
      <Grid container sx={{ pl: "45px" }}>
        {userPermissionsIsFetching && <Spinner width="1512px" height="520px" />}
        {userPermissionsError && (
          <Alert
            children={userPermissionsError}
            sx={{ width: "100%", ml: "-25px" }}
            severity="error"
          />
        )}
        {userActions &&
          userActions.length === 0 &&
          !userPermissionsError &&
          !userPermissionsIsFetching && (
            <Alert
              children="You have no permission."
              sx={{ width: "100%", ml: "-25px" }}
              severity="info"
            />
          )}
        {userActions && userActions.length > 0 && (
          <>
            {columns.map((column, indexColumn) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={indexColumn}>
                {column?.map((row, indexRow) => (
                  <div key={row?.id}>
                    {indexColumn === 0 && indexRow === 0 && (
                      <p>Please choose what to do:</p>
                    )}
                    {indexColumn !== 0 && indexRow === 0 && (
                      <Box sx={{ height: "55px" }} />
                    )}

                    <div>
                      <StyledButton
                        variant={
                          indexColumn === 0 && indexRow < 2
                            ? "contained"
                            : "outlined"
                        }
                        color={
                          indexColumn === 0 && indexRow < 2
                            ? "primary"
                            : "secondary"
                        }
                        onClick={handleAction(row?.paths[0] ?? "/")}
                      >
                        <Typography noWrap>
                          {mapCodeGrantToLabel(row?.codeGrant) +
                            row?.description}
                        </Typography>
                      </StyledButton>
                    </div>
                    <br />
                  </div>
                ))}
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </PermissionChecker>
  );
};
