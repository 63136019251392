import React from "react";
import { StyledDivider } from "./index.style";
import { Paper, Toolbar, Typography } from "@mui/material";
import type { ReactNode, CSSProperties, FC } from "react";
import { Section } from "../Section";
import AppBar from "@mui/material/AppBar";

export type CardProps = {
  children?: ReactNode;
  title: string | React.ReactNode;
  Component?: FC<{ children: ReactNode }>;
  actions?: any;
  style?: CSSProperties;
  sticky?: boolean;
};

export const Card = (props: CardProps): React.ReactElement => {
  const { title, children, sticky = true, Component, actions, style } = props;

  const CardToolbar = React.useMemo(
    () => (
      <>
        <Toolbar>
          <Typography
            component="h2"
            variant="h5"
            color={"primary"}
            noWrap
            sx={{ flexGrow: 1 }}
          >
            <span style={{ marginBottom: "24px" }}>{title}</span>
          </Typography>
          {actions}
        </Toolbar>
        <StyledDivider />
      </>
    ),
    [actions, title]
  );

  return (
    <React.Fragment>
      <Paper
        sx={{ p: 2, display: "flex", flexDirection: "column" }}
        style={style}
      >
        {sticky ? (
          <AppBar
            elevation={0}
            sx={{
              background: "white",
              position: "sticky",
              top: 60,
              zIndex: 5,
            }}
          >
            {CardToolbar}
          </AppBar>
        ) : (
          CardToolbar
        )}

        {Component ? (
          <Component>{children}</Component>
        ) : (
          <Section>{children}</Section>
        )}
      </Paper>
    </React.Fragment>
  );
};
