/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CoverLetterRequestDto } from '../models/CoverLetterRequestDto';
import type { EntityModelCoverLetterResponseDto } from '../models/EntityModelCoverLetterResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CoverLetterControllerService {

    /**
     * It will return a Cover Letter instance derived by to the Multiple Simulation Input instance"
     * @param input
     * @returns EntityModelCoverLetterResponseDto Cover Letter derived by the given Multiple Simulation Input
     * @throws ApiError
     */
    public static createCoverLetter(
input: {
    requestBody: CoverLetterRequestDto,
    authorization?: string,
}
): CancelablePromise<EntityModelCoverLetterResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/cover-letter',
            headers: {
                'Authorization': input.authorization,
            },
            body: input.requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Your role must have read grants Execute to MULTIPLE_SIMULATION function`,
            },
        });
    }

}
