import { createSlice } from "@reduxjs/toolkit";
import { EntityModelPricingDto } from "../../openapi";

export type PricingState = {
  pricing: EntityModelPricingDto[];
  selected?: EntityModelPricingDto;
  isFetching: boolean;
};

export const INITIAL_STATE: PricingState = {
  pricing: [],
  selected: undefined,
  isFetching: false,
};

export type FetchPricingAction = {
  type: string;
  payload: {
    bookingCenterId: number;
  };
};

const pricingSlice = createSlice({
  name: "pricing",
  initialState: INITIAL_STATE,
  reducers: {
    fetchPricing: (state: PricingState, _action: FetchPricingAction) => {
      state.isFetching = true;
    },
    fetchPricingSuccess: (state: PricingState, action) => {
      state.pricing = action.payload.pricing;
      state.isFetching = false;
    },
    pricingSelected: (state: PricingState, action) => {
      state.selected = action.payload;
    },
    resetPricing: (state: PricingState) => {
      state.pricing = [];
    },
  },
});

export const {
  fetchPricing,
  fetchPricingSuccess,
  pricingSelected,
  resetPricing,
} = pricingSlice.actions;

export default pricingSlice.reducer;
