/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedModelEntityModelValueBand } from "../models/PagedModelEntityModelValueBand";
import type { ValueBandUpdateDto } from "../models/ValueBandUpdateDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ValueBandControllerService {
  /**
   * It update the Value Band instance
   * @param valueBandId
   * @param requestBody
   * @param authorization
   * @returns void
   * @throws ApiError
   */
  public static updateValueBand(
    valueBandId: number,
    requestBody: ValueBandUpdateDto,
    authorization?: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/value-bands/{valueBandId}",
      path: {
        valueBandId: valueBandId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        403: `Your role must have read grants to Update the value bands table`,
        404: `The Value Band must exists`,
      },
    });
  }

  /**
   * It returns a list of all the Value Bands
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param search
   * @param authorization
   * @param code
   * @param fieldName
   * @param valMin
   * @param valMax
   * @param id
   * @param text
   * @returns PagedModelEntityModelValueBand Value Band list
   * @throws ApiError
   */
  public static getValueBands(input: {
    page?: number;
    size: number;
    sort?: Array<string>;
    search?: string;
    authorization?: string;
    code?: string;
    fieldName?: string;
    valMin?: number;
    valMax?: number;
    id?: number;
    text?: string;
  }): CancelablePromise<PagedModelEntityModelValueBand> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/value-bands",
      headers: {
        Authorization: input.authorization,
      },
      query: {
        page: input.page,
        size: input.size,
        sort: input.sort,
        search: input.search,
        code: input.code,
        fieldName: input.fieldName,
        valMin: input.valMin,
        valMax: input.valMax,
        id: input.id,
        text: input.text,
      },
      errors: {
        403: `Your role must have read grants to value bands table`,
      },
    });
  }
}
