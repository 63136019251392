import { takeLatest, put } from "redux-saga/effects";
import {
  fetchFeeMatrices3P,
  FetchFeeMatrices3PAction,
  fetchFeeMatrices3PSuccess,
} from "./feeMatrices3P.reducer";
import {
  FeeMatrix3PControllerService,
  EntityModelFeeMatrix3P,
} from "../../openapi";
import authorizedCall from "../../utils/authorizedCall";

export function* fetchFeeMatrices3PSaga(action: FetchFeeMatrices3PAction) {
  const response: EntityModelFeeMatrix3P[] = yield authorizedCall(
    FeeMatrix3PControllerService.getFeeMatrices3P,
    {
      page: 0,
      size: 50,
      ...action?.payload,
    }
  );

  yield put(fetchFeeMatrices3PSuccess({ feeMatrices3P: response }));
}

export default function* rootFeeMatrices3PSaga() {
  yield takeLatest(fetchFeeMatrices3P.type, fetchFeeMatrices3PSaga);
}
