import { styled } from "@mui/material/styles";
import {Section} from "../../../../../components/core/Section";

export const StyledSection = styled(Section)`
  overflow-y: scroll;
  height: 100vh;
  width: 100%;
`;


