import { createSlice } from "@reduxjs/toolkit";
import { EntityModelChargeModelDto } from "../../openapi";

export type ChargeModelsState = {
  chargeModels: EntityModelChargeModelDto[];
  selected?: EntityModelChargeModelDto;
  isFetching: boolean;
};

export const INITIAL_STATE: ChargeModelsState = {
  chargeModels: [],
  selected: undefined,
  isFetching: false,
};

export type FetchChargeModelsAction = {
  type: string;
  payload: {
    bookingCenterId: number;
    pricingModelId: number;
    categoryId: number;
  };
};

export type FetchChargeModelsActionForMultipleSimulationAction = {
  type: string;
  payload: {
    bookingCenterId: number;
  };
};

const chargeModelsSlice = createSlice({
  name: "chargeModels",
  initialState: INITIAL_STATE,
  reducers: {
    fetchChargeModels: (
      state: ChargeModelsState,
      _action: FetchChargeModelsAction
    ) => {
      state.isFetching = true;
    },
    fetchChargeModelsSuccess: (state: ChargeModelsState, action) => {
      state.chargeModels = action.payload.chargeModels;
      state.isFetching = false;
    },
    fetchChargeModelsForMultipleSimulation: (state: ChargeModelsState, _action: FetchChargeModelsActionForMultipleSimulationAction) => {
      state.isFetching = true;
    },
    fetchChargeModelsForMultipleSimulationSuccess: (state: ChargeModelsState, action) => {
      state.chargeModels = action.payload.chargeModels;
      state.isFetching = false;
    },
    chargeModelSelected: (state: ChargeModelsState, action) => {
      state.selected = action.payload;
    },
    resetChargeModels: (state: ChargeModelsState) => {
      state.chargeModels = [];
    },
  },
});

export const {
  fetchChargeModels,
  fetchChargeModelsSuccess,
  fetchChargeModelsForMultipleSimulation,
  fetchChargeModelsForMultipleSimulationSuccess,
  chargeModelSelected,
  resetChargeModels,
} = chargeModelsSlice.actions;

export default chargeModelsSlice.reducer;
