/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedModelEntityModelTransactionDto } from '../models/PagedModelEntityModelTransactionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TransactionControllerService {

    /**
     * It will return a paged list of the Transactions
     * @param page Zero-based page index (0..N)
     * @param size The size of the page to be returned
     * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param authorization 
     * @param code 
     * @param id 
     * @param text 
     * @param isActive 
     * @returns PagedModelEntityModelTransactionDto Transactions list paged and filtered by the given mandatory and optional fields
     * @throws ApiError
     */
    public static getTransactions(
input: {
    page?: number,
size: number,
sort?: Array<string>,
authorization?: string,
code?: string,
id?: number,
text?: string,
isActive?: boolean,
}
): CancelablePromise<PagedModelEntityModelTransactionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/transactions',
            headers: {
                'Authorization': input.authorization,
            },
            query: {
                'page': input.page,
                'size': input.size,
                'sort': input.sort,
                'code': input.code,
                'id': input.id,
                'text': input.text,
                'isActive': input.isActive,
            },
            errors: {
                403: `Your role must have read grants to transaction table`,
            },
        });
    }

}
