import React from "react";
import Grid, { GridSize } from "@mui/material/Grid";

export interface ContentProps {
  children?: React.ReactNode;
  className?: string;
  sizeMd?: GridSize;
  sizeXs?: GridSize;
  border?: number;
}

export const Content = (props: ContentProps): React.ReactElement => {
  const { children, className, sizeMd, sizeXs, border } = props;

  return (
    <React.Fragment>
      <Grid
        item
        xs={sizeXs ?? 12}
        md={sizeMd ?? 12}
        className={className}
        role="grid"
        border={border}
      >
        {children}
      </Grid>
    </React.Fragment>
  );
};
