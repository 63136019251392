import { Grid, SelectChangeEvent } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FormFieldLabel } from "../../../../../../components/hook-form/FormFieldLabel";
import { FormSelect } from "../../../../../../components/hook-form/FormSelect";
import { Header } from "../../../../../../components/core/Header";
import { Content } from "../../../../../../components/core/Content";
import { useAppSelector } from "../../../../../../hooks/useAppSelector";
import {
  selectPricingModels,
  selectSelectedPricingModel,
} from "../../../../../../features/pricingModels/pricingModels.selectors";
import { useEffect } from "react";
import { useAppDispatch } from "../../../../../../hooks/useAppDispatch";
import { pricingModelSelected } from "../../../../../../features/pricingModels/pricingModels.reducer";
import { mapEntitiesWithNoCodeToFormSelectOptions } from "../../../../../../utils/formUtils";
import {
  selectChargeModels,
  selectSelectedChargeModel,
} from "../../../../../../features/chargeModels/chargeModels.selectors";
import { selectSelectedEtdCategory } from "../../../../../../features/etdCategories/etdCategories.selectors";
import {
  fetchChargeModels,
  chargeModelSelected,
} from "../../../../../../features/chargeModels/chargeModels.reducer";
import { selectSelectedBookingCenter } from "../../../../../../features/bookingCenters/bookingCenters.selectors";
import {
  selectMarketPlaces,
  selectSelectedMarketPlace,
} from "../../../../../../features/marketPlaces/marketPlaces.selectors";
import {
  fetchMarketPlaces,
  marketPlaceSelected,
  resetMarketPlaces,
} from "../../../../../../features/marketPlaces/marketPlaces.reducer";
import { selectSelectedCurrency } from "../../../../../../features/currencies/currencies.selectors";
import { SingleSimulationFormInputs } from "../types/SingleSimulationFormInputs";

export const SegmentFields = () => {
  const { control, setValue } = useFormContext<SingleSimulationFormInputs>();
  const dispatch = useAppDispatch();

  const pricingModels = useAppSelector(selectPricingModels);
  const chargeModels = useAppSelector(selectChargeModels);
  const stockExchangeIndicative = useAppSelector(selectMarketPlaces);

  const selectedEtdCategory = useAppSelector(selectSelectedEtdCategory);
  const selectedBookingCenter = useAppSelector(selectSelectedBookingCenter);
  const selectedPricingModel = useAppSelector(selectSelectedPricingModel);
  const selectedChargeModel = useAppSelector(selectSelectedChargeModel);
  const selectedCurrency = useAppSelector(selectSelectedCurrency);
  const selectedStockExchangeIndicative = useAppSelector(
    selectSelectedMarketPlace
  );

  useEffect(() => {
    if (selectedBookingCenter && selectedPricingModel && selectedEtdCategory) {
      setValue("thirdPartyChargesModel", "");
      dispatch(
        fetchChargeModels({
          bookingCenterId: selectedBookingCenter.id as number,
          pricingModelId: selectedPricingModel.id,
          categoryId: selectedEtdCategory.id,
        })
      );
    }
  }, [
    dispatch,
    selectedBookingCenter,
    selectedEtdCategory,
    selectedPricingModel,
    setValue,
  ]);

  useEffect(() => {
    if (selectedPricingModel?.text === "Non-Eurex") {
      const selected = chargeModels.find((cm) => cm.text === "all-inclusive");
      dispatch(chargeModelSelected(selected));
      if (selected) {
        setValue("thirdPartyChargesModel", selected.text);
      }
    }
  }, [chargeModels, dispatch, selectedPricingModel?.text, setValue]);

  useEffect(() => {
    if (
      selectedEtdCategory &&
      selectedBookingCenter &&
      selectedPricingModel &&
      selectedChargeModel &&
      selectedCurrency
    ) {
      setValue("stockExchangeIndicative", "");
      dispatch(resetMarketPlaces());
      dispatch(
        fetchMarketPlaces({
          bookingCenterId: selectedBookingCenter.id as number,
          pricingModelId: selectedPricingModel.id,
          categoryId: selectedEtdCategory.id,
          chargeModelId: selectedChargeModel.id,
          currencyId: selectedCurrency.id,
        })
      );
    }
  }, [
    dispatch,
    selectedBookingCenter,
    selectedChargeModel,
    selectedCurrency,
    selectedEtdCategory,
    selectedPricingModel,
    setValue,
  ]);

  const onPricingModelSelected = (evt: SelectChangeEvent<string>) => {
    const pmText = evt.target.value;
    const selected = pricingModels.find((pm) => pm.text === pmText);
    dispatch(pricingModelSelected(selected));
  };

  const onThirdPartyCharges = (evt: SelectChangeEvent<string>) => {
    const cmText = evt.target.value;
    const selected = chargeModels.find((cm) => cm.text === cmText);
    dispatch(chargeModelSelected(selected));
  };

  const onStockExchangeSelected = (evt: SelectChangeEvent<string>) => {
    const seText = evt.target.value;
    const selected = stockExchangeIndicative.find((se) => {
      return se.text === seText;
    });
    dispatch(marketPlaceSelected(selected));
  };

  return (
    <>
      <Content>
        <Header title="Segment Fields" />
      </Content>
      <Grid item xs={6}>
        <FormSelect
          name="pricingModel"
          label={<FormFieldLabel isRequired>Pricing Model</FormFieldLabel>}
          control={control}
          fullWidth
          autoComplete="off"
          handleChange={onPricingModelSelected}
          options={mapEntitiesWithNoCodeToFormSelectOptions(pricingModels)}
          rules={{
            required: "Pricing Model is Required.",
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <FormSelect
          name="thirdPartyChargesModel"
          label={<FormFieldLabel>3rd Party Charges Model</FormFieldLabel>}
          control={control}
          fullWidth
          autoComplete="off"
          handleChange={onThirdPartyCharges}
          options={mapEntitiesWithNoCodeToFormSelectOptions(chargeModels)}
        />
      </Grid>
      <Grid item xs={6}>
        <FormSelect
          name="stockExchangeIndicative"
          label={
            <FormFieldLabel isRequired={stockExchangeIndicative.length > 0}>
              Stock Exchange Indicative
            </FormFieldLabel>
          }
          control={control}
          fullWidth
          autoComplete="off"
          handleChange={onStockExchangeSelected}
          options={mapEntitiesWithNoCodeToFormSelectOptions(
            stockExchangeIndicative
          )}
          rules={
            stockExchangeIndicative.length > 0
              ? {
                  required: "Stock Exchange Indicative is Required.",
                }
              : { required: false }
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FormSelect
          name="simulateThirdPartyCharges"
          label={<FormFieldLabel>Simulate 3rd Party Charges ?</FormFieldLabel>}
          control={control}
          fullWidth
          autoComplete="off"
          options={[
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ]}
          disabled={!selectedStockExchangeIndicative}
        />
      </Grid>
    </>
  );
};
