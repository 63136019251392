import { createSlice } from "@reduxjs/toolkit";
import { FeeCode } from "../../openapi";

export type FeeCodesState = {
  feeCodes: FeeCode[];
  selected?: FeeCode;
  isFetching: boolean;
};

export const INITIAL_STATE: FeeCodesState = {
  feeCodes: [],
  selected: undefined,
  isFetching: false,
};

const feeCodesSlice = createSlice({
  name: "feeCodes",
  initialState: INITIAL_STATE,
  reducers: {
    fetchFeeCodes: (state: FeeCodesState) => {
      state.isFetching = true;
    },
    fetchFeeCodesSuccess: (state: FeeCodesState, action) => {
      state.feeCodes = action.payload.feeCodes;
      state.isFetching = false;
    },
    feeCodeSelected: (state: FeeCodesState, action) => {
      state.selected = action.payload;
    },
    resetFeeCodes: (state: FeeCodesState) => {
      state.feeCodes = [];
      state.selected = undefined;
    }
  },
});

export const {
  fetchFeeCodes,
  fetchFeeCodesSuccess,
  feeCodeSelected,
  resetFeeCodes
} = feeCodesSlice.actions;

export default feeCodesSlice.reducer;
