/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedModelEntityModelEtdCategory } from "../models/PagedModelEntityModelEtdCategory";
import type { PagedModelEntityModelInstrumentDto } from "../models/PagedModelEntityModelInstrumentDto";
import type { PagedModelEntityModelInstrumentType } from "../models/PagedModelEntityModelInstrumentType";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class EtdCategoryControllerService {
  /**
   * It will return a paged list of the ETD Categories
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param authorization
   * @param code
   * @param id
   * @param text
   * @param isActive
   * @param sid
   * @returns PagedModelEntityModelEtdCategory ETD Categories list paged and filtered by the given mandatory and optional fields
   * @throws ApiError
   */
  public static getCategories(input: {
    page?: number;
    size: number;
    sort?: Array<string>;
    authorization?: string;
    code?: string;
    id?: number;
    text?: string;
    isActive?: boolean;
    sid?: number;
  }): CancelablePromise<PagedModelEntityModelEtdCategory> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/categories",
      headers: {
        Authorization: input.authorization,
      },
      query: {
        page: input.page,
        size: input.size,
        sort: input.sort,
        code: input.code,
        id: input.id,
        text: input.text,
        isActive: input.isActive,
        sid: input.sid,
      },
      errors: {
        403: `Your role must have read grants to EC table`,
      },
    });
  }

  /**
   * It will return a paged list of the Instrument Types
   * @param etdCategoryId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param authorization
   * @param code
   * @param etdCategory
   * @param id
   * @param text
   * @param isActive
   * @param sid
   * @returns PagedModelEntityModelInstrumentType Instrument Type list paged related to the inserted ETD Category Id and filtered by the given mandatory and optional fields
   * @throws ApiError
   */
  public static getCategoryInstrumentTypes(input: {
    etdCategoryId: number;
    page?: number;
    size: number;
    sort?: Array<string>;
    authorization?: string;
    code?: string;
    etdCategory?: string;
    id?: number;
    text?: string;
    isActive?: boolean;
    sid?: number;
  }): CancelablePromise<PagedModelEntityModelInstrumentType> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/categories/{etdCategoryId}/instrument-types",
      path: {
        etdCategoryId: input.etdCategoryId,
      },
      headers: {
        Authorization: input.authorization,
      },
      query: {
        page: input.page,
        size: input.size,
        sort: input.sort,
        code: input.code,
        etdCategory: input.etdCategory,
        id: input.id,
        text: input.text,
        isActive: input.isActive,
        sid: input.sid,
      },
      errors: {
        403: `Your role must have read grants to IT table`,
      },
    });
  }

  /**
   * It will return a paged list of the Instruments filtered by an etd category id, a pricing model id, a transaction id, an instrument type id and a direction id
   * @param categoryId
   * @param pricingModelId
   * @param transactionId
   * @param instrumentTypeId
   * @param directionId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param authorization
   * @param pricingModel
   * @param stockExchange
   * @param instrumentType
   * @param quantity
   * @param simulationNumber
   * @param etdCategory
   * @param fullName
   * @param instrSecurityNumber
   * @param sid
   * @param premiumPerContrLot
   * @param contrLotSize
   * @param exchangeRate
   * @param id
   * @param text
   * @param instrSecurityCurrency
   * @param transaction
   * @param bookingCenterCurrency
   * @param direction
   * @returns PagedModelEntityModelInstrumentDto Instruments list paged and filtered by the given mandatory and optional fields
   * @throws ApiError
   */
  public static getInstruments1(input: {
    categoryId: number;
    pricingModelId: number;
    transactionId: number;
    instrumentTypeId: number;
    directionId: number;
    page?: number;
    size: number;
    sort?: Array<string>;
    authorization?: string;
    pricingModel?: string;
    stockExchange?: string;
    instrumentType?: string;
    quantity?: number;
    simulationNumber?: number;
    etdCategory?: string;
    fullName?: string;
    instrSecurityNumber?: string;
    sid?: number;
    premiumPerContrLot?: number;
    contrLotSize?: number;
    exchangeRate?: number;
    id?: number;
    text?: string;
    instrSecurityCurrency?: string;
    transaction?: string;
    bookingCenterCurrency?: string;
    direction?: string;
  }): CancelablePromise<PagedModelEntityModelInstrumentDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/categories/{categoryId}/pricing-models/{pricingModelId}/transactions/{transactionId}/instrument-types/{instrumentTypeId}/directions/{directionId}/instruments",
      path: {
        categoryId: input.categoryId,
        pricingModelId: input.pricingModelId,
        transactionId: input.transactionId,
        instrumentTypeId: input.instrumentTypeId,
        directionId: input.directionId,
      },
      headers: {
        Authorization: input.authorization,
      },
      query: {
        page: input.page,
        size: input.size,
        sort: input.sort,
        pricingModel: input.pricingModel,
        stockExchange: input.stockExchange,
        instrumentType: input.instrumentType,
        quantity: input.quantity,
        simulationNumber: input.simulationNumber,
        etdCategory: input.etdCategory,
        fullName: input.fullName,
        instrSecurityNumber: input.instrSecurityNumber,
        sid: input.sid,
        premiumPerContrLot: input.premiumPerContrLot,
        contrLotSize: input.contrLotSize,
        exchangeRate: input.exchangeRate,
        id: input.id,
        text: input.text,
        instrSecurityCurrency: input.instrSecurityCurrency,
        transaction: input.transaction,
        bookingCenterCurrency: input.bookingCenterCurrency,
        direction: input.direction,
      },
      errors: {
        403: `Your role must have read grants to Instruments table`,
      },
    });
  }
}
