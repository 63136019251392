import { RootState } from "../../app/store";
import { EntityModelFfeMatrixBjbDto } from "../../openapi";

export const selectIsFetchingFfeMatrices = (state: RootState): boolean =>
  state.fmReducer.isFetching;
export const selectFfeMatrices = (
  state: RootState
): EntityModelFfeMatrixBjbDto[] => state.fmReducer.ffeMatrices;
export let selectFfeMatricesForMultipleSimulation1 = (
  state: RootState
): EntityModelFfeMatrixBjbDto[] =>
  state.fmReducer.ffeMatricesForMultipleSimulation1;
export const selectFfeMatricesForMultipleSimulation2 = (
  state: RootState
): EntityModelFfeMatrixBjbDto[] =>
  state.fmReducer.ffeMatricesForMultipleSimulation2;
export const selectFfeMatricesForMultipleSimulation3 = (
  state: RootState
): EntityModelFfeMatrixBjbDto[] =>
  state.fmReducer.ffeMatricesForMultipleSimulation3;
export const selectFfeMatricesForMultipleSimulation4 = (
  state: RootState
): EntityModelFfeMatrixBjbDto[] =>
  state.fmReducer.ffeMatricesForMultipleSimulation4;
export const selectSelectedFfeMatrix = (
  state: RootState
): EntityModelFfeMatrixBjbDto | undefined => state.fmReducer.selected;
