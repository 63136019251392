import React from "react";
import { useFormContext } from "react-hook-form";
import { MultipleSimulationFormInputs } from "../types/MultipleSimulationFormInputs";
import { useAppDispatch } from "../../../../../../hooks/useAppDispatch";
import { Grid, SelectChangeEvent } from "@mui/material";
import { FormFieldLabel } from "../../../../../../components/hook-form/FormFieldLabel";
import { FormInput } from "../../../../../../components/hook-form/FormInput";
import { FormSelect } from "../../../../../../components/hook-form/FormSelect";
import {
  mapEntitiesToFormSelectOptions,
  mapEntitiesWithNoCodeToFormSelectOptions,
} from "../../../../../../utils/formUtils";
import { useAppSelector } from "../../../../../../hooks/useAppSelector";
import { selectLanguages } from "../../../../../../features/languages/languages.selectors";
import {
  selectBookingCenters,
  selectSelectedBookingCenter,
} from "../../../../../../features/bookingCenters/bookingCenters.selectors";
import {
  selectPricing,
  selectSelectedPricing,
} from "../../../../../../features/pricing/pricing.selectors";
import {
  bookingCenterSelected,
  fetchBookingCenters,
} from "../../../../../../features/bookingCenters/bookingCenters.reducer";
import { fetchLanguages } from "../../../../../../features/languages/languages.reducer";
import {
  fetchPricing,
  pricingSelected,
} from "../../../../../../features/pricing/pricing.reducer";
import {
  feeCodeSelected,
  fetchFeeCodes,
  resetFeeCodes,
} from "../../../../../../features/feeCodes/feeCodes.reducer";
import {
  selectFeeCodes,
  selectSelectedFeeCode,
} from "../../../../../../features/feeCodes/feeCodes.selectors";
import { fetchChargeModelsForMultipleSimulation } from "../../../../../../features/chargeModels/chargeModels.reducer";
import { selectChargeModels } from "../../../../../../features/chargeModels/chargeModels.selectors";
import { resetSpecialConditionTypes } from "../../../../../../features/specialConditionTypes/specialConditionTypes.reducer";
import { resetPricingModels } from "../../../../../../features/pricingModels/pricingModels.reducer";
import { getMinimumFeeFieldName } from "../Simulation/utils";
import { selectInstruments } from "../../../../../../features/instruments/instruments.selectors";
import { useAutoSelectEN } from "../../../../../../features/languages/hooks/useAutoSelectEN";

const yesNoOptions = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];

export const SharedFields = (): React.ReactElement => {
  const { control, setValue } = useFormContext<MultipleSimulationFormInputs>();
  const dispatch = useAppDispatch();

  useAutoSelectEN();

  const bookingCenters = useAppSelector(selectBookingCenters);
  const languages = useAppSelector(selectLanguages);
  const pricing = useAppSelector(selectPricing);
  const feeCodes = useAppSelector(selectFeeCodes);
  const chargesModels = useAppSelector(selectChargeModels);
  const instruments = useAppSelector(selectInstruments);

  const selectedBookingCenter = useAppSelector(selectSelectedBookingCenter);
  const selectedPricing = useAppSelector(selectSelectedPricing);
  const selectedFeeCode = useAppSelector(selectSelectedFeeCode);

  React.useEffect(() => {
    dispatch(fetchLanguages());
    dispatch(fetchBookingCenters());
  }, [dispatch]);

  React.useEffect(() => {
    if (!selectedBookingCenter) {
      return;
    }

    dispatch(resetFeeCodes());
    setValue("feeCode", "");
    setValue("pricing", "");
    setValue("chargesModel", "");

    dispatch(
      fetchPricing({ bookingCenterId: selectedBookingCenter?.id as number })
    );

    dispatch(
      fetchChargeModelsForMultipleSimulation({
        bookingCenterId: selectedBookingCenter?.id as number,
      })
    );
  }, [dispatch, selectedBookingCenter, setValue]);

  React.useEffect(() => {
    //auto select first option and solves infinite loop inside FormSelect TODO: make dynamic
    if (bookingCenters.length > 0 && pricing.length === 1) {
      return onPricingChanged({
        target: {
          value: pricing[0].text,
        },
      } as SelectChangeEvent<string>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingCenters, pricing, selectedPricing]);

  React.useEffect(() => {
    if (!selectedPricing) {
      dispatch(resetFeeCodes());
      setValue("feeCode", "");
      return;
    }

    setValue("feeCode", "");

    if (selectedPricing.text.toLowerCase() === "fee code") {
      dispatch(fetchFeeCodes());
    } else {
      dispatch(resetFeeCodes());
    }
  }, [dispatch, selectedPricing, setValue]);

  const onBookingCenterSelected = (evt: SelectChangeEvent<string>) => {
    const bcCode = evt.target.value;
    const selected = bookingCenters.find((bc) => bc.code === bcCode);
    dispatch(bookingCenterSelected(selected));
    dispatch(resetPricingModels());
  };

  const onPricingChanged = (evt: SelectChangeEvent<string>) => {
    const pText = evt.target.value;
    const selected = pricing.find((p) => p.text === pText);
    setValue("feeCode", "");
    setValue("specialConditions1", "");
    setValue("specialConditions2", "");
    setValue("specialConditions3", "");
    setValue("specialConditions4", "");
    setValue("specialConditionsUnit1", "");
    setValue("specialConditionsUnit2", "");
    setValue("specialConditionsUnit3", "");
    setValue("specialConditionsUnit4", "");
    dispatch(resetFeeCodes());
    dispatch(resetSpecialConditionTypes());
    dispatch(pricingSelected(selected));
  };

  const onFeeCodeChanged = (evt: SelectChangeEvent<string>) => {
    const fcCode = Number(evt.target.value);
    const selected = feeCodes.find((fc) => fc.code === fcCode);
    dispatch(feeCodeSelected(selected));

    setValue(getMinimumFeeFieldName(1), "");
    setValue(getMinimumFeeFieldName(2), "");
    setValue(getMinimumFeeFieldName(3), "");
    setValue(getMinimumFeeFieldName(4), "");

    if (fcCode === 120) {
      return;
    }

    setValue("ticketFeeHost", undefined);
  };

  return (
    <React.Fragment>
      <Grid item xs={6} md={3}>
        <FormInput
          name="portfolioNo"
          label={<FormFieldLabel isRequired>Portfolio No</FormFieldLabel>}
          control={control}
          autoComplete="off"
          fullWidth
          regEx="[]"
          rules={{ required: "PortfolioNo is Required." }}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <FormSelect
          name="outputLanguage"
          label={<FormFieldLabel isRequired>Output Language</FormFieldLabel>}
          control={control}
          fullWidth
          autoComplete="off"
          options={mapEntitiesToFormSelectOptions(languages, {
            useCodeAsLabel: true,
          })}
          rules={{ required: "Output Language is Required." }}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <FormSelect
          name="bookingCenter"
          label={<FormFieldLabel isRequired>Booking Center</FormFieldLabel>}
          control={control}
          fullWidth
          autoComplete="off"
          options={mapEntitiesToFormSelectOptions(bookingCenters, {
            useCodeAsLabel: true,
          })}
          handleChange={onBookingCenterSelected}
          rules={{ required: "Booking Center is Required." }}
          deps={[instruments]}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <FormSelect
          name="pricing"
          label={<FormFieldLabel isRequired>Pricing</FormFieldLabel>}
          control={control}
          fullWidth
          autoComplete="off"
          handleChange={onPricingChanged}
          options={mapEntitiesWithNoCodeToFormSelectOptions(pricing)}
          rules={{ required: "Pricing is Required." }}
          noFirstOption
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <FormSelect
          name="feeCode"
          label={
            <FormFieldLabel
              isRequired={selectedPricing?.text.toLowerCase() === "fee code"}
            >
              Select Fee Code
            </FormFieldLabel>
          }
          control={control}
          fullWidth
          autoComplete="off"
          handleChange={onFeeCodeChanged}
          options={mapEntitiesToFormSelectOptions(feeCodes, {
            useNameAsLabel: true,
          })}
          rules={
            selectedPricing?.text.toLowerCase() === "fee code"
              ? { required: "Fee Code is Required." }
              : { required: false }
          }
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <FormInput
          name="ticketFeeHost"
          label={
            <FormFieldLabel isRequired={selectedFeeCode?.code === 120}>
              Ticket fee (HOST)
            </FormFieldLabel>
          }
          control={control}
          type="number"
          autoComplete="off"
          disabled={selectedFeeCode?.code !== 120}
          fullWidth
          rules={
            selectedFeeCode?.code === 120
              ? { required: "Ticket fee (HOST) is Required." }
              : { required: false }
          }
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <FormSelect
          name="chargesModel"
          label={
            <FormFieldLabel isRequired>
              3rd Party Charges Model (Non-Eurex)
            </FormFieldLabel>
          }
          control={control}
          fullWidth
          autoComplete="off"
          options={mapEntitiesWithNoCodeToFormSelectOptions(chargesModels)}
          rules={{ required: "3rd Party Charges Model is Required." }}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <FormSelect
          name="simulateThirdPartyCharges"
          label={
            <FormFieldLabel isRequired>
              Simulate 3rd Party Charges?
            </FormFieldLabel>
          }
          control={control}
          fullWidth
          autoComplete="off"
          options={yesNoOptions}
          rules={{
            required: "No Simulation 3rd Party Charges Model is Required.",
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormInput
          regEx="[]"
          name="comments"
          label={<FormFieldLabel>Comments (Cover Letter)</FormFieldLabel>}
          control={control}
          autoComplete="off"
          fullWidth
          multiline
          minRows={2}
        />
      </Grid>
    </React.Fragment>
  );
};
