import React from "react";
import { SingleSimulationFormInputs } from "../../SingleSimulationForm/types/SingleSimulationFormInputs";
import { useFormContext, useWatch } from "react-hook-form";
import { useAppSelector } from "../../../../../../hooks/useAppSelector";
import { selectFeeMatrices3P } from "../../../../../../features/feeMatrices3P/feeMatrices3P.selectors";
import { InputAdornment } from "@mui/material";
import { selectSelectedCurrency } from "../../../../../../features/currencies/currencies.selectors";
import { FormCurrencyInput } from "components/hook-form/FormCurrencyInput";

export const ThirdPartyCharges = (): React.ReactElement => {
  const { control, setValue } = useFormContext<SingleSimulationFormInputs>();

  const feeMatrices3P = useAppSelector(selectFeeMatrices3P);
  const selectedCurrency = useAppSelector(selectSelectedCurrency);

  const exchangeRate = useWatch({
    control,
    name: "exchangeRate",
  });
  const thirdChargesInPercentage = useWatch({
    control,
    name: "thirdChargesInPercentage",
  });
  const thirdChargesPerLot = useWatch({ control, name: "thirdChargesPerLot" });
  const totalThirdCharges = useWatch({ control, name: "totalThirdCharges" });
  const totalFee = useWatch({ control, name: "totalFee" });
  const totalTransactionFeeInstrumentCurrency = useWatch({
    control,
    name: "totalTransactionFeeInstrumentCurrency",
  });
  const quantity = useWatch({ control, name: "quantity" });
  const contractLotSize = useWatch({ control, name: "contractLotSize" });
  const premiumPerContractLot = useWatch({
    control,
    name: "premiumPerContractLot",
  });
  const simulateThirdPartyCharges = useWatch({
    control,
    name: "simulateThirdPartyCharges",
  });
  const bjbTaRateMinFee = useWatch({
    control,
    name: "bjbTaRateMinFee",
  });

  React.useEffect(() => {
    if (
      !feeMatrices3P ||
      feeMatrices3P.length === 0 ||
      /** now default is yes */
      // !simulateThirdPartyCharges ||
      simulateThirdPartyCharges === "no"
    ) {
      setValue("thirdChargesInPercentage", 0);
      setValue("thirdChargesPerLot", 0);
      return;
    }

    const _thirdChargesInPercentage = feeMatrices3P[0].rate as number;
    setValue("thirdChargesInPercentage", _thirdChargesInPercentage);

    const _thirdChargesPerLot = feeMatrices3P[0].perLot as number;

    setValue("thirdChargesPerLot", _thirdChargesPerLot);
  }, [feeMatrices3P, simulateThirdPartyCharges, setValue]);

  React.useEffect(() => {
    const _totalThirdCharges =
      (quantity *
        contractLotSize *
        (premiumPerContractLot ?? 1) *
        (!thirdChargesInPercentage ? 0 : thirdChargesInPercentage)) /
        100 +
      quantity * thirdChargesPerLot;

    setValue("totalThirdCharges", _totalThirdCharges);
  }, [
    thirdChargesInPercentage,
    thirdChargesPerLot,
    quantity,
    contractLotSize,
    premiumPerContractLot,
    setValue,
    bjbTaRateMinFee,
    exchangeRate,
  ]);

  React.useEffect(() => {
    if (!totalTransactionFeeInstrumentCurrency) {
      return;
    }

    setValue(
      "totalFee",
      totalTransactionFeeInstrumentCurrency + (totalThirdCharges ?? 0)
    );
  }, [totalTransactionFeeInstrumentCurrency, totalThirdCharges, setValue]);

  return (
    <React.Fragment>
      {thirdChargesInPercentage && feeMatrices3P ? (
        <FormCurrencyInput
          name="thirdChargesInPercentage"
          label="3rd Party Charges in %"
          variant="filled"
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
            readOnly: true,
          }}
          fullWidth
          sx={{ mt: "10px" }}
        />
      ) : (
        <React.Fragment />
      )}
      {thirdChargesPerLot && feeMatrices3P && selectedCurrency ? (
        <FormCurrencyInput
          name="thirdChargesPerLot"
          label="3rd Party Charges per Lot"
          variant="filled"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{"per lot"}</InputAdornment>
            ),
            readOnly: true,
          }}
          fullWidth
          sx={{ mt: "10px" }}
        />
      ) : (
        <React.Fragment />
      )}
      {totalThirdCharges && selectedCurrency ? (
        <FormCurrencyInput
          name="totalThirdCharges"
          label="Total 3rd Party Charges"
          variant="filled"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {selectedCurrency.code}
              </InputAdornment>
            ),
            readOnly: true,
          }}
          fullWidth
          sx={{ mt: "10px" }}
        />
      ) : (
        <React.Fragment />
      )}
      {totalFee && selectedCurrency ? (
        <FormCurrencyInput
          name="totalFee"
          label="Total Fee"
          variant="filled"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {selectedCurrency.code}
              </InputAdornment>
            ),
            readOnly: true,
          }}
          fullWidth
          sx={{ mt: "10px" }}
        />
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};
