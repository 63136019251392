import { createSlice } from "@reduxjs/toolkit";
import { CurrencyDto } from "../../openapi";

export type CurrenciesState = {
  currencies: CurrencyDto[];
  selected?: CurrencyDto;
  isFetching: boolean;
};

export const INITIAL_STATE: CurrenciesState = {
  currencies: [],
  selected: undefined,
  isFetching: false,
};

const currenciesSlice = createSlice({
  name: "currencies",
  initialState: INITIAL_STATE,
  reducers: {
    fetchCurrencies: (state: CurrenciesState) => {
      state.isFetching = true;
    },
    fetchCurrenciesSuccess: (state: CurrenciesState, action) => {
      state.currencies = action.payload.currencies;
      state.isFetching = false;
    },
    currencySelected: (state: CurrenciesState, action) => {
      state.selected = action.payload;
    },
    resetCurrencies: (state: CurrenciesState) => {
      state.currencies = [];
    }
  },
});

export const {
  fetchCurrencies,
  fetchCurrenciesSuccess,
  currencySelected,
  resetCurrencies
} = currenciesSlice.actions;

export default currenciesSlice.reducer;
