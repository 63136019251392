import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { StyledDiv, StyledSection } from "./index.style";
import { Card } from "../../../../components/core/Card";
import { Header } from "../../../../components/core/Header";
import logo from "../../../../assets/images/logo-output.png";
import DownloadOutlined from "@mui/icons-material/DownloadOutlined";
import { ButtonWrapper } from "../../../MultipleSimulation/index.style";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";
import {
  selectIsFetchingSingleOutput,
  selectSingleOutput,
} from "../../../../features/singleOutput/singleOutput.selectors";
import { Spinner } from "../../../../components/core/Spinner";
import { apexify, withDecimalPrecision } from "../../../../utils/numbersUtils";
import Button from "components/core/Button";
import { useUser } from "hooks/useUser";
import { downloadPDF } from "utils/pdfUtils";
import env from "../../../../config/env";

type OutputPanelProps = {
  hide: boolean;
  handleBack: () => void;
};

const isTotalChargesInvalid = (
  totalThirdCharges: number,
  simulateThirdPartyCharges: string
) => {
  return totalThirdCharges === 0 || simulateThirdPartyCharges === "no";
};

export const OutputPanel = (props: OutputPanelProps): React.ReactElement => {
  const { hide, handleBack } = props;

  const singleOutput = useSelector(selectSingleOutput);
  const singleOutputIsFetching = useSelector(selectIsFetchingSingleOutput);

  const { token } = useUser();

  const url = `${env.REACT_APP_API_URL}/api/pdf/download?reportName=${singleOutput?.reportName}&type=SINGLE`;

  const handleDownloadPdf = () =>
    downloadPDF(url, token, singleOutput?.reportName);

  if (singleOutputIsFetching) {
    return <Spinner width="100vw" height="calc(100vh - 264px)" />;
  }

  if (!singleOutput) {
    return <React.Fragment />;
  }

  const {
    header,
    portfolioNrLabel,
    transactionRelatedCostsLabel,
    portfolioNo,
    instrumentSectionLabel,
    instrumentTypeLabel,
    instrumentType,
    underlyingLabel,
    underlyingFullName,
    tickerLabel,
    tickerIsinOfUnderlying,
    expiryLabel,
    expiry,
    instrumentShortNameLabel,
    instrumentShortName,
    indicativeExchangeMarketLabel,
    stockExchangeIndicative,
    instrumentCurrencyLabel,
    instrumentSecurityCurrency,
    strikeLabel,
    strike,
    contractLotSizeLabel,
    contractLotSize,
    instrumentSecurityNumberLabel,
    instrumentSecurityNumber,
    indicativeExchangeRateLabel,
    exchangeRate,
    yourIntendedTransactionLabel,
    transaction,
    quantity,
    direction,
    disclaimer,
    premiumPerContractLot,
    grossPremiumLabel,
    premiumLabel,
    premiumDirection,
    transactionVolume1,
    ticketOrTransactionFeeLabel,
    externalCommissionLabel,
    totalThirdCharges,
    exCostLabel,
    totalTransactionRelatedCostsLabel,
    transactionAmountLabel,
    remarks,
    remarksLabel,
    transactionAmountAnnotation,
    totalTransactionFeeInstrumentCurrency,
    totalFee,
    leftVerticalSign,
    bankName,
    subtitle,
    simulateThirdPartyCharges,
  } = singleOutput;
  const newLeftVerticalSign = leftVerticalSign?.split("@")[0];

  const formattedQuantity = withDecimalPrecision(quantity);
  const formattedContractLotSize = withDecimalPrecision(contractLotSize);
  const formattedPremiumPerContractLotSize = withDecimalPrecision(
    premiumPerContractLot as number
  );

  return (
    <>
      <Card
        title="Output"
        Component={StyledSection}
        sticky
        style={{
          width: "100%",
          display: hide ? "none" : "block",
        }}
        actions={
          <ButtonWrapper>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleDownloadPdf}
            >
              Download PDF
              <DownloadOutlined />
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleBack}>
              <ArrowBackIcon /> Back
            </Button>
          </ButtonWrapper>
        }
      >
        <Grid item xs={12} sx={{ ml: "-20px" }}>
          <StyledDiv>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Box
                component="img"
                sx={{
                  width: 250,
                  color: "#001489",
                  mt: "-50px",
                  pt: "24px",
                }}
                alt="Julius Baer logo"
                src={logo}
              />
            </Grid>
            <Grid item xs={12} marginTop={5}>
              {header &&
                !isTotalChargesInvalid(
                  totalThirdCharges,
                  simulateThirdPartyCharges
                ) && (
                  <Header
                    title={header}
                    subtitle={subtitle}
                    smallMarginBottom
                  />
                )}

              {header &&
                isTotalChargesInvalid(
                  totalThirdCharges,
                  simulateThirdPartyCharges
                ) && <Header title={header} subtitle={""} smallMarginBottom />}

              {portfolioNrLabel && (
                <Grid item xs={6}>
                  <Grid container marginTop={3}>
                    <Grid item xs={6}>
                      <Typography>{portfolioNrLabel}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{portfolioNo}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {instrumentSectionLabel && (
                <Grid item xs={12} marginTop={4}>
                  <Typography>
                    <strong>{instrumentSectionLabel}</strong>
                  </Typography>
                </Grid>
              )}

              {instrumentTypeLabel && instrumentType && (
                <Grid item xs={6}>
                  <Grid container marginTop={2}>
                    <Grid item xs={6}>
                      <Typography>{instrumentTypeLabel}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{instrumentType}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {underlyingLabel && underlyingFullName && (
                <Grid item xs={6}>
                  <Grid container marginTop={2}>
                    <Grid item xs={6}>
                      <Typography>{underlyingLabel}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{underlyingFullName}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {tickerLabel && tickerIsinOfUnderlying && (
                <Grid item xs={6}>
                  <Grid container marginTop={2}>
                    <Grid item xs={6}>
                      <Typography>Ticker / ISIN</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{tickerIsinOfUnderlying}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {expiryLabel && expiry && (
                <Grid item xs={6}>
                  <Grid container marginTop={2}>
                    <Grid item xs={6}>
                      <Typography>Expiry</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{expiry}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {instrumentShortNameLabel && instrumentShortName && (
                <Grid item xs={6}>
                  <Grid container marginTop={2}>
                    <Grid item xs={6}>
                      <Typography>Instrument Short Name</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{instrumentShortName}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Grid item xs={6}></Grid>

              {indicativeExchangeMarketLabel && stockExchangeIndicative && (
                <Grid item xs={6}>
                  <Grid container marginTop={2}>
                    <Grid item xs={6}>
                      <Typography>Indicative Exchange / Market</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{stockExchangeIndicative}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {instrumentCurrencyLabel && instrumentSecurityCurrency && (
                <Grid item xs={6}>
                  <Grid container marginTop={2}>
                    <Grid item xs={6}>
                      <Typography>{instrumentCurrencyLabel}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{instrumentSecurityCurrency}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {strikeLabel && strike && (
                <Grid item xs={6}>
                  <Grid container marginTop={2}>
                    <Grid item xs={6}>
                      <Typography>{strikeLabel}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{strike}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {contractLotSizeLabel && contractLotSize !== undefined ? (
                <Grid item xs={6}>
                  <Grid container marginTop={2}>
                    <Grid item xs={6}>
                      <Typography>{contractLotSizeLabel}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{formattedContractLotSize}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}

              {instrumentSecurityNumberLabel && instrumentSecurityNumber && (
                <Grid item xs={6}>
                  <Grid container marginTop={2}>
                    <Grid item xs={6}>
                      <Typography>{instrumentSecurityNumberLabel}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{instrumentSecurityNumber}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {indicativeExchangeRateLabel && exchangeRate && (
                <Grid item xs={6}>
                  <Grid container marginTop={2}>
                    <Grid item xs={6}>
                      <Typography>{indicativeExchangeRateLabel}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{Number(exchangeRate).toFixed(4)}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {yourIntendedTransactionLabel && (
                <Grid item xs={12} marginTop={4}>
                  <Typography>
                    <strong>{yourIntendedTransactionLabel}</strong>
                  </Typography>
                </Grid>
              )}

              <Grid container marginTop={2}>
                <Grid container item xs={4}>
                  <Grid item xs={3}>
                    <Typography>{transaction}</Typography>
                  </Grid>
                  <Grid item xs={3} display="flex" justifyContent="flex-end">
                    <Typography sx={{ mr: "10px" }}>
                      {formattedQuantity}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>{instrumentType}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>{direction}</Typography>
                  </Grid>

                  {premiumLabel && (
                    <React.Fragment>
                      <Grid item xs={3}>
                        <Typography>{premiumLabel}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Typography sx={{ mr: "10px" }}>
                          {formattedPremiumPerContractLotSize}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>{instrumentSecurityCurrency}</Typography>
                      </Grid>
                      <Grid item xs={3} />
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>

              {grossPremiumLabel && (
                <Grid container marginTop={2}>
                  <Grid item xs={3}>
                    <Typography>
                      <strong>{grossPremiumLabel}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      <strong>{premiumDirection}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      <strong>{instrumentSecurityCurrency}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ textAlign: "right" }}>
                      <strong>
                        {apexify(transactionVolume1 as unknown as number, 0)}
                      </strong>
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {transactionRelatedCostsLabel && (
                <Grid item marginTop={4}>
                  <Header
                    title={transactionRelatedCostsLabel}
                    smallMarginBottom
                  />
                </Grid>
              )}

              <Grid container marginTop={2}>
                <Grid item xs={6}>
                  <Typography>
                    <strong>{ticketOrTransactionFeeLabel ?? ""}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{instrumentSecurityCurrency}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ textAlign: "right" }}>
                    {!isNaN(totalTransactionFeeInstrumentCurrency)
                      ? apexify(totalTransactionFeeInstrumentCurrency)
                      : "-"}
                  </Typography>
                </Grid>
                {externalCommissionLabel ? (
                  <React.Fragment>
                    <Grid item xs={6}>
                      <Typography>{externalCommissionLabel}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>{instrumentSecurityCurrency}</Typography>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: "right" }}>
                      <Typography>{apexify(totalThirdCharges)}</Typography>
                    </Grid>
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
                <Grid item xs={12}>
                  <Typography>{exCostLabel}</Typography>
                </Grid>
                <Grid container marginTop={2}>
                  <React.Fragment>
                    <Grid item xs={6}>
                      <Typography>
                        <strong>{totalTransactionRelatedCostsLabel}</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>
                        <strong>{instrumentSecurityCurrency}</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: "right" }}>
                      <Typography>
                        <strong>
                          {totalTransactionFeeInstrumentCurrency
                            ? apexify(
                                totalTransactionFeeInstrumentCurrency +
                                  (!isNaN(totalThirdCharges)
                                    ? totalThirdCharges
                                    : 0)
                              )
                            : "-"}
                        </strong>
                      </Typography>
                    </Grid>
                  </React.Fragment>

                  {transactionAmountAnnotation && (
                    <Grid item xs={12}>
                      <Typography>{transactionAmountAnnotation}</Typography>
                    </Grid>
                  )}

                  {transactionAmountLabel ? (
                    <React.Fragment>
                      <Grid item xs={9}>
                        <Typography>{transactionAmountLabel}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography style={{ textAlign: "right" }}>
                          {totalFee && transactionVolume1 ? (
                            <>
                              {apexify(
                                (totalFee /
                                  (transactionVolume1 as unknown as number)) *
                                  100
                              )}
                              %
                            </>
                          ) : (
                            "n.a"
                          )}
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                </Grid>
              </Grid>

              {remarksLabel ? (
                <React.Fragment>
                  <Grid container marginTop={5}>
                    <Typography>
                      <strong>{remarksLabel}</strong>
                    </Typography>
                  </Grid>
                  <Grid container>
                    <Typography
                      variant="body2"
                      style={{ wordBreak: "break-all" }}
                    >
                      {remarks}
                    </Typography>
                  </Grid>
                </React.Fragment>
              ) : (
                <React.Fragment />
              )}

              <Grid container marginTop={5}>
                <Typography variant="body2">{disclaimer}</Typography>
                <Grid
                  container
                  marginTop={5}
                  marginBottom={-8}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography sx={{ marginBottom: "30px" }} color="primary">
                    {bankName}
                  </Typography>
                </Grid>
                <div
                  style={{
                    position: "relative",
                    width: "20px",
                    minHeight: "33px",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      transform: "translateX(-50%) rotate(270deg)",
                      minHeight: "100px",
                      minWidth: "350px",
                      whiteSpace: "nowrap",
                      left: "-100%",
                      bottom: "169px",
                    }}
                  >
                    {newLeftVerticalSign?.replace(/\\/g, "")}
                  </span>
                </div>
              </Grid>
            </Grid>
          </StyledDiv>
        </Grid>
      </Card>
    </>
  );
};
