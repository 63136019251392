import { createSlice } from "@reduxjs/toolkit";
import { EntityModelFeeMatrix3P } from "../../openapi";

export type FeeMatrices3PState = {
  feeMatrices3P: EntityModelFeeMatrix3P[];
  selected?: EntityModelFeeMatrix3P;
  isFetching: boolean;
};

export const INITIAL_STATE: FeeMatrices3PState = {
  feeMatrices3P: [],
  selected: undefined,
  isFetching: false,
};

export type FetchFeeMatrices3PAction = {
  type: string;
  payload: {
    pricingModel?: string;
    chargeModel?: string;
    rate?: number;
    marketPlace?: string;
    perLot?: number;
    bookingCenter?: string;
    etdCategory?: string;
    currency?: string;
    id?: number;
    source?: string;
  }
}

const feeMatrices3PSlice = createSlice({
  name: "feeMatrices3P",
  initialState: INITIAL_STATE,
  reducers: {
    fetchFeeMatrices3P: (state: FeeMatrices3PState, _action: FetchFeeMatrices3PAction) => {
      state.isFetching = true;
    },
    fetchFeeMatrices3PSuccess: (state: FeeMatrices3PState, action) => {
      state.feeMatrices3P = action.payload.feeMatrices3P;
      state.isFetching = false;
    },
    feeMatrix3PSelected: (state: FeeMatrices3PState, action) => {
      state.selected = action.payload;
    },
  },
});

export const {
  fetchFeeMatrices3P,
  fetchFeeMatrices3PSuccess,
  feeMatrix3PSelected,
} = feeMatrices3PSlice.actions;

export default feeMatrices3PSlice.reducer;
