import { takeLatest, put } from "redux-saga/effects";
import { fetchFeeCodes, fetchFeeCodesSuccess } from "./feeCodes.reducer";
import { FeeCodeControllerService, FeeCode } from "../../openapi";
import authorizedCall from "../../utils/authorizedCall";

export function* fetchFeeCodesSaga() {
  const response: FeeCode[] = yield authorizedCall(
    FeeCodeControllerService.getFeeCodes,
    {
      page: 0,
      size: 10,
    }
  );
  yield put(fetchFeeCodesSuccess({ feeCodes: response }));
}

export default function* rootFeeCodesSaga() {
  yield takeLatest(fetchFeeCodes.type, fetchFeeCodesSaga);
}
